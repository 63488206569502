import React, { useEffect } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import { AlertMessage, InputButton, InputText } from "components/UI";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import "./ModalPossibleStatus.css";
import { usePossibleStatus } from "shared/services/master/routepoint";
import { message } from "utils";
import { ModeAction, MessageType } from "state/enum";
import ConfirmDialog from "components/UI/ConfirmDialog";
import { HeaderContentBar } from "components/Layout";
import { messageTypeDisplay } from "utils";

export function ModalPossibleStatus({ open = true, setOpen, setRows, rows = [], rowNumber, mode, functionId }) {
  const [msgError, setMsgError] = useState([]);

  const [selectedList, setSelectedList] = useState([]);
  const [isConfirmCancelOpen, setConfirmCancelOpen] = useState(false);

  useEffect(() => {
    const dataSelected = rows.find(v => v.rowNumber === rowNumber)?.possibleStatusId || [];

    setSelectedList(dataSelected);
  }, [rows, rowNumber, open]);

  const { data: possibleList } = usePossibleStatus({});

  const handleClose = () => {
    setConfirmCancelOpen(true);
  };

  const handleCancelClick = () => {
    setConfirmCancelOpen(true);
  };

  const handleCancelConfirm = () => {
    setOpen(false);
    setSelectedList([]);
    setMsgError([]);
  };

  const hasDuplicates = arr => {
    const map = new Map();
    const duplicates = [];

    arr.forEach(item => {
      const key = `${item["seqNo"]}-${item["routePointSts"]}`; // Combine both fields into a unique key
      if (map.has(key)) {
        duplicates.push(item);
      } else {
        map.set(key, true);
      }
    });

    return duplicates;
  };

  const handleSaveClick = () => {
    // validate
    if (!selectedList.length) {
      setMsgError([message({ type: "empty", msg: "List of possible status" })]);
      return;
    }
    if (selectedList.some(v => parseInt(v.seqNo) === 0)) {
      setMsgError([message({ type: "moreThan0", msg: "Sequence" })]);
      return;
    }

    const hasDuplicatesData = hasDuplicates(selectedList);
    if (hasDuplicatesData.length > 0) {
      const routePointStsDupData = [];
      const seqNoDup = [];
      hasDuplicatesData.forEach(element => {
        routePointStsDupData.push(element.routePointSts);
        seqNoDup.push(element.seqNo);
      });
      setMsgError([
        message({
          type: "MSTD0039AERR_DUPLICATE",
          msg: `Sequence = ${seqNoDup.join(",")} and List of possible status = ${routePointStsDupData.join(",")} `,
        }),
      ]);
      return;
    }

    const newCurrency = selectedList.map((item, i) => ({
      ...item,
      seqNo: item.seqNo,
      routePointSts: item.routePointSts,
    }));

    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        const itemRows = { ...row };
        if (row.rowNumber === rowNumber) {
          return {
            ...itemRows,
            possibleStatusId: newCurrency,
          };
        }
        return itemRows;
      });
      return updatedRows;
    });

    setOpen(false);
  };

  const handleAddList = cd => {
    const newData = possibleList.find(v => v.cd === cd);
    setSelectedList(old => [...old, { seqNo: `${selectedList.length + 1}`, routePointSts: newData.value }]);
  };

  const handleDeleteSelected = id => {
    const newArray = [...selectedList];
    newArray.splice(id, 1);
    setSelectedList(newArray);
  };

  const handleChangeSeqNo = (event, index) => {
    setSelectedList(prev => {
      return prev.map((item, i) => (i === index ? { ...item, seqNo: event.target.value } : item));
    });
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose}>
        <DialogContent>
          <div className="btn-close" onClick={handleClose}>
            Close
          </div>
          <HeaderContentBar title="WDN91031 : List of Possible Status Screen" />
          {/* </DialogContentText> */}
          <AlertMessage type={"warning"} message={msgError} />
          <Box className="box">
            <Box className="box-left">
              <div className="head">
                <span>Status</span>
                <span>Add</span>
              </div>
              <ul className="list-data">
                {possibleList?.map((val, i) => (
                  <li key={i}>
                    <div>
                      <span>{val.value}</span>
                    </div>
                    <div>
                      <InputButton
                        value="Add"
                        color="info"
                        size="small"
                        style={{
                          minWidth: "45px",
                          textTransform: "none",
                        }}
                        tabIndex={1}
                        onClick={() => handleAddList(val.cd)}
                        disabled={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode)}
                        noRoleRequired={true}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </Box>
            <Box className="box-right">
              <div className="head">
                <span>Sequence</span>
                <span>List of possible status</span>
                <span>Delete</span>
              </div>
              <ul
                className="list-data-seleted"
                style={{
                  width: "99%",
                }}>
                {selectedList?.map((val, i) => (
                  <li key={i}>
                    <div className="area-data-selected">
                      <div style={{ textAlign: "right" }}>
                        <InputText
                          type="text"
                          fullWidth={false}
                          style={{
                            width: "40px",
                            textAlign: "right",
                          }}
                          value={val.seqNo}
                          onChange={e => {
                            handleChangeSeqNo(e, i);
                          }}
                          tabIndex={1}
                          maxLength={1}
                          disabled={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode)}
                        />
                      </div>
                      <div style={{ textAlign: "center", paddingLeft: "3rem" }}>
                        <span
                          style={{
                            alignContent: "center",
                          }}>
                          {val.routePointSts}
                        </span>
                      </div>
                      <div
                        className="icon-delete"
                        style={{
                          textAlign: "right",
                          paddingRight: "2rem",
                        }}>
                        <DeleteIcon
                          color={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode) ? "disabled" : "error"}
                          fontSize="small"
                          onClick={() =>
                            ![ModeAction.VIEW, ModeAction.SELECTED].includes(mode) ? handleDeleteSelected(i) : null
                          }
                          tabIndex={1}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            {![ModeAction.VIEW, ModeAction.SELECTED].includes(mode) && (
              <>
                <InputButton
                  onClick={handleSaveClick}
                  color={"primary"}
                  value={"Confirm"}
                  name={"WDN91031Confirm"}
                  tabIndex={1}
                />
                <InputButton
                  onClick={handleCancelClick}
                  color={"secondary"}
                  value={"Cancel"}
                  name={"WDN91031Cancel"}
                  tabIndex={1}
                />
              </>
            )}
          </Box>
          <Box
            style={{
              background: "#EB0A1E",
              textAlign: "center",
              color: "#fff",
              padding: "4px 0",
              marginTop: "10px",
            }}>
            Polygon &copy; 2024 version 1.0
          </Box>
        </DialogContent>
      </Dialog>
      {/* ConfirmDialog Close Modal */}
      <ConfirmDialog
        open={isConfirmCancelOpen}
        onClose={() => setConfirmCancelOpen(false)}
        onConfirm={() => {
          setConfirmCancelOpen(false);
          handleCancelConfirm();
        }}
        message={messageTypeDisplay(MessageType.CLOSE_UPDATE_DATA)}
        noRoleRequired={true}
      />
    </React.Fragment>
  );
}
