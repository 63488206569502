import axiosInstance, { AxiosError } from "axios";
import * as notistack from "notistack";

const serviceErrorTimestamps = new Map();
const ERROR_TIMEOUT = 5 * 1000;

const handleError = error => {
  const baseUrl = error.config.url.split("/");
  const serviceName = baseUrl[1];

  const lastErrorTime = serviceErrorTimestamps.get(serviceName);
  const currentTime = Date.now();

  if (!lastErrorTime || currentTime - lastErrorTime > ERROR_TIMEOUT) {
    notistack.enqueueSnackbar(
      `Service (${serviceName.replace(/-/g, " ").toUpperCase()} ) is maintenance, please contact support.`,
      {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }
    );
    serviceErrorTimestamps.set(serviceName, currentTime);
    return `Service (${serviceName.replace(/-/g, " ").toUpperCase()} ) is maintenance, please contact support.`;
  }
};

const axios = axiosInstance.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  // TODO: timeout
  timeout: 2 * 60 * 1000, // 60000 = 1 minute
  timeoutErrorMessage: "Axios TIMEOUT",
  headers: {
    "x-api-key": process.env.REACT_APP_X_API_KEY,
  },
});
// Request interceptor
axios.interceptors.request.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  (error = AxiosError) => {
    switch (error.response?.status) {
      case 400:
        return Promise.reject(error);
      case 401:
        return Promise.reject(error);

      case 500:
        return Promise.reject(error.response.data);
      case 503:
        handleError(error);
        return Promise.reject({
          response: {
            statusCode: 503,
            data: {
              errors: [
                {
                  code: "MSTD0067AERR",
                  message: `Undefine Error : Internal server error (Connection Timeout). Please try again later.`,
                },
              ],
            },
          },
        });
      case 504:
        console.error(error);
        handleError(error);
        return Promise.reject({
          response: {
            statusCode: 503,
            data: {
              errors: [
                {
                  code: "MSTD0067AERR",
                  message: `Undefine Error : Internal server error (Connection Timeout). Please try again later.`,
                },
              ],
            },
          },
        });
      default:
        console.error(error);
        handleError(error);
        if (!error.response) {
          // const err = {
          //   response: {
          //     statusCode: 504,
          //     data: {
          //       errors: [
          //         {
          //           code: "MSTD0067AERR",
          //           message: `Undefine Error : Internal server error (Service not available). Please try again later.`,
          //         },
          //       ],
          //     },
          //   },
          // };
          return Promise.reject();
          // break;
        }
        return Promise.reject(error);
    }
  }
);

export default axios;
