import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function PopupDialog({
  id,
  onOpen,
  title,
  content,
  action,
  maxWidth = "md",
  onFooter = false,
  contentFooter,
  rest,
  height = "100%",
}) {
  return (
    <Dialog
      id={`popup-${id}`}
      open={onOpen}
      fullWidth
      maxWidth={maxWidth}
      sx={{ height: height, overflow: "hidden" }}
      {...rest}>
      {title && <DialogTitle children={title} />}
      <DialogContent children={content} sx={{ padding: "12px 12px", overflow: "hidden" }}></DialogContent>
      {action && <DialogActions children={action}></DialogActions>}
      {onFooter && <Box sx={{ mt: 4, position: "sticky" }}>{contentFooter}</Box>}
    </Dialog>
  );
}
