import React, { useMemo, useState } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { DatePickerField, InputButton, InputDropDown, InputText } from "components/UI";
import {
  useGetMaker,
  useGetPONumberType,
  useGetRPackageType,
  useNewPrint,
  useSearchNewBarcodePrint,
} from "shared/services/master/barcodeprint";
import { userProfile } from "constant";
import { findObject, message, responseDownloadFileErrors, responseErrors } from "utils";
import { formatRow } from "../utils/formatRow";
import useColumnNewPrint, { useColumnGroupNewPrint } from "../hooks/useColumnNewPrint";
import DataTable from "pages/DataTable";
import InputCurrency from "components/UI/InputCurrency";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndPrintBarcode } from "shared/services/common-dowload/download-helper-service";
import { useSelector } from "react-redux";

const NewPrintMode = ({ mode, setMsgError, setMsgAlert, functionId }) => {
  const [form, setForm] = useState({
    mode: mode,
    makerImpExpCd: "",
    poNo: "",
    rPackTypeId: "",
    barcodeFrom: "",
    barcodeTo: "",

    totalRemainingQuantity: "",
    poPeriod: "",
    printed: "",
    numberOfCopy: "",
    dataSearch: {},
  });
  const userInfo = useSelector(state => state.auth.user);
  const [rows, setRows] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState([]);
  const [deliveryPlanDt, setDeliveryPlanDt] = useState([]);
  const [disabledPrintBarcodeBtn, setDisabledPrintBarcodeBtn] = useState(false);
  const { startLoading, stopLoading } = useLoadingContext();

  // api
  const { data: RmMakerData } = useGetMaker();

  const { data: poNumberData } = useGetPONumberType({
    impExpCd: form?.makerImpExpCd,
  });

  const { data: RpackageTypData } = useGetRPackageType({
    rPkgOwnerCompAbbr: userProfile.rPkgOwnerCompAbbr,
  });

  const searchDataAsync = useSearchNewBarcodePrint({});
  const confirmDialogCtx = useConfirmDialogContext();

  const createDataAsync = useNewPrint();
  const columnGroupingModel = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumnGroupNewPrint({ deliveryDate, deliveryPlanDt });
  }, [deliveryDate, deliveryPlanDt]);

  const columns = useMemo(() => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useColumnNewPrint({ deliveryDate, deliveryPlanDt });
    } catch (error) {
      console.error(error);
    }
  }, [deliveryDate, deliveryPlanDt]);
  // handle clear
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setOnSearch(false);
    setForm({
      makerImpExpCd: "",
      poNo: "",
      rPackTypeId: "",
      barcodeFrom: "",
      barcodeTo: "",
      totalRemainingQuantity: "",
      poPeriod: "",
      printed: "",
      numberOfCopy: "",
      dataSearch: {},
    });

    setTimeout(() => {
      document.getElementById("select_makerImpExpCd").focus();
    }, 600);
  };
  // handle search
  const handleSearch = async () => {
    try {
      // reset Form
      setMsgAlert([]);
      setMsgError([]);
      setRows([]);
      setDeliveryDate([]);
      setDeliveryPlanDt("");
      setDisabledPrintBarcodeBtn(false);

      const body = {
        dataOwner: userProfile.dataOwner,
        printType: mode,
        makerImpExpCd: form.makerImpExpCd,
        poNo: form.poNo,
        rPkgTypeId: form.rPackTypeId,
        rPkgType: findObject({
          data: RpackageTypData,
          value: form.rPackTypeId,
          property: "rPkgTypeId",
          field: "rPkgType",
        }),
        barcodeFrom: form.barcodeFrom,
        barcodeTo: form.barcodeTo,
        // barcodeFrom: formatDateToSave(document.querySelector(".input_barcodeFrom input").value),
        // barcodeTo: formatDateToSave(document.querySelector(".input_barcodeTo input").value),
      };
      const searchData = await searchDataAsync(body);
      if (searchData && searchData.length > 0) {
        setRows([]);
        setOnSearch(false);
        return;
      }

      const { data, deliveryDate, deliveryPlanDt } = formatRow(searchData);
      const allDates = new Set();
      data.forEach(item => {
        Object.keys(item).forEach(key => {
          if (/^\d{4}-\d{2}-\d{2}$/.test(key)) {
            allDates.add(key);
          }
        });
      });
      const sortedDates = Array.from(allDates).sort((a, b) => new Date(a) - new Date(b));

      data.map(item => {
        sortedDates.forEach(date => {
          if (!item.hasOwnProperty(date)) {
            item[date] = "0"; // Add missing date with default value 0
          }
        });
        return item;
      });

      setRows(data);
      setDeliveryDate(deliveryDate);
      setDeliveryPlanDt(deliveryPlanDt);
      setForm(old => ({
        ...old,
        totalRemainingQuantity: searchData?.result?.totalRemainingQuantity,
        numberOfCopy: searchData?.result?.numberOfCopy,
        poPeriod: searchData?.result?.poPeriod,
        printed: "",
        dataSearch: searchData?.result,
      }));
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      setRows([]);
      setOnSearch(false);
      setDeliveryDate([]);
      setDeliveryPlanDt([]);
      return;
    }
  };

  // handle print
  const handlePrint = async () => {
    setMsgAlert([]);
    setMsgError([]);
    const saveConfirm = await confirmDialogCtx.success({
      type: "confirmPrint",
    });
    if (!saveConfirm) {
      return;
    }
    try {
      const body = {
        dataOwner: userProfile.dataOwner,
        rPkgType: findObject({
          data: RpackageTypData,
          value: form.rPackTypeId,
          property: "rPkgTypeId",
          field: "rPkgType",
        }),
        poNo: form.poNo,
        printQty: form.printed,
        numOfCopy: form.numberOfCopy,
        printLocation: userInfo.companyAbbr,
        user: {
          userInfo: {
            userId: userInfo.userName,
            email: userInfo.mail,
          },
        },
      };
      startLoading();

      const response = await createDataAsync(body);
      waitAndPrintBarcode(180, response, setMsgError, stopLoading, () => {
        setDisabledPrintBarcodeBtn(true);
        setMsgAlert([message({ type: "added" })]);
        handleSearch();
      });
      return;
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      stopLoading();
      return;
    }
  };

  return (
    <>
      <Grid item xs={7} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>*R/M Maker : </strong>
        <InputDropDown
          id="select_makerImpExpCd"
          value={form?.makerImpExpCd}
          onChange={e =>
            setForm(old => ({
              ...old,
              makerImpExpCd: e.target.value,
              poNo: "",
            }))
          }
          memu={RmMakerData?.map(val => ({
            key: val.impExpCd,
            value: val.impExpCd,
          }))}
          disabled={onSearch}
          placeholder="<Select>"
          defaultValue=""
          required
          tabIndex={1}
          focus={true}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>*P/O Number : </strong>
        <InputDropDown
          id="select_poNo"
          value={form?.poNo}
          onChange={e =>
            setForm(old => ({
              ...old,
              poNo: e.target.value,
            }))
          }
          memu={poNumberData?.map(val => ({
            key: val.poNo,
            value: val.poNo,
          }))}
          placeholder="<Select>"
          defaultValue=""
          required
          disabled={onSearch}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>*R-Package Type : </strong>
        <InputDropDown
          id="select_rPackTypeId"
          value={form?.rPackTypeId}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPackTypeId: e.target.value,
            }))
          }
          memu={RpackageTypData?.map(val => ({
            key: val.rPkgTypeId,
            value: val.rPkgType,
          }))}
          placeholder="<Select>"
          defaultValue=""
          required
          disabled={onSearch}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={7} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>Delivery Plan Date (From) : </strong>
        <DatePickerField
          id="input_barcodeFrom"
          fullWidth
          onChange={e =>
            setForm(old => ({
              ...old,
              barcodeFrom: e,
            }))
          }
          value={form?.barcodeFrom}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>Delivery Plan Date (To) : </strong>
        <DatePickerField
          id="input_barcodeTo"
          fullWidth
          onChange={e =>
            setForm(old => ({
              ...old,
              barcodeTo: e,
            }))
          }
          value={form?.barcodeTo}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={12} display="flex" justifyContent="flex-end" marginTop="24px">
        <InputButton value="Search" onClick={handleSearch} name={`${functionId}Search`} tabIndex={1} />
        <InputButton
          value="Clear"
          onClick={handleClear}
          onKeyDown={e => {
            if (!onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select_makerImpExpCd").focus();
            }
          }}
          name={`${functionId}Clear`}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>

      {onSearch && (
        <>
          <Box
            sx={{
              overflowX: "scroll",
              "-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              scrollbarWidth: "none",
            }}>
            <DataTable
              checkboxSelection={false}
              onSearch={onSearch}
              rows={rows}
              columns={columns}
              enabledPagination={false}
              processRowUpdate={null}
              handleChangePagination={null}
              columnGroupingModel={columnGroupingModel}
              onRowSelectionModelChange={null}
              columnVisibilityModel={{ rowNumber: false }}
              rowHeight={25}
              rowHeightAuto={false}
              minHeight="none"
              paddingBottom="5px"
              girdSx={{ padding: "0px" }}
              containerSx={{ width: "100%" }}
            />
          </Box>

          <Box sx={{ width: "100%", mt: 2 }}>
            <Box sx={{ width: "60%" }}>
              <Grid container spacing={0} display="flex" alignItems="center">
                <Grid item xs={3}>
                  <strong>Total Remaining Quantity : </strong>
                </Grid>
                <Grid item xs={3}>
                  <InputText
                    fullWidth
                    id="input_totalRemainingQuantity"
                    value={form?.totalRemainingQuantity}
                    onChange={e =>
                      setForm(old => ({
                        ...old,
                        totalRemainingQuantity: e.target.value,
                      }))
                    }
                    style={{ textAlign: "right" }}
                    disabled
                    tabIndex={1}
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2}>
                  <strong>P/O Period : </strong>
                </Grid>
                <Grid item xs={3}>
                  <InputText
                    fullWidth
                    id="input_poPeriod"
                    value={form?.poPeriod}
                    onChange={e =>
                      setForm(old => ({
                        ...old,
                        poPeriod: e.target.value,
                      }))
                    }
                    disabled
                    tabIndex={1}
                  />
                </Grid>
                <Grid item xs={3}>
                  <strong>*Print Quantity : </strong>
                </Grid>
                <Grid item xs={3} sx={{ marginBottom: "calc(8px * 0.8)" }}>
                  <InputCurrency
                    fullWidth
                    id="input_printed"
                    value={form.printed}
                    onChange={e =>
                      setForm(old => ({
                        ...old,
                        printed: e.value,
                      }))
                    }
                    style={{ textAlign: "right" }}
                    required
                    maxLimit={1000000}
                    tabIndex={2}
                  />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={3}>
                  <strong>*Number or Copy : </strong>
                </Grid>
                <Grid item xs={2}>
                  <InputCurrency
                    fullWidth
                    id="input_numOfCopy"
                    value={form?.numberOfCopy}
                    onChange={e =>
                      setForm(old => ({
                        ...old,
                        numberOfCopy: e.value,
                      }))
                    }
                    style={{ textAlign: "right" }}
                    required
                    maxLimit={10}
                    tabIndex={2}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <InputButton
              value="Print Barcode"
              onClick={handlePrint}
              disabled={disabledPrintBarcodeBtn}
              tabIndex={2}
              onKeyDown={e => {
                if (onSearch && e.key === "Tab") {
                  e.preventDefault();
                  document.getElementById("input_barcodeFrom").focus();
                }
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default NewPrintMode;
