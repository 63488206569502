import React, { Fragment } from "react";
import { TanStackTable } from "components/UI";
// Utils
import { responseErrors } from "utils";
//API
export default function TableBar(props) {
  const {
    rows,
    columns,
    setMsg: { setMsgAlert, setMsgError },
    setRowModesModel,
    rowModesModel,
    rowSelectionModel,
    setRowSelectionModel,
  } = props;

  const processRowUpdate = row => {
    try {
      setRowSelectionModel([]);
      setRowSelectionModel(row);
      return true;
    } catch (error) {
      console.error(error);
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  return (
    <Fragment>
      <TanStackTable
        rows={rows}
        columns={columns}
        getRowId={row => row.rowNumber}
        processRowUpdate={processRowUpdate}
        rowModesModel={rowModesModel}
        rowSelectionKey={rowModesModel}
        setRowSelectionKey={setRowModesModel}
        rowSelection={rowSelectionModel}
        setRowSelect={setRowSelectionModel}
        enableRowSelection={false}
        enableMultiRowSelection={true}
        tableHeight="600px"
      />
    </Fragment>
  );
}
