import React from "react";
import { Grid, Box } from "@mui/material";
import { DropDown, InputMask } from "components/UI";
import { DatePickerField } from "components/UI";
// Constant and Type
import { ModeAction } from "state/enum";
import { API_SHIPMENT } from "shared/constants";
export default function GroupingFilterBar(props) {
  const {
    mode,
    form,
    setForm,
    dataList: { exporterList, importerList, rPkgList, statusList },
  } = props;

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={3} sx={{ alignItems: "center" }}>
        <Grid item xs={3} style={{ display: "flex" }}>
          <strong style={{ margin: 'auto' }} className="text-field-label">*Exporter :</strong>
          <DropDown
            id="select_exporter"
            required
            value={form?.exporterId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.EXPORTER_ID]: e.target.value,
              }));
            }}
            menu={exporterList?.map(val => ({
              key: val.impExpCd,
              value: val.impExpCd,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<Select>"
            autoFocus={true}
            tabIndex={1}
          />
        </Grid>

        <Grid item xs={3} style={{ display: "flex" }}>
          <strong style={{ margin: 'auto' }} className="text-field-label">*Importer :</strong>
          <DropDown
            id="select_importer"
            required
            value={form.importerId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.IMPORTER_ID]: e.target.value,
              }));
            }}
            menu={importerList?.map(val => ({
              key: val.companyId,
              value: val.companyAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            defaultValue=""
            placeholder="<Select>"
            tabIndex={1}
          />
        </Grid>

        <Grid item xs={3} style={{ display: "flex" }}>
          <strong style={{ margin: 'auto' }} className="text-field-label">*R-Package Owner :</strong>
          <DropDown
            id="select_r_pkg_owner"
            required
            value={form.rPkgOwnerId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.R_RKG_OWNER_ID]: e.target.value,
              }));
            }}
            menu={rPkgList?.map(val => ({
              key: val.rPkgOwnerCompId,
              value: val.rPkgOwnerCompAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<Select>"
            tabIndex={1}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ alignItems: "center", marginTop: 0.5 }}>
        <Grid item xs={3} style={{ display: "flex" }}>
          <strong style={{ margin: 'auto' }} className="text-field-label">*Forecast Month :</strong>
          <InputMask
            id="input-monthly-month"
            mask="mM/YYYY"
            fullWidth={true}
            formatChars={{
              Y: "[0-9]",
              m: "[0-1]",
              M: "[0-9]",
            }}
            maskChar={null}
            alwaysShowMask={false}
            value={form?.forecastMonth}
            onChange={e =>
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.FORECAST_MONTH]: e.target.value,
              }))
            }
            required
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            tabIndex={1}
          />
          <strong style={{ margin: 'auto', paddingLeft: '10px' }} className="text-field-label">MM/YYYY</strong>
        </Grid>

        <Grid item xs={3} style={{ display: "flex" }}>
          <strong style={{ margin: 'auto' }} className="text-field-label">Status :</strong>
          <DropDown
            id="select_shipment_status"
            value={form?.shipmentStsId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.SHIPMENT_STATUS_ID]: e.target.value,
              }));
            }}
            menu={statusList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            defaultValue=""
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<All>"
            tabIndex={1}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ alignItems: "center", marginTop: 0.5 }}>
        <Grid item xs={3} style={{ display: "flex" }}>
          <strong style={{ margin: 'auto' }} className="text-field-label">ETD From :</strong>
          <DatePickerField
            fullWidth={true}
            id="input_etd_from"
            value={form?.etdFrom}
            onChange={newValue => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.ETD_FORM]: newValue,
              }));
            }}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={3} style={{ display: "flex" }}>
          <strong style={{ margin: 'auto' }} className="text-field-label">ETD To :</strong>
          <DatePickerField
            id="input_etd_to"
            fullWidth={true}
            value={form?.etdTo}
            onChange={newValue => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.ETD_TO]: newValue,
              }));
            }}
            tabIndex={1}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
