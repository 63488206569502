import { Grid } from "@mui/material";
import { DropDown, InputTextField } from "components/UI";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import { FORM_DEFAULT_AGING_STOCK } from "pages/Report/constants/constants";
import {
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportCommonGetLocationNameListQuery,
  useReportGetAvailableRPackageStatusQuery,
  useReportGetAvailablePackStatusQuery,
  useReportGetDefaultMaximumAgingDayQuery,
  useReportGetDefaultTargetLineForWIP2EmptyQuery,
  useReportGetDefaultTargetLineForFGEmptyQuery,
  useReportGetDefaultTargetLineForFGPackQuery,
  useReportGetDefaultTargetLineForNGEmptyQuery,
  useReportGetDefaultTargetLineForNGPackQuery,
  useReportGetDefaultTargetLineForWIP1EmptyQuery,
  useExcelDownloadOnlineJavaMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseErrors } from "utils";
import InputCurrency from "components/UI/InputCurrency";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { useSelector } from "react-redux";
export default function AgingStockReport(props) {
  const userInfo = useSelector(state => state.auth.user);
  const [form, setForm] = useState(FORM_DEFAULT_AGING_STOCK);
  const { startLoading, stopLoading } = useLoadingContext();
  //API
  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeQuery(form?.rPkgTypeOwner);
  const { data: locationFromToList } = useReportCommonGetLocationNameListQuery(userProfile.dataOwner);
  const { data: rPackageStatusList } = useReportGetAvailableRPackageStatusQuery();
  const { data: packStatusList } = useReportGetAvailablePackStatusQuery();
  const { data: maximumAgingDayList } = useReportGetDefaultMaximumAgingDayQuery();
  const { data: wIP1EmptyList } = useReportGetDefaultTargetLineForWIP1EmptyQuery();
  const { data: wIP2EmptyList } = useReportGetDefaultTargetLineForWIP2EmptyQuery();
  const { data: fgEmptyList } = useReportGetDefaultTargetLineForFGEmptyQuery();
  const { data: fgPackList } = useReportGetDefaultTargetLineForFGPackQuery();
  const { data: ngEmptyList } = useReportGetDefaultTargetLineForNGEmptyQuery();
  const { data: ngPackList } = useReportGetDefaultTargetLineForNGPackQuery();

  const generateReport = useExcelDownloadOnlineJavaMutation();
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);

      const body = {
        userId: userInfo.userName,
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        locationName: form?.location,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        rPackageStatus: form?.rPkgStatus,
        pPackStatus: form?.packStatus,
        maxAgingDay: parseInt(determineValue(form?.maximumAgingDay, maximumAgingDayList)),
        wip1Empty: parseInt(form?.wip1Empty),
        wip2Empty: parseInt(form?.wip2Empty),
        fgEmpty: parseInt(form?.fgEmpty),
        fgPack: parseInt(form?.fgPack),
        ngEmpty: parseInt(form?.ngEmpty),
        ngPack: parseInt(form?.ngPack),
        total: parseInt(form?.total),
      };

      const pathReport = "generate-aging-stock-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(60, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      stopLoading();
      setDisabledSubmitBtn(false);
    }
  };

  const determineValue = (formValue, initValue) => {
    if (formValue?.length) {
      return formValue;
    }
    if (initValue?.length) {
      return initValue[0]?.value;
    }
  };

  const getWipValue = v => {
    if (v) {
      if (v.length < 0) return 0;
      return v[0]?.value;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    return setForm(old => ({
      ...old,
      wip1Empty: getWipValue(wIP1EmptyList),
      wip2Empty: getWipValue(wIP2EmptyList),
      fgEmpty: getWipValue(fgEmptyList),
      fgPack: getWipValue(fgPackList),
      ngEmpty: getWipValue(ngEmptyList),
      ngPack: getWipValue(ngPackList),
    }));
  }, [wIP1EmptyList, wIP2EmptyList, fgEmptyList, fgPackList, ngEmptyList, ngPackList]);

  useEffect(() => {
    const total =
      Number(form.wip1Empty) +
      Number(form.wip2Empty) +
      Number(form.fgEmpty) +
      Number(form.fgPack) +
      Number(form.ngEmpty) +
      Number(form.ngPack);

    return setForm(old => ({
      ...old,
      total: total,
    }));
  }, [form.wip1Empty, form.wip2Empty, form.fgEmpty, form.fgPack, form.ngEmpty, form.ngPack]);

  return (
    <Grid container>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>*Report : </strong>
        <DropDown
          id="select-report"
          fullWidth
          required
          defaultValue=""
          placeholder="<Select>"
          menu={[{ key: "LDN960H0", value: "Aging Stock Report" }]}
          value={"LDN960H0"}
          disabled
        />
      </Grid>
      <Grid item xs={7} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>*Location : </strong>
        <DropDown
          id="select-location"
          fullWidth
          required
          value={form?.location}
          defaultValue=""
          placeholder="<Select>"
          autoFocus={true}
          menu={locationFromToList?.map(val => ({
            key: val?.name,
            value: val?.name,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              location: e.target.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Owner : </strong>
        <DropDown
          id="select-rpackage-owner"
          fullWidth
          value={form?.rPkgTypeOwner}
          defaultValue=""
          placeholder="<All>"
          // autoFocus={true}
          menu={rPkgOwnerList?.map(val => ({
            key: val?.rPkgOwnerCompAbbr,
            value: val?.rPkgOwnerCompAbbr,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPkgTypeOwner: e.target.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Type : </strong>
        <DropDown
          id="select-rpackage-type"
          fullWidth
          value={form?.rPkgType}
          defaultValue=""
          placeholder="<All>"
          // autoFocus={true}
          menu={rPkgTypeList?.map(val => ({
            key: val?.rPkgType,
            value: val?.rPkgType,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPkgType: e.target.value,
            }))
          }
          disabled={!form?.rPkgTypeOwner.length}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Status : </strong>
        <DropDown
          id="select-rpackage-status"
          fullWidth
          value={form?.rPkgStatus}
          defaultValue=""
          placeholder="<All>"
          // autoFocus={true}
          menu={rPackageStatusList?.map(val => ({
            key: val?.cd,
            value: val?.value,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              rPkgStatus: e.target.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>Pack Status : </strong>
        <DropDown
          id="select-pack-status"
          fullWidth
          value={form?.packStatus}
          defaultValue=""
          placeholder="<All>"
          menu={packStatusList?.map(val => ({
            key: val?.cd,
            value: val?.value,
          }))}
          onChange={e =>
            setForm(old => ({
              ...old,
              packStatus: e.target.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "0px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>Maximum Aging Day : </strong>
        {maximumAgingDayList?.[0]?.value ? (
          <InputTextField
            id="input-maximum-aging-day"
            fullWidth={true}
            maxLength={3}
            textalign="right"
            defaultValue={maximumAgingDayList?.[0]?.value}
            regularExp={/^[\d.]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                maximumAgingDay: e.target.value,
              }))
            }
          />
        ) : null}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={12} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>Target Line (Day) For : </strong>
      </Grid>
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>WIP1 - EMPTY : </strong>
        <InputCurrency
          style={{ background: "white" }}
          id="input-wip1-empty"
          fullWidth={true}
          maxLimit={999999}
          decimalScale={2}
          value={form?.wip1Empty}
          // regularExp={/^[a-zA-Z0-9_*-]*$/}
          onChange={e =>
            setForm(old => ({
              ...old,
              wip1Empty: e.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>WIP2 - EMPTY : </strong>
        <InputCurrency
          style={{ background: "white" }}
          id="input-wip2-empty"
          fullWidth={true}
          maxLimit={999999}
          decimalScale={2}
          value={form?.wip2Empty}
          // regularExp={/^[a-zA-Z0-9_*-]*$/}
          onChange={e =>
            setForm(old => ({
              ...old,
              wip2Empty: e.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>FG - EMPTY : </strong>
        <InputCurrency
          style={{ background: "white" }}
          id="input-fg-empty"
          fullWidth={true}
          maxLimit={999999}
          decimalScale={2}
          value={form?.fgEmpty}
          // regularExp={/^[a-zA-Z0-9_*-]*$/}
          onChange={e =>
            setForm(old => ({
              ...old,
              fgEmpty: e.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>FG - PACK : </strong>
        <InputCurrency
          style={{ background: "white" }}
          id="input-fg-pack"
          fullWidth={true}
          maxLimit={999999}
          decimalScale={2}
          value={form?.fgPack}
          // regularExp={/^[a-zA-Z0-9_*-]*$/}
          onChange={e =>
            setForm(old => ({
              ...old,
              fgPack: e.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>NG - EMPTY : </strong>
        <InputCurrency
          style={{ background: "white" }}
          id="input-ng-empty"
          fullWidth={true}
          maxLimit={999999}
          decimalScale={2}
          value={form?.ngEmpty}
          // regularExp={/^[a-zA-Z0-9_*-]*$/}
          onChange={e =>
            setForm(old => ({
              ...old,
              ngEmpty: e.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>NG - PACK : </strong>
        <InputCurrency
          style={{ background: "white" }}
          id="input-ng-pack"
          fullWidth={true}
          maxLimit={999999}
          decimalScale={2}
          value={form?.ngPack}
          onChange={e =>
            setForm(old => ({
              ...old,
              ngPack: e.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
        <strong style={{ margin: "auto 0px", width: "300px" }}>Total : </strong>
        <InputCurrency
          style={{ background: "white" }}
          id="input-total"
          fullWidth={true}
          maxLimit={999999}
          decimalScale={2}
          value={form?.total}
          onChange={e =>
            setForm(old => ({
              ...old,
              total: e.value,
            }))
          }
        />
      </Grid>
      <Grid item xs={1} />

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_AGING_STOCK}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
        firstField={"select-location"}
      />
    </Grid>
  );
}
