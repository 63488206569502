import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import React, { Fragment } from "react";
import { ModeAction } from "state/enum";

export default function CriterialPrint({
  form,
  setForm,
  setRows,
  setMode,
  setOnSearch,
  setRowSelectionModel,
  dataList: { deliveryTypeList, packStatusList, senderList, receiverList, shippingInfoList },
}) {
  const handleDvlType = e => {
    try {
      setForm(prev => ({
        ...prev,
        deliveryType: e.target.value,
        sender: "",
        receiver: "",
      }));
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
      setOnSearch(false);
      return;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={5} style={{ display: "flex", padding: "5px 0" }}>
          <strong style={{ margin: "auto 0px", width: "350px" }}>*Actual Delivery Date (From) :</strong>
          <DatePickerField
            id="date-picker-actual-from"
            required={true}
            fullWidth={true}
            autoFocus={true}
            views={["year", "month", "day"]}
            value={form?.actualDtFrom}
            onChange={date => setForm(prev => ({ ...prev, actualDtFrom: date }))}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "5px 0" }}>
          <strong style={{ margin: "auto 0px", width: "350px" }}>Actual Delivery Date (To) :</strong>
          <DatePickerField
            id="date-picker-actual-to"
            fullWidth={true}
            views={["year", "month", "day"]}
            value={form?.actualDtTo}
            onChange={date => setForm(prev => ({ ...prev, actualDtTo: date }))}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "5px 0" }}>
          <strong style={{ margin: "auto 0px", width: "350px" }}>*Delivery Type :</strong>
          <DropDown
            id="combobox-delivery-type"
            required={true}
            defaultValue=""
            placeholder="<Select>"
            value={form?.deliveryType}
            onChange={handleDvlType}
            menu={deliveryTypeList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "5px 0" }}>
          <strong style={{ margin: "auto 0px", width: "350px" }}>Sender :</strong>
          <DropDown
            id="combobox-sender"
            placeholder="<All>"
            defaultValue=""
            value={form?.sender}
            onChange={e => setForm(prev => ({ ...prev, sender: e.target.value }))}
            menu={senderList?.map(val => ({
              key: val?.senderId,
              value: val?.senderName,
            }))}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "5px 0" }}>
          <strong style={{ margin: "auto 0px", width: "350px" }}>Receiver :</strong>
          <DropDown
            id="combobox-receiver"
            placeholder="<All>"
            defaultValue=""
            value={form?.receiver}
            onChange={e => setForm(prev => ({ ...prev, receiver: e.target.value }))}
            menu={receiverList?.map(val => ({
              key: val?.receiverId,
              value: val?.receiverName,
            }))}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "5px 0" }}>
          <strong style={{ margin: "auto 0px", width: "350px" }}>Shipping Information :</strong>
          <DropDown
            id="combobox-shipping-info"
            placeholder="<All>"
            defaultValue=""
            value={form?.shippingInfo}
            onChange={e => setForm(prev => ({ ...prev, shippingInfo: e.target.value }))}
            menu={shippingInfoList?.map((val, index) => ({
              key: index,
              value: `${val?.shippingCompany}:${val?.shippingDate}`,
            }))}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "0 0" }}>
          <strong style={{ margin: "auto 0px", width: "350px" }}>Container No. :</strong>
          <InputTextField
            id="text-field-container-no"
            fullWidth
            value={form?.containerNo}
            onChange={e =>
              setForm(prev => ({
                ...prev,
                containerNo: e.target.value?.toUpperCase(),
              }))
            }
            maxLength={15}
            regularExp={/^[a-zA-Z0-9]*$/}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "0 0" }}>
          <strong style={{ margin: "auto 0px", width: "350px" }}>Delivery Instruction Sheet No. :</strong>
          <InputTextField
            id="text-field-delivery-instr-sht-no"
            fullWidth
            value={form?.deliveryInstrSheetNo}
            onChange={e =>
              setForm(prev => ({
                ...prev,
                deliveryInstrSheetNo: e.target.value?.toUpperCase(),
              }))
            }
            maxLength={20}
            regularExp={/^[a-zA-Z0-9]*$/}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", padding: "5px 0" }}>
          <strong style={{ margin: "auto 0px", width: "350px" }}>Pack Status :</strong>
          <DropDown
            id="combobox-pack-status"
            placeholder="<All>"
            defaultValue=""
            value={form?.packStatus}
            onChange={e => setForm(prev => ({ ...prev, packStatus: e.target.value }))}
            menu={packStatusList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
}
