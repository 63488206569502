import { useMutateData, useMutateDownload, useCustomQuery } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;

export const useGetRpkg = ({ dataOwner, rPkgOwnerCompAbbr }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/gentan-i/r-package/${dataOwner}/${rPkgOwnerCompAbbr}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useSearchGentanI = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/gentan-i/search`,
      method: "POST",
      data: body,
    });
  };
  // return useMutation({
  //   url: "/gentan-i/search",
  //   method: "POST",
  //   data: {
  //     dataOwner,
  //     gantanITypeCd,
  //     expCd,
  //     impCd,
  //     rPkgType,
  //     carFamilyCd,
  //     subSeriesCd,
  //     effDtFrom,
  //     effDtTo,
  //     pageNumber,
  //     rowsPerPage,
  //   },
  // });
};

export const useCreateGentanI = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/gentan-i/create`,
      method: "POST",
      data: body,
    });
  };
};

export const useUpdateGentanI = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, gentanIId) => {
    return await mutate({
      url: `${BaseServicePath}/gentan-i/edit/${gentanIId}`,
      method: "PATCH",
      data: body,
    });
  };
};

export const useDeleteGentanI = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, gentanIId) => {
    return await mutate({
      url: `${BaseServicePath}/gentan-i/delete/${gentanIId}`,
      method: "DELETE",
      data: body,
    });
  };
};

export const useGentanIDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate(
      {
        url: `${BaseServicePath}/gentan-i/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },

      {
        onSuccess: response => {
          saveAs(response);
        },
      }
    );
  };
};
