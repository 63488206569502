import React, { useEffect } from "react";
import { FocusTrap } from "focus-trap-react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { InputButton } from ".";

const ConfirmDialog = ({ open, onClose, onConfirm, title = "", message, functionId, type, noRoleRequired }) => {
  const handleClose = (...args) => {
    const reason = args[1];
    if (reason === "backdropClick") return;
    onClose(args);
  };

  useEffect(() => {
    if (!document.getElementById("button-confirm-ok")) return;
    document.getElementById("button-confirm-ok").focus();
  }, []);

  return (
    <Dialog id="confirm-dialog" open={open} onClose={handleClose}>
      <FocusTrap active={open}>
        <div>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">{message}</Typography>
          </DialogContent>
          <DialogActions>
            <InputButton
              id="button-confirm-ok"
              onClick={onConfirm}
              color="primary"
              variant="contained"
              value={"OK"}
              name={`${functionId}${type}`}
              noRoleRequired={noRoleRequired}
              autoFocus
              tabIndex={1}
            />
            <InputButton
              id="button-confirm-cancel"
              onClick={onClose}
              color="secondary"
              variant="contained"
              value={"Cancel"}
              name={`${functionId}${type}`}
              noRoleRequired={noRoleRequired}
              tabIndex={1}
            />
          </DialogActions>
        </div>
      </FocusTrap>
    </Dialog>
  );
};

export default ConfirmDialog;
