import React from "react";
import { API_LOG_MONITORING } from "shared/constants";
import { ModeAction } from "state/enum";

export default function useColumn({ rows, mode, handleTabLogDetail }) {
  return [
    {
      field: API_LOG_MONITORING.ROW_NUMBER,
      headerName: "No",
      sortable: false,
      minWidth: 80,
      flex: 0.1,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    {
      field: API_LOG_MONITORING.APP_ID,
      headerName: "App ID",
      headerAlign: "center",
      sortable: false,
      // width: 100,
      minWidth: 100,
      flex: 0.1,
      editable: false,
    },
    {
      headerName: "Module",
      field: API_LOG_MONITORING.MODULE_NAME,
      headerAlign: "center",
      sortable: false,
      // width: 200,
      minWidth: 150,
      flex: 0.1,
      editable: false,
      renderCell: params => {
        return `${params.value}`;
      },
    },
    {
      headerName: "Function",
      field: API_LOG_MONITORING.FUNCTION_NAME,
      headerAlign: "center",
      sortable: false,
      // width: 400,
      minWidth: 450,
      flex: 0.3,
      editable: false,
    },
    {
      headerName: "Start Time",
      field: API_LOG_MONITORING.START_DATE,
      headerAlign: "center",
      sortable: false,
      // width: 140,
      minWidth: 140,
      flex: 0.1,
      editable: false,
      renderCell: params => {
        return `${params?.value} ${params?.row?.startTime}`;
      },
    },

    {
      headerName: "End Time",
      field: API_LOG_MONITORING.END_DATE,
      headerAlign: "center",
      sortable: false,
      // width: 200,
      minWidth: 140,
      flex: 0.1,
      editable: false,
      renderCell: params => {
        return `${params?.value} ${params?.row?.endTime}`;
      },
    },

    {
      headerName: "User ID",
      field: API_LOG_MONITORING.USER_ID,
      headerAlign: "center",
      sortable: false,
      // width: 150,
      minWidth: 150,
      flex: 0.1,
      editable: false,
    },

    {
      headerName: "Status",
      field: API_LOG_MONITORING.STATUS,
      align: "center",
      headerAlign: "center",
      sortable: false,
      // width: 150,
      minWidth: 90,
      flex: 0.1,
      editable: false,
      renderCell: params => {
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              handleTabLogDetail(params?.row);
            }}>
            {params.value}
          </span>
        );
      },
    },
    {
      headerName: "Message",
      field: API_LOG_MONITORING.MESSAGE,
      headerAlign: "center",
      sortable: false,
      // width: 650,
      minWidth: 600,
      flex: 1,
      editable: false,
    },
    {
      field: API_LOG_MONITORING.STATUS_ID,
      editable: false,
    },
    {
      field: API_LOG_MONITORING.START_TIME,
      editable: false,
    },
    {
      field: API_LOG_MONITORING.END_TIME,
      editable: false,
    },
    {
      field: API_LOG_MONITORING.MODULE_ID,
      editable: false,
    },
    {
      field: API_LOG_MONITORING.FUNCTION_ID,
      editable: false,
    },
  ];
}
