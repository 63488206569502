const INDEX_OF_FIG_COL = 1;
export default function useColumnNewPrint({ deliveryDate }) {
  try {
    const column = [
      {
        field: "warehouseSupplier",
        headerName: "",
        headerAlign: "center",
        align: "left",
        maxWidth: 200,
        minWidth: 200,
        flex: 0.1,
        sortable: false,
        editable: false,
      },
      { field: "rowNumber", editable: false },
    ];
    let newCol = [];
    newCol = deliveryDate.map((val, index) => ({
      field: val,
      headerName: val.split("-")[2],
      headerAlign: "center",
      align: "right",
      maxWidth: 80,
      minWidth: 80,
      flex: 0.1,
      sortable: false,
      editable: false,
    }));
    column.splice(INDEX_OF_FIG_COL, 0, ...newCol);
    return column;
  } catch (error) {
    console.error(error);
  }
}

export const useColumnGroupNewPrint = ({ deliveryDate, deliveryPlanDt }) => {
  try {
    const formatPlanDate = planDate => {
      const [year, month] = planDate.split("-");
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const monthIndex = parseInt(month, 10) - 1;

      return `${monthNames[monthIndex]} ${year}`;
    };

    const columnGroupingModel = [
      {
        groupId: "Warehouse Supplier",
        headerAlign: "center",
        children: [{ field: "warehouseSupplier" }],
        headerClassName: "align-items-end",
      },
    ];
    if (deliveryPlanDt?.length <= 0) {
      return columnGroupingModel;
    }
    let newCol = [];

    newCol = deliveryPlanDt?.map(planDate => ({
      groupId: formatPlanDate(planDate),
      headerAlign: "center",
      headerClassName: "table-header-group",
      children: deliveryDate
        .map(date => {
          const [year, month, day] = date.split("-");
          const yearMonth = `${year}-${month}`;
          return yearMonth === planDate ? { field: date, headerName: day } : null;
        })
        .filter(day => day !== null),
    }));
    const temp = [...columnGroupingModel, ...newCol];

    return temp;
  } catch (error) {
    console.error(error);
  }
};
