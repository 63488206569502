import { EditableDropDown, InputButton, InputEditTableCheckbox, InputEditTableText } from "components/UI";
import React from "react";
import { MessageType, ModeAction } from "state/enum";
import { findObject, isBlankOrNull } from "utils";
import Link from "@mui/material/Link";
export default function useColumnRoutePoint({
  mode,
  rows,
  routePointCategoryInTable,
  setRoutePointCategoryInTable,
  setCountryCdInTable,
  routePointCategoryInTableData,
  countryInTableData,
  companyPlantData,
  subPlantInTableData,
  rowSelectionModel,
  handleModalPossible,
  inOutStatusData,
}) {
  return [
    {
      field: "rowNumber",
      sortable: false,
      headerName: "No",
      align: "right",
      width: 50,
      editable: false,
      renderCell: params => {
        if (params.row.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    {
      field: "routePointCategory",
      sortable: false,
      headerName: "Route Point Category",
      headerAlign: "center",
      minWidth: 120,
      editable: ModeAction.ADD === mode,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Route Point Category"],
        },
      ],
      renderEditCell: params => {
        if (params.value !== "") {
          setRoutePointCategoryInTable(params.value);
        }

        if (params.value === "") {
          setRoutePointCategoryInTable("");
        }
        return (
          <EditableDropDown
            autoFocus
            {...params}
            menu={routePointCategoryInTableData?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            placeholder="<Select>"
            required
            tabIndex={1}
            clearValueWithoutChange={["countryCd", "companyPlant", "extensionCd"]}
          />
        );
      },
      renderCell: params => {
        const name =
          params.value &&
          findObject({
            data: routePointCategoryInTableData,
            value: params.value,
            property: "cd",
            field: "value",
          });

        return <p>{name}</p>;
      },
      flex: 2,
    },
    {
      field: "countryCd",
      sortable: false,
      headerName: "Country",
      headerAlign: "center",
      minWidth: 120,
      editable: ModeAction.ADD === mode,
      rule: [
        {
          type: routePointCategoryInTable === "C" ? MessageType.EMPTY : "",
          key: ["Country"],
        },
      ],
      renderEditCell: params => {
        if (params.value !== "") {
          setCountryCdInTable(params.value);
        }
        if (routePointCategoryInTable === "") {
          return;
        }
        return (
          <EditableDropDown
            {...params}
            menu={countryInTableData?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e => {
              params.api.setEditCellValue({
                id: params.row.rowNumber,
                field: "companyPlant",
                value: "",
              });
              params.api.setEditCellValue({
                id: params.row.rowNumber,
                field: "routePointName",
                value: "",
              });
              return;
            }}
            tabIndex={1}
            placeholder={routePointCategoryInTable === "C" ? "<Select>" : ""}
            required={routePointCategoryInTable === "C"}
          />
        );
      },
      renderCell: params => {
        const name =
          params.value &&
          findObject({
            data: countryInTableData,
            value: params.value,
            property: "cd",
            field: "value",
          });
        return <p>{name}</p>;
      },
      flex: 2,
    },
    {
      headerName: "Company/Plant",
      field: "companyPlant",
      sortable: false,
      headerAlign: "center",
      minWidth: 120,
      editable: ModeAction.ADD === mode,
      rule: [
        {
          type: routePointCategoryInTable === "C" ? MessageType.EMPTY : "",
          key: ["Company/Plant"],
        },
      ],
      renderEditCell: params => {
        if (routePointCategoryInTable === "C") {
          return (
            <EditableDropDown
              {...params}
              menu={companyPlantData?.map(val => ({
                key: val.plantId,
                value: val.companyPlant,
              }))}
              onChange={e => {
                const newValue = isBlankOrNull(e.target.value) ? "" : e.target.value;
                params.api.setEditCellValue({
                  id: params.row.rowNumber,
                  field: "routePointName",
                  value: findObject({
                    data: companyPlantData,
                    value: newValue,
                    property: "plantId",
                    field: "impExpCd",
                  }),
                });
                return;
              }}
              tabIndex={1}
              required
              placeholder="<Select>"
              fullWidth
            />
          );
        }
      },
      renderCell: params => {
        return params.value;
      },
      flex: 2,
    },
    {
      field: "extensionCd",
      sortable: false,
      headerName: "Sub Plant",
      headerAlign: "center",
      minWidth: 125,
      editable: ModeAction.ADD === mode,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Sub Plant"],
        },
      ],
      renderEditCell: params => {
        return (
          <EditableDropDown
            {...params}
            tabIndex={1}
            value={params.value}
            menu={subPlantInTableData?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            required
            placeholder="<Select>"
          />
        );
      },
      renderCell: params => {
        const { row } = params;
        return <p>{row.extensionValue}</p>;
      },
      flex: 2,
    },
    {
      headerName: "Route Point Name",
      field: "routePointName",
      sortable: false,
      align: "left",
      minWidth: 150,
      editable: ModeAction.ADD === mode,
      headerAlign: "center",
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Route Point Name"],
        },
      ],
      renderEditCell: params => {
        return (
          <InputEditTableText {...params} required maxLength={20} tabIndex={1} regularExp={/^[a-zA-Z0-9_*-/\\ /s]*$/} />
        );
      },
      renderCell: params => params.value,
      flex: 2,
    },
    {
      field: "possibleStatusId",
      rule: [
        {
          type: MessageType.MUST_BE_SELECTED,
          key: ["Possible Status"],
        },
      ],
    },
    {
      field: "possibleStatus",
      sortable: false,
      headerName: "Possible Status",
      minWidth: 125,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderEditCell: params => {
        const findRow = rows.find(v => v.rowNumber === params.id).possibleStatusId;
        if (findRow?.length) {
          const nameRs = findRow?.map(val => val.routePointSts).join(" | ") || params.value;
          return (
            <Link
              id={params.row.rowNumber === rowSelectionModel[0] ? "WDN91030_PossibleStatus" : ""}
              tabIndex={1}
              underline="none"
              autoFocus
              sx={{
                '&:focus': {
                  outline: '2px solid black',
                  borderRadius: '4px',
                },
              }}
              color={params.row.rowNumber === 1 ? "blue" : "black"}
              onClick={() => {
                if (params.row.rowNumber === rowSelectionModel[0]) {
                  handleModalPossible(params.id);
                }
              }}>
              {nameRs}
            </Link>
          );
        }
        return (
          <InputButton
            value={"Select"}
            color="info"
            size="small"
            tabIndex={1}
            onClick={() => handleModalPossible(params.id)}
            name={"WDN91030Add"}
          />
        );
      },
      renderCell: params => {
        const findRow = rows.find(v => v.rowNumber === params.id).possibleStatusId;

        if (ModeAction.VIEW === mode) {
          if (findRow?.length) {
            const nameRs = findRow?.map(val => val.routePointSts).join(" | ") || params.value;

            return (
              <Link
                href="#"
                underline="none"
                color={"blue"}
                tabIndex={1}
                onClick={() => {
                  handleModalPossible(params.id);
                }}>
                {nameRs}
              </Link>
            );
          }
          return (
            <InputButton
              value={"Select"}
              color="info"
              tabIndex={1}
              size="small"
              onClick={() => handleModalPossible(params.id)}
            />
          );
        } else {
          if (findRow?.length) {
            const nameRs = findRow?.map(val => val.routePointSts).join(" | ") || params.value;

            return (
              <Link
                href="#"
                underline="none"
                tabIndex={1}
                color={params.row.rowNumber === rowSelectionModel[0] ? "blue" : "black"}
                onClick={() => {
                  if (params.row.rowNumber === rowSelectionModel[0]) {
                    handleModalPossible(params.id);
                  }
                }}>
                {nameRs}
              </Link>
            );
          }
          return (
            <InputButton
              value={"Select"}
              color="info"
              tabIndex={1}
              size="small"
              onClick={() => handleModalPossible(params.id)}
            />
          );
        }
      },
      flex: 2,
    },
    {
      field: "trackSts",
      sortable: false,
      headerName: "IN/OUT Status",
      headerAlign: "center",
      minWidth: 120,
      editable: true,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["IN/OUT Status"],
        },
      ],
      renderEditCell: params => {
        return (
          <EditableDropDown
            {...params}
            autoFocus={false}
            menu={inOutStatusData?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            required
            placeholder="<Select>"
            tabIndex={1}
          />
        );
      },
      renderCell: params => {
        const name =
          params.value &&
          findObject({
            data: inOutStatusData,
            value: params.value,
            property: "cd",
            field: "value",
          });

        return <p>{name}</p>;
      },
      flex: 2,
    },
    {
      field: "enableFlag",
      sortable: false,
      headerName: "Enable Flag",
      headerAlign: "center",
      editable: true,
      align: "center",
      minWidth: 100,
      renderEditCell: params => {
        if (ModeAction.ADD === mode) {
          return <InputEditTableCheckbox {...params} checked={params.value === "Y"} color="default" tabIndex={1} />;
        } else {
          return <InputEditTableCheckbox {...params} color="default" checked={params.value === "Y"} tabIndex={1} />;
        }
      },
      renderCell: params => {
        return (
          <InputEditTableCheckbox {...params} tabIndex={1} checked={params.value === "Y"} color="secondary" disabled />
        );
      },
      flex: 1,
    },
  ];
}
