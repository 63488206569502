import React, { useEffect, useState } from "react";
import { Button, Typography, styled, IconButton, Box } from "@mui/material";
import { CloudUpload, Close, AttachFile } from "@mui/icons-material";
import { useConfirmDialogContext } from "context/confirmDialogContext";

const StyledInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  marginLeft: 1,
});
const StyledLabel = styled(Typography)({
  padding: "4px 8px",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  height: "1.3rem",
  color: "#000",
});
export default function InputUploadFile({
  id,
  fileType,
  field,
  onChange,
  disabled = false,
  value,
  required,
  className,
  file,
  style,
  onConfirmDelete = true,
  autoFocus = false,
  maxLength = 250,
  tabIndex = -1,
  uploadProcess = async () => {},
  ...props
}) {
  const confirmDialogCtx = useConfirmDialogContext();
  const [isHaveFile, setIsHaveFile] = useState(false);
  const [filename, setFilename] = useState("");

  const handleFileUpload = async event => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      const result = await uploadProcess(field, file, maxLength);
      if (result) {
        setFilename(file.name);
        setIsHaveFile(true);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteClick = async () => {
    try {
      if (onConfirmDelete) {
        const saveConfirm = await confirmDialogCtx.success({
          type: "confirmDelete",
        });
        if (!saveConfirm) {
          return;
        }
      }
      const result = await uploadProcess({ field, file: {} });
      if (result) {
        setFilename("");
        setIsHaveFile(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (filename && value) {
      setIsHaveFile(true);
    } else {
      setIsHaveFile(false);
      setFilename("");
    }
  }, [filename, isHaveFile, value]);

  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        ...style,
      }}>
      {!isHaveFile && (
        <Button
          {...props}
          data-testid="label-upload-file"
          id="label-upload-file"
          color="secondary"
          aria-label="label-upload-file"
          component="label"
          variant="contained"
          size="small"
          disabled={disabled}
          startIcon={<CloudUpload />}>
          Upload
          <StyledInput
            id={id}
            data-testid={id}
            tabIndex={tabIndex}
            type="file"
            accept={fileType}
            onChange={handleFileUpload}
            disabled={disabled}
            className={className}
            autoFocus={autoFocus}
          />
        </Button>
      )}
      {isHaveFile ? (
        <StyledLabel
          {...props}
          data-testid="label-file-name"
          id="label-file-name"
          aria-label="label-file-name"
          component="span"
          disabled={disabled}
          required={required}
          sx={{
            mt: "3px",
            borderRadius: "4px",
          }}>
          <AttachFile sx={{ mr: 0.5 }} fontSize="inherit" /> {filename}
        </StyledLabel>
      ) : (
        <StyledLabel
          {...props}
          data-testid="label-no-file"
          id="label-no-file"
          aria-label="label-no-file"
          component="span"
          disabled={disabled}
          required={required}
          sx={{
            mt: "2px",
            padding: "5.5px 12.5px",
            borderRadius: "1px 5px 5px 1px",
          }}>
          'No Choose file'
        </StyledLabel>
      )}
      {isHaveFile && (
        <IconButton
          {...props}
          data-testid={`button-delete-${id}`}
          id="button-delete-file"
          variant="text"
          color="secondary"
          size="small"
          disabled={disabled}
          onClick={handleDeleteClick}
          sx={{ borderRadius: 10, size: "10px" }}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
}
