import { COMMON_ENUM } from "shared/constants";
import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useGetBarcodeMode = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/${COMMON_ENUM.C_SM_CTG_TMAP_EM}/BARCODE_PRINT_MASTER_SCREEN_MODE/ASC`,
    method: "GET",
    select: data => data.result,
    enabled: true,
    keepPreviousData: true,
  });
};

export const useGetMaker = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/barcode-print/company-plant`,
    method: "GET",
    select: data => data.result,
    enabled: true,
    keepPreviousData: true,
  });
};

export const useGetPONumberType = ({ impExpCd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/barcode-print/delivery/${impExpCd}`,
    method: "GET",
    select: data => data.result,
    enabled: impExpCd !== "" && impExpCd !== undefined,
    keepPreviousData: true,
  });
};

export const useGetRPackageType = ({ rPkgOwnerCompAbbr }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/barcode-print/r-package/${rPkgOwnerCompAbbr}`,
    method: "GET",
    select: data => data.result,
    enabled: true,
    keepPreviousData: true,
  });
};

export const useGetPrintAs = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/barcode-print/print-as`,
    method: "GET",
    select: data => data.result,
    enabled: true,
    keepPreviousData: true,
  });
};
