import { MessageType } from "state/enum";

export const MessageList = [
  {
    code: "MSTD0029AERR",
    message: "[0] should be empty",
  },
  {
    code: "MSTD0030AERR",
    message: "[0] should be specified",
  },
  {
    key: MessageType.EMPTY,
    code: "MSTD0031AERR",
    message: "[0] should not be empty",
  },
  {
    code: "MSTD0032AERR",
    message: "Can not create folder [0]",
  },
  {
    code: "MSTD0033AERR",
    message: "Can not delete [0] file",
  },
  {
    code: "MSTD0034AERR",
    message: "Can not delete [0].[1]",
  },
  {
    code: "MSTD0035AERR",
    message: "Can not insert new data.",
  },
  {
    code: "MSTD0036AERR",
    message: "Can not update the data.",
  },
  {
    code: "MSTD0037AERR",
    message: "Cannot create [0] in [1]",
  },
  {
    code: "MSTD0038AERR",
    message: "Cannot open file [0] for reading",
  },
  {
    key: MessageType.DUPLICATE,
    code: "MSTD0039AERR",
    message: "Duplication found for [0]",
  },
  {
    code: "MSTD0040AERR",
    message: "Error when executing business validator: [0]",
  },
  {
    code: "MSTD0041AERR",
    message: "Error writing file = [0]. Reason = [1]",
  },
  {
    code: "MSTD0042AERR",
    message: "File already exist in [0]",
  },
  {
    key: MessageType.INVALID,
    code: "MSTD0043AERR",
    message: "Invalid [0]",
  },
  {
    code: "MSTD0044AERR",
    message: "Invalid [0]. The value must be [1]",
  },
  {
    key: MessageType.DATE_FORMAT,
    code: "MSTD0045AERR",
    message: "Invalid date format for [0]. The date format must be [1]",
  },
  {
    code: "MSTD0046AERR",
    message: "Invalid date value. [0] must conform to valid calendar date",
  },
  {
    code: "MSTD0047AERR",
    message: "Invalid datetime format for [0]. The datetime format must be [1]",
  },
  {
    code: "MSTD0048AERR",
    message: "Invalid days value. Range of [0] is between 01 and 31",
  },
  {
    code: "MSTD0049AERR",
    message: "Invalid email for [0]. The email must be like [1]",
  },
  {
    key: MessageType.MONTH_INVALID_45,
    code: "MSTD0045AERR",
    message: "Invalid date format for [0]. The date format must be [1]",
  },
  {
    key: MessageType.MONTH_INVALID_50,
    code: "MSTD0050AERR",
    message: "Invalid format for [0]. The format must be [1]",
  },
  {
    key: MessageType.FILE_NAME,
    code: "MSTD0051AERR",
    message: "Invalid length of [0]. The length can not be more than [1]",
  },
  {
    code: "MSTD0052AERR",
    message: "Invalid length of [0]. The length must be [1] digits",
  },
  {
    code: "MSTD0053AERR",
    message: "Invalid length of [0]. The length must be between [1] and [2] digits",
  },
  {
    code: "MSTD0054AERR",
    message: "Invalid months value. Range of [0] is between 01 and 12",
  },
  {
    code: "MSTD0055AERR",
    message: "Invalid numeric format for [0]",
  },
  {
    key: MessageType.NUMERIC_FORMAT,
    code: "MSTD0056AERR",
    message: "Invalid numeric format for [0]. The numeric format must be [1]",
  },
  {
    code: "MSTD0057AERR",
    message: "Invalid time format for [0]. The time format must be [1]",
  },
  {
    code: "MSTD0058AERR",
    message: "Length of filename [0] is invalid. It must be [1].",
  },
  {
    key: MessageType.NOT_FOUND,
    code: "MSTD0059AERR",
    message: "No data found",
  },
  {
    code: "MSTD0060AERR",
    message: "No data to be deleted",
  },
  {
    code: "MSTD0061AERR",
    message: "No data to be displayed",
  },
  {
    code: "MSTD0062AERR",
    message: "No data to be imported",
  },
  {
    code: "MSTD0063AERR",
    message: "No data to be saved",
  },
  {
    code: "MSTD0064AERR",
    message: "No data to be transferred",
  },
  {
    code: "MSTD0065AERR",
    message: "Size is too large for column [0] at [1]. Maximum size is [2]",
  },
  {
    code: "MSTD0066AERR",
    message: "Space is not allowed in [0]",
  },
  {
    code: "MSTD0067AERR",
    message: "Undefine Error : [0]",
  },
  {
    code: "MSTD0068AERR",
    message: "Undefined Error: [0]: [1]",
  },
  {
    code: "MSTD0069AERR",
    message: "You do not have permission to access this screen",
  },
  {
    code: "MSTD0070AERR",
    message: "You do not have permission to add new record in this screen",
  },
  {
    code: "MSTD0071AERR",
    message: "You do not have permission to delete record in this screen",
  },
  {
    code: "MSTD0072AERR",
    message: "You do not have permission to modify record in this screen",
  },
  {
    code: "MSTD0073AERR",
    message: "No Change to Save",
  },
  {
    code: "MSTD9002FERR",
    message: "[0] : The size of [1] is mismatch",
  },
  {
    code: "MSTD9003FERR",
    message: "[0] : Failed to send Control File = [1].CTF",
  },
  {
    code: "MSTD9004FERR",
    message: "[0] : Failed to receive Result File = [1]",
  },
  {
    code: "MSTD9005FERR",
    message: "[0] : Cannot find  Send File = [1]",
  },
  {
    code: "MSTD9006FERR",
    message: "[0] : Failed to analyze  Control File = [1].CTF",
  },
  {
    code: "MSTD9007FERR",
    message: "[0] : Failed to move Send File = [1] or Control File = [2].CTF",
  },
  {
    code: "MSTD9008FERR",
    message: "[0] : Failed to send Send File = [1] or Control File = [2].CTF",
  },
  {
    code: "MSTD7021BERR",
    message: "[0] Data not found from [1], [2], [3], [4]",
  },
  {
    code: "MSTD9009FINF",
    message: "[0] Sending is completed successfully",
  },
  {
    code: "MSTD9010FINF",
    message: "Unable to send [0]. Please check the Error Log",
  },
  {
    code: "MSTD9011FINF",
    message: "[0] Generation is completed successfully",
  },
  {
    code: "MSTD9012FINF",
    message: "Transferring data to Temporary table is started",
  },
  {
    code: "MSTD9013FINF",
    message: "Posting process is started",
  },
  {
    code: "MSTD0001ACFM",
    message: "Are you sure you want to delete the record ?",
  },
  {
    code: "MSTD0001AERR",
    message: "No data to be saved. Please specify the [0] completely",
  },
  {
    code: "MSTD0001AINF",
    message: "[0] is successfully added",
  },
  {
    code: "MSTD0001FCRI",
    message: "Undefine Error : [0]",
  },
  {
    code: "MSTD0001FERR",
    message: "Standard library failed : [0].",
  },
  {
    code: "MSTD0001FINF",
    message: "[0] is not found",
  },
  {
    code: "MSTD0002ACFM",
    message: "Are you sure you want to delete this attachment?",
  },
  {
    code: "MSTD0002AERR",
    message: "Invalid date format : [0].",
  },
  {
    code: "MSTD0002AINF",
    message: "[0] action is invalid for a blank [1]",
  },
  {
    code: "MSTD0002FCRI",
    message:
      "Database connection could not be established. Database connection can be invalid or server has been down. Please contact your system administrator.",
  },
  {
    code: "MSTD0002FERR",
    message: "Database transaction fail : [0]",
  },
  {
    code: "MSTD0002FINF",
    message: "Data is still used in other table",
  },
  {
    code: "MSTD0003ACFM",
    message: "Are you sure you want to abort the operation ?",
  },
  {
    code: "MSTD0000AINF",
    message: "[0]",
  },
  {
    code: "MSTD0003FINF",
    message: "The selected record is locked by another user",
  },
  {
    code: "MSTD0004ACFM",
    message: "Are you sure you want to confirm all records ?",
  },
  {
    code: "MSTD0004FINF",
    message: "The selected record has been deleted by another user. After clicking OK, you can see refreshed data",
  },
  {
    code: "MSTD0005ACFM",
    message: "Do you want to register the new record(s) ?",
  },
  {
    code: "MSTD0005FINF",
    message: "The selected record has been modified by another user. After clicking OK, you can see refreshed data",
  },
  {
    code: "MSTD0006ACFM",
    message: "Do you want to save the changes on this window ?",
  },
  {
    code: "MSTD0006FINF",
    message: "The selected record has been deleted by another user.",
  },
  {
    code: "MSTD0007ACFM",
    message: "Changes have been made, Do you wish to proceed ?",
  },
  {
    code: "MSTD0007FINF",
    message: "The selected record has been modified by another user.",
  },
  {
    code: "MSTD0008AERR",
    message: "[0] : The size of [1] is mismatch",
  },
  {
    code: "MSTD0009AERR",
    message: "[0] : Unspecified message ID",
  },
  {
    code: "MSTD0010AERR",
    message: "[0] = [1] already exist in [2]",
  },
  {
    code: "MSTD0011AERR",
    message: "[0] = [1] already exist in Database",
  },
  {
    code: "MSTD0012AERR",
    message: "[0] does not exist in [1]",
  },
  {
    code: "MSTD0013AERR",
    message: "[0] file does not exist",
  },
  {
    code: "MSTD0014AERR",
    message: "[0] import is cancelled",
  },
  {
    code: "MSTD0015AERR",
    message: "[0] must be alphanumeric",
  },
  {
    code: "MSTD0016AERR",
    message: "[0] must be between [1] and [2]",
  },
  {
    code: "MSTD0017AERR",
    message: "[0] must be earlier than or equal to [1]",
  },
  {
    code: "MSTD0018AERR",
    message: "[0] must be equal to or greater than [1]",
  },
  {
    key: MessageType.EQUAL_BETWEEN,
    code: "MSTD0019AERR",
    message: "[0] must be equal to or later than [1]",
  },
  {
    key: MessageType.MORETHAN_TODAY,
    code: "MSTD0020AERR",
    message: "[0] must be equal to or later than today",
  },
  {
    code: "MDN91001AERR",
    message: "Effective Date Period is overlapped with existing records",
  },
  {
    code: "MDN91002AERR",
    message: "Effective Date Period is not continuous with existing  records",
  },
  {
    key: MessageType.MORETHAN_ZERO,
    code: "MSTD0021AERR",
    message: "[0] must be greater than [1]",
  },
  {
    code: "MSTD0022AERR",
    message: "[0] must be greater than or equal to [1]",
  },
  {
    code: "MSTD0023AERR",
    message: "[0] must be later than [1]",
  },
  {
    code: "MSTD7014BERR",
    message: "File [0] is invalid [1] format",
  },
  {
    code: "MSTD7015BERR",
    message: "File [0] is invalid File Name's format, should be [1]",
  },
  {
    code: "MSTD7016BERR",
    message: "File [0] is invalid ASCII File, Header is missing",
  },
  {
    code: "MSTD7017BERR",
    message: "File [0] is invalid ASCII File, Trailer is missing",
  },
  {
    code: "MSTD7018BERR",
    message: "File [0] is invalid ASCII File, Trailer - Total Records do not match,",
  },
  {
    code: "MSTD7019BERR",
    message: "Missing parameter, Required = [0], Actual = [1]",
  },
  {
    code: "MSTD7020BERR",
    message: "[0] Error Exception, [1], [2], [3], [4]",
  },
  {
    code: "MSTD7022BERR",
    message: "[0] Duplicate key from [1], [2], [3], [4]",
  },
  {
    code: "MSTD7023BERR",
    message: "[0] Invalid data from [1], [2], [3], [4]",
  },
  {
    code: "MSTD7024BERR",
    message: "[0] Missing mandatory data from [1], [2], [3], [4]",
  },
  {
    code: "MSTD0074AERR",
    message: "Please input data",
  },
  {
    code: "MSTD0075AERR",
    message: "Mandatory Field Not Entered",
  },
  {
    code: "MSTD0076AINF",
    message: "[0] Calculation is completed successfully",
  },
  {
    code: "MSTD0077AINF",
    message: "[0] Calculation is finished with error",
  },
  {
    code: "MSTD0078AINF",
    message: "[0] Checking is completed successfully",
  },
  {
    code: "MSTD0079AINF",
    message: "[0] Checking is finished with error",
  },
  {
    code: "MSTD0080AINF",
    message: "[0] Deletion is completed successfully",
  },
  {
    code: "MSTD0081AINF",
    message: "[0] Generation is completed successfully",
  },
  {
    code: "MSTD0082AINF",
    message: "[0] Generation is finished with error",
  },
  {
    code: "MSTD0083AINF",
    message: "[0] is not found",
  },
  {
    code: "MSTD0084AINF",
    message: "[0] Merging is finished with error",
  },
  {
    code: "MSTD0085AINF",
    message: "[0] process is completed successfully",
  },
  {
    code: "MSTD0086AINF",
    message: "[0] process is finished with error. [1]",
  },
  {
    code: "MSTD0087AINF",
    message: "[0] Validation is finished with error",
  },
  {
    code: "MSTD0088AINF",
    message: "Cancellation for [0] is completed successfully",
  },
  {
    code: "MSTD0089AINF",
    message: "Cancellation for [0] is finished with error",
  },
  {
    code: "MSTD0090AINF",
    message: "Deletion process is completed successfully",
  },
  {
    code: "MSTD0091AINF",
    message: "Deletion process is finished with error",
  },
  {
    code: "MSTD0092AINF",
    message: "Import data [0] is completed successfully",
  },
  {
    code: "MSTD0093AINF",
    message: "Import data [0] is finished with error",
  },
  {
    code: "MSTD0094AINF",
    message: "Importing process is started",
  },
  {
    code: "MSTD0095AINF",
    message: "Merging data is completed successfully",
  },
  {
    code: "MSTD0096AINF",
    message: "Merging data is finished wih error",
  },
  {
    code: "MSTD0097AINF",
    message: "Posting data is completed successfully",
  },
  {
    code: "MSTD0098AINF",
    message: "Posting data is finished with error",
  },
  {
    code: "MSTD0099AINF",
    message: "Posting process is started",
  },
  {
    code: "MSTD0100AINF",
    message: "Saving [0] is finished with error",
  },
  {
    code: "MSTD0101AINF",
    message: "Saving data is completed successfully",
  },
  {
    code: "MSTD0102AINF",
    message: "Saving data is finished with error",
  },
  {
    code: "MSTD0103AINF",
    message: "Select one [0] to be processed",
  },
  {
    code: "MSTD0104AINF",
    message: "Select one [0] to be retrieved",
  },
  {
    code: "MSTD0105AINF",
    message: "Select one record to be deleted",
  },
  {
    code: "MSTD0106AINF",
    message: "Select one record to be modified",
  },
  {
    code: "MSTD0107AINF",
    message: "Select report type to be printed",
  },
  {
    code: "MSTD0108AINF",
    message: "Start process [0]",
  },
  {
    code: "MSTD0109AINF",
    message: "Un-Merging data is completed successfully",
  },
  {
    code: "MSTD0110AINF",
    message: "Un-Merging data is finished with error",
  },
  {
    code: "MSTD0111AINF",
    message: "Validation process is completed successfully",
  },
  {
    code: "MSTD0112AINF",
    message: "Validation process is finished with error",
  },
  {
    code: "MSTD0113AINF",
    message: "Validation process is started",
  },
  {
    code: "MSTD0601BERR",
    message: "[0] should not be empty.",
  },
  {
    code: "MSTD0602BERR",
    message: "Error while writing file",
  },
  {
    code: "MSTD0603BERR",
    message: "Request is unacceptable",
  },
  {
    code: "MSTD0604BERR",
    message: "Error Parsing Date",
  },
  {
    code: "MSTD0605BERR",
    message: "Unacceptable time of request in request control table.",
  },
  {
    code: "MSTD0606BERR",
    message: "Specified time is not acceptable time for the request in request control table.",
  },
  {
    code: "MSTD0607BERR",
    message: "Can not get data of request [0] from request definition table.",
  },
  {
    code: "MSTD0608BERR",
    message: "Request [0] is unacceptable in request definition table.",
  },
  {
    code: "MSTD0613BERR",
    message: "Input Data is not acceptable for Request [0].",
  },
  {
    code: "MSTD0609BERR",
    message: "Specified time is out of acceptable time in request definition table.",
  },
  {
    code: "MSTD0610BERR",
    message: "Request ID [0] is the time which cannot receive a request definition information table.",
  },
  {
    code: "MSTD0611BERR",
    message: "The appointed time is not the registration time of a request reception table.",
  },
  {
    code: "MSTD0612BERR",
    message: "Out of acceptable time for request [0] in request definition table.",
  },
  {
    code: "MSTD0614BERR",
    message: "Input Data of request [0] must within [1] bytes",
  },
  {
    code: "MSTD0615BERR",
    message: "Since the request receivable number [1] of Request ID [0] is exceeded, it is acceptable.",
  },
  {
    code: "MSTD0616BERR",
    message: "Can not get data from request number master table.",
  },
  {
    code: "MSTD0617BERR",
    message: "The renewal of data of a requester control table went wrong.",
  },
  {
    code: "MSTD0618BERR",
    message: "The renewal of data of a request number master table went wrong.",
  },
  {
    code: "MSTD0619BERR",
    message: "Not [1] job data in Job Registration table for request [0].",
  },
  {
    code: "MSTD0620BERR",
    message: "Failed to set job.",
  },
  {
    code: "MSTD0621BERR",
    message: "The set of a request went wrong.",
  },
  {
    code: "MSTD0622BERR",
    message: "Batch In Progress Company Code/Location Code.",
  },
  {
    code: "MSTD0623BERR",
    message: "Batch Waiting to start for Company Code/Location Code.",
  },
  {
    code: "MSTD7000BINF",
    message: "[0] Begin",
  },
  {
    code: "MSTD7001BINF",
    message: "[0] End successfully  [1], [2], [3]",
  },
  {
    code: "MSTD7002BINF",
    message: "[0] End with error [1], [2], [3]",
  },
  {
    code: "MSTD7003BINF",
    message: "[0] End with warning [1], [2], [3]",
  },
  {
    code: "MSTD7004BERR",
    message: "Cannot find folder : [0] in [1]",
  },
  {
    code: "MSTD7005BERR",
    message: "Duplicate file : [0] in [1]",
  },
  {
    code: "MSTD7006BERR",
    message: "Cannot find file : [0] in [1]",
  },
  {
    code: "MSTD7007BERR",
    message: "Cannot replace file : [0] in [1]",
  },
  {
    code: "MSTD7008BERR",
    message: "Cannot create file : [0]  in [1]",
  },
  {
    code: "MSTD7025BINF",
    message: "Data not found from Criteria [1], [2], [3], [4]",
  },
  {
    code: "MSTD7026BINF",
    message: "Data not found for processing from [0]",
  },
  {
    code: "MSTD7027BERR",
    message: "User ID. [0] is not authorized",
  },
  {
    code: "MSTD7028BERR",
    message: "Invalid email address : [0]",
  },
  {
    code: "MSTD7029BERR",
    message: "Problem in sending email : [0]",
  },
  {
    code: "MSTD7030BCRI",
    message: "<C> {error message}",
  },
  {
    code: "MSTD7031BINF",
    message: "<I> Loading controller XML: {Controller XML file name}",
  },
  {
    code: "MSTD7032BINF",
    message: "<I> Retrieving information from data source: {data source type}: { execution statement}",
  },
  {
    code: "MSTD7033BINF",
    message: "<I> Generate ASCII file content",
  },
  {
    code: "MSTD7034BINF",
    message: "<I> Writing ASCII File content to file: {ASCII file name}",
  },
  {
    code: "MSTD7035BINF",
    message: "<I> ASCII file not created, controller XML does not allow empty data",
  },
  {
    code: "MSTD7036BINF",
    message: "<I> Commiting any database updates",
  },
  {
    code: "MSTD7037BWRN",
    message: "<W> Type of {field name} field is number but no format set for it",
  },
  {
    code: "MSTD8000SERR",
    message: "Undefined Error: [0]: [1]",
  },
  {
    code: "MSTD8001SERR",
    message: "Failed to connect to [0]  Database",
  },
  {
    code: "MSTD8002SERR",
    message: "Undefine Error : [0]",
  },
  {
    code: "MSTD8003SERR",
    message: "Can not create folder [0]",
  },
  {
    code: "MSTD8004SERR",
    message: "Can not connect into database.\\nAll windows will be closed",
  },
  {
    code: "MSTD9000FERR",
    message: "Cannot open sending result file = [0] for reading",
  },
  {
    code: "MSTD9001FERR",
    message: "[0] : Failed to send [1] to Server = [2]",
  },
  {
    code: "MSTD0024AERR",
    message: "[0] must be less than [1]",
  },
  {
    key: MessageType.LESS_OR_EQUAL,
    code: "MSTD0025AERR",
    message: "[0] must be less than or equal to [1]",
  },
  {
    code: "MSTD0026AERR",
    message: "[0] must be one of the following [1]",
  },
  {
    code: "MSTD0027AERR",
    message: "[0] must be same with [1]",
  },
  {
    code: "MSTD0028AERR",
    message: "[0] must not be same with [1]",
  },
  {
    code: "MSTD1049AERR",
    message: "File [0] cannot be uploaded because [1]",
  },
  {
    code: "MSTD7009BERR",
    message: "Cannot move file : [0] from [1] to  [2]",
  },
  {
    code: "MSTD7010BERR",
    message: "Cannot delete file : [0]  in [1]",
  },
  {
    code: "MSTD7011BERR",
    message: "Cannot open file : [0] in [1]",
  },
  {
    code: "MSTD7012BERR",
    message: "Cannot read file : [0] in [1]",
  },
  {
    key: MessageType.TYPE_IMAGE,
    code: "MSTD7013BERR",
    message: "File [0] is not expected file type [[1], [2], [3]]",
  },
  {
    key: MessageType.TYPE_EXCEL,
    code: "MSTD7013BERR",
    message: "File [0] is not expected file type [[1], [2], [3]]",
  },
  {
    key: MessageType.TYPE_PDF,
    code: "MSTD7013BERR",
    message: "File [0] is not expected file type [[1], [2], [3]]",
  },
  {
    code: "MSTD7030BERR",
    message: "[0] is mandatory field and could not be empty",
  },
  {
    code: "MSTD0114AINF",
    message: "Total Record : [0]. Successed : [1]. Failed : [2].  ",
  },
  {
    code: "MSTD0120AERR",
    message: "[0] must be equal with ",
  },
  {
    code: "MSTD7031BERR",
    message: "[0] must be greater than zero",
  },
  {
    code: "MSTD7032BERR",
    message: "[0] record of [1] is being locked by other user",
  },
  {
    code: "MSTD7033BERR",
    message: "[0] can not be zero",
  },
  {
    code: "MSTD7034BERR",
    message: "Data not found for processing from [0]",
  },
  {
    key: MessageType.UPDATED,
    code: "MDN90016AINF",
    message: "Data is updated successfully.",
  },
  {
    key: MessageType.DELETED,
    code: "MDN90017AINF",
    message: "Data is deleted successfully.",
  },
  {
    code: "MDN90023AINF",
    message: "Data is saved successfully.",
  },
  {
    code: "MDN92016AINF",
    message: "[0] process is started.",
  },
  {
    code: "MDN90034AINF",
    message: "Posting on demand batch is completed successfully.",
  },
  {
    code: "MDN92013AINF",
    message: "[0] of [1] Finish successfully.",
  },
  {
    code: "MDN92024AINF",
    message: "No data found from [0], [1]",
  },
  {
    code: "MDN94001AINF",
    message: "[0] found for [1], R-Package Type [2],  [3]",
  },
  {
    code: "MDN94003AINF",
    message: "[0] is generated successfully.",
  },
  {
    code: "MDN90027AINF",
    message: "Error while sending e-mail to [0], Subject :[1].",
  },
  {
    code: "MDN90026AINF",
    message: "Sending e-mail to [0], Subject:[1] is successfully.",
  },
  {
    code: "MDN95028AINF",
    message: "Cancel Vanning is completed successfully.",
  },
  {
    code: "MDN95019AINF",
    message: "Upload B/L is completed successfully.",
  },
  {
    code: "MDN95020AINF",
    message: "Send e-mail is completed successfully.",
  },
  {
    code: "MDN95014AINF",
    message: "Invoice data is cancelled successfully.",
  },
  {
    code: "MDN97003AINF",
    message: "Data not found from Criteria [0], [1].",
  },
  {
    code: "MDN97020AINF",
    message: "Please Scan barcode or input data for Login.",
  },
  {
    code: "MDN97021AINF",
    message: "Please Scan barcode or input data to select operation mode.",
  },
  {
    code: "MDN97022AINF",
    message: "Please Key-in Container No. or Start Scan Receive mode.",
  },
  {
    code: "MDN97023AINF",
    message: "Please Key-in Container No. or Start Scan Deliver mode.",
  },
  {
    code: "MDN97024AINF",
    message: "Please Scan for Change R-Package Status or Change to other operation mode.",
  },
  {
    code: "MDN97027AINF",
    message: "Starting RPC call for function :[0]",
  },
  {
    code: "MDN97028AINF",
    message: "End RPC Call for function :[0]",
  },
  {
    code: "MDN97029AINF",
    message: "Parameters to use :[ [0] ]",
  },
  {
    code: "MDN95040AINF",
    message: "Delete B/L is completed successfully.",
  },
  {
    code: "MDN90042AINF",
    message: "Data is temporarily added.",
  },
  {
    code: "MDN90043AINF",
    message: "Data is temporarily edited.",
  },
  {
    code: "MDN90044AINF",
    message: "Data is temporarily deleted.",
  },
  {
    code: "MDN90045AINF",
    message: "[0] Interface File does not exists under Input path,[1]",
  },
  {
    code: "MDN90046AINF",
    message: "File [0] cannot be uploaded because It does not contain data to upload.",
  },
  {
    code: "MDN90047AINF",
    message: "[0] = [1] already exist in [2]",
  },
  {
    code: "MDN90001AINF",
    message: "Cannot find Interface File for process from Plant Server.",
  },
  {
    code: "MDN93010AINF",
    message: "[0] found for [1]",
  },
  {
    code: "MDN95043AINF",
    message: "[0] is not in between [1] and [2].",
  },
  {
    code: "MDN95044AINF",
    message: "Data not found Shipment,Plan Status and ETD greater than 2 date in Shipment Summary tables.",
  },
  {
    code: "MDN90001ACFM",
    message: "[0] is not in the Container List, do you confirm to proceed?",
  },
  {
    code: "MDN90002ACFM",
    message: "[0] is not in the Delivery Instruction Sheet, do you confirm to proceed?",
  },
  {
    key: MessageType.CONFIRM_SAVE,
    code: "MDN90014ACFM",
    message: "Do you want to confirm saving the record?",
  },
  {
    key: MessageType.CONFIRM_DELETE,
    code: "MDN90018ACFM",
    message: "Do you want to confirm deletion the record?",
  },
  {
    key: MessageType.CONFIRM_CANCEL,
    code: "MDN90019ACFM",
    message: "Do you want to abort the operation?",
  },
  {
    code: "MDN90020ACFM",
    message:
      "Receiving data has been used in Forecast Generation. Do you confirm to delete all revisions in this Forecasting period?",
  },
  {
    code: "MDN90021ACFM",
    message: "Do you confirm to delete all revisions in this Forecasting period?",
  },
  {
    key: MessageType.CLOSE_UPDATE_DATA,
    code: "MDN90031ACFM",
    message: "Updated data will be lost. Do you want to abort the operation?",
  },
  {
    code: "MDN91005ACFM",
    message: "Do you want to confirm printing the barcode?",
  },
  {
    code: "MDN92015ACFM",
    message: "Do you want to Activate Forecasting?",
  },
  {
    code: "MDN92016ACFM",
    message: "Do you want to delete [0] file?",
  },
  {
    code: "MDN93007ACFM",
    message: "Do you want to [0] System?",
  },
  {
    code: "MDN94002ACFM",
    message: "Do you want to upload [0] Repositioning Simulation report?",
  },
  {
    code: "MDN95007ACFM",
    message: "Do you want to confirm to generate the report?",
  },
  {
    code: "MDN95016ACFM",
    message: "The Invoice No = [0] already have B/L file. Do you want to confirm to replace B/L file?",
  },
  {
    code: "MDN95032ACFM",
    message: "Do you want to confirm to Cancel Invoice?",
  },
  {
    code: "MDN97006ACFM",
    message: "Do you want to change scanning mode?",
  },
  {
    code: "MDN97025ACFM",
    message:
      "Unable to commit this transaction on server.\nYou may retry this operation by clicking 'OK'.\nHowever if the error persists, contact Technical Support.",
  },
  {
    code: "MDN90022ACFM",
    message: "Are you sure you want to [0] the record? ",
  },
  {
    key: MessageType.SIZE_IMAGE,
    code: "MDN90001AERR",
    message: "File size for [0] can not exceed [1].",
  },
  {
    code: "MDN90002AERR",
    message: "A record  must be selected to execute Print operation. ",
  },
  {
    code: "MDN90005AERR",
    message: "All indicated dates of [0] are back dates.",
  },
  {
    code: "MDN90006AERR",
    message: "[0] must be positive number.",
  },
  {
    code: "MDN90008AERR",
    message: "Number of [0] on [1] must not be greater than number of [2].",
  },
  {
    code: "MDN90009AERR",
    message: "Invalid value of [0]. Range of [0] is between [1] and [2].",
  },
  {
    key: MessageType.MUST_BE_SELECTED,
    code: "MDN90010AERR",
    message: "[0] must be selected.",
  },
  {
    code: "MDN91010AERR",
    message: "Upload data should be in future",
  },
  {
    code: "MDN90011AERR",
    message: "Error in Generating barcode because of concurrency check.",
  },
  {
    code: "MDN90012AERR",
    message: "A single record must be selected to execute Copy operation.",
  },
  {
    code: "MDN90022AERR",
    message: "[0] already existed in [1]",
  },
  {
    code: "MDN90023AERR",
    message: "A record must be selected to execute Copy operation.",
  },
  {
    code: "MDN90024AERR",
    message: "A record must be selected to execute Delete operation.",
  },
  {
    code: "MDN90025AERR",
    message: "A record must be selected to execute Edit operation.",
  },
  {
    code: "MDN90028AERR",
    message: "[0] Period can not exceed [1]",
  },
  {
    code: "MDN90029AERR",
    message: "Data can not be deleted because effective date is in the past.",
  },
  {
    code: "MDN90030AERR",
    message: "Data can not be deleted because of continuous status. ",
  },
  {
    code: "MDN90032AERR",
    message: "Effective Month Period is overlapped with existing records.",
  },
  {
    code: "MDN90033AERR",
    message: "Effective Month Period is not continuous with existing records.",
  },
  {
    code: "MDN90035AERR",
    message: "File Id [0] is not registered in [1] properly. Please check field [2].",
  },
  {
    code: "MDN90036AERR",
    message: "Cannot [0], because [1] have been cancelled",
  },
  {
    code: "MDN90037AERR",
    message: "Select at least one record to be processed.",
  },
  {
    code: "MDN90038AERR",
    message: "A single record must be selected to execute the operation",
  },
  {
    key: MessageType.CLOSE_SCREEN,
    code: "MDN90039ACFM",
    message: "Do you want to close the screen?",
  },
  {
    code: "MDN90040AERR",
    message: "The number of columns [0] must be [1]",
  },
  {
    code: "MDN90041AERR",
    message: "Cannot delete [0], because refered by other master.",
  },
  {
    code: "MDN90042AERR",
    message: "Cannot [0] [1], because refered by other master.",
  },
  {
    code: "MDN90043AERR",
    message: "Indicated dates of [0] are back dates.",
  },
  {
    code: "MDN90044AERR",
    message: "Interface Data Upload & Validation has Error",
  },
  {
    code: "MDN90045AERR",
    message: "A single record must be selected to execute [0] operation.",
  },
  {
    code: "MDN90046AERR",
    message: "Error in [0] because of concurrency check.",
  },
  {
    code: "MDN90047AERR",
    message: "A record must be selected to execute [0] operation.",
  },
  {
    code: "MDN90048AERR",
    message: "File [0] is over [1].",
  },
  {
    code: "MDN91001AERR",
    message: "Effective Date Period is overlapped with existing records.",
  },
  {
    code: "MDN91002AERR",
    message: "Effective Date Period is not continuous with existing records.",
  },
  {
    code: "MDN91004AERR",
    message: "[0] must have [1].",
  },
  {
    code: "MDN91007AERR",
    message: "[0] must be [1]",
  },
  {
    code: "MDN91009AERR",
    message: "[0] cannot be same value with [1]",
  },
  {
    code: "MDN91011AERR",
    message: "Path not continuous from [0] to [1].",
  },
  {
    code: "MDN91012AERR",
    message: "Error in Print because of concurrency check.",
  },
  {
    code: "MDN91013AERR",
    message: "Select at least one record to execute Save operation.",
  },
  {
    code: "MDN92002AERR",
    message: "Container Group of [0], [1] cannot be obtained.",
  },
  {
    code: "MDN92008AERR",
    message: "Time overlap found for [0]",
  },
  {
    code: "MDN92009AERR",
    message: "Forecast data of [0] [1] already generated.",
  },
  {
    code: "MDN92010AERR",
    message: "[0] must be continuous.",
  },
  {
    code: "MDN92011AERR",
    message: "Cannot find Supplier Code and Plant Code for [0]",
  },
  {
    code: "MDN92012AERR",
    message: "Data of [0] cannot be obtained.",
  },
  {
    code: "MDN92014AERR",
    message: "[0] of [1] Finish with error.",
  },
  {
    code: "MDN92015AERR",
    message: "Some calendar data can not be found for using calculate ETD data. Start [0] and End [1].",
  },
  {
    code: "MDN92017AERR",
    message: "[0] for the [1] [2] not exist.",
  },
  {
    code: "MDN92018AERR",
    message: "[0], [1] already generated.",
  },
  {
    code: "MDN92019AERR",
    message: "[0] must be equal to [1], [2]",
  },
  {
    code: "MDN92020AERR",
    message: "Year, Month data must cover [0] months.",
  },
  {
    code: "MDN92021AERR",
    message:
      "Forecast Period = [1], Mode = [2], Exporter = [3], Importer = [4]  Data already exist in Packing Plan table.",
  },
  {
    code: "MDN92022AERR",
    message: "[0] must be the same for all records.",
  },
  {
    code: "MDN92023AERR",
    message: "No [0] data to process from [1], [2]",
  },
  {
    code: "MDN92024AERR",
    message: "Please finish Shipment No. [0] with upload actual data and NCV before process new shipment.",
  },
  {
    code: "MDN92025AERR",
    message: "[0] = [1] cannot map to Supplier WH.",
  },
  {
    code: "MDN93002AERR",
    message: "[0] has already been processed by [1]",
  },
  {
    code: "MDN94004AERR",
    message: "Cannot download Repositioning Simulation Report more than 3 Warehouses per time.",
  },
  {
    code: "MDN94005AERR",
    message: "Number of [0] on [1] of [2], is not equal to number of [3]. ",
  },
  {
    code: "MDN94006AERR",
    message: "[0] put on [1] is back date.",
  },
  {
    code: "MDN94007AERR",
    message:
      "Daily Inventory Management Data is already changed. Please re-download Repositioning Simulation Report and check it again.",
  },
  {
    code: "MDN94008AERR",
    message:
      "Related Repositioning Data of [0] is already changed. Please re-download Repositioning Simulation Report and check it again.",
  },
  {
    code: "MDN95004AERR",
    message: "A record must be selected to execute Download Invoice operation.",
  },
  {
    code: "MDN95005AERR",
    message: "A record must be selected to execute Generate Report operation.",
  },
  {
    code: "MDN95006AERR",
    message: "A single record must be selected to execute Generate Report operation.",
  },
  {
    code: "MDN95007AERR",
    message: "A single record must be selected to execute Add operation.",
  },
  {
    code: "MDN95018AERR",
    message: "Error in uploaded B/L, because of concurrency check.",
  },
  {
    code: "MDN95025AERR",
    message: "A record must be selected to execute Cancel Vanning Result operation.",
  },
  {
    code: "MDN95020AERR",
    message: "A selected record to execute Delete B/L Invoice doesn't has B/L information.",
  },
  {
    code: "MDN95033AERR",
    message: "Selected records should have the same Pack Status.",
  },
  {
    code: "MDN95034AERR",
    message: "A single record must be selected to execute Cancel Vanning Result operation.",
  },
  {
    code: "MDN95015AERR",
    message: "A record must be selected to execute Upload B/L Invoice.",
  },
  {
    code: "MDN95008AERR",
    message: "A record must be selected to execute Cancel Invoice operation.",
  },
  {
    code: "MDN95009AERR",
    message: "A single record must be selected to execute Cancel Invoice operation.",
  },
  {
    code: "MDN95035AERR",
    message: "Selected records should have the same ETD Date.",
  },
  {
    code: "MDN95036AERR",
    message: "Error in Cancel Invoice because of concurrency check.",
  },
  {
    code: "MDN95027AERR",
    message: "Error in Cancel Vanning because of concurrency check.",
  },
  {
    code: "MDN95028AERR",
    message: "Found some invoice and container already mapped to other shipment :[0]",
  },
  {
    code: "MDN97001AERR",
    message: "No target Plant to generate interface file.",
  },
  {
    code: "MDN97011AERR",
    message: "[0] Data already exist in [1]",
  },
  {
    code: "MDN97004AERR",
    message: "A record must be selected to execute Download Report operation.",
  },
  {
    code: "MDN97005AERR",
    message: "Scanning should be Finished / Reset.",
  },
  {
    code: "MDN97006AERR",
    message: "[0] Cannot find match parent module barcode",
  },
  {
    code: "MDN90007AERR",
    message: "Can not delete selected data.",
  },
  {
    code: "MDN95037AERR",
    message: "Unable to delete because the selected record has a Completed Vanning Status.",
  },
  {
    code: "MDN93008AERR",
    message:
      "Record of Actual Vanning data without Shipping Plan must be selected to execute Copy Actual Data operation.",
  },
  {
    code: "MDN93009AERR",
    message: "[0] already send to plant server.",
  },
  {
    code: "MDN93010AERR",
    message: "Found Shipment code not exists in system :[0]",
  },
  {
    code: "MDN95026AERR",
    message: "Cannot Cancel Vanning because of data already created Non-Commercial Invoice No.",
  },
  {
    code: "MDN91003AERR",
    message: "Same container group can not have similar R-Package Type.",
  },
  {
    code: "MDN97002AERR",
    message: "No data to process.",
  },
  {
    code: "MDN95038AERR",
    message: "A record with Upload B/L must be selected to execute Delete B/L Invoice.",
  },
  {
    code: "MDN95039AERR",
    message: "Error in deleted B/L, because of concurrency check.",
  },
  {
    code: "MDN95041AERR",
    message: "Records with the same Upload B/L must be selected to execute Delete B/L Invoice.",
  },
  {
    code: "MDN92007AERR",
    message: "[0] must be same.",
  },
  {
    code: "MDN95040AERR",
    message: "Cannot [0] the selected record because the ETD Date is less than the current date.",
  },
  {
    code: "MDN96001AERR",
    message: "The number of R-Package Type ([0]) cannot more than maximum of template ([1]).",
  },
  {
    code: "MDN96002AERR",
    message: "Data not found for processing from [0]",
  },
  {
    code: "MDN96003AERR",
    message: "A record must be selected to execute Add operation.",
  },
  {
    code: "MDN93011AERR",
    message: "[0] [1], [2] have to be inputted or not inputted concurrently.",
  },
  {
    code: "MDN93012AERR",
    message: "Found multiple score of [0] in Container Group table (TB_M_CONTAINER_GROUP_PT).",
  },
  {
    code: "MDN93013AERR",
    message: "System not allow Barcode Id = [0] contain more than 1 Other Material Type.",
  },
  {
    code: "MDN90000AERR",
    message: "[0].",
  },
  {
    code: "MDN97026ACRI",
    message:
      "An error occurred:\n [0]\nYou may retry this operation by clicking 'OK'.\nHowever if the error persists, contact Technical Support.",
  },
  {
    code: "MDN97027ACRI",
    message: "Sorry, this handheld device is not authorized. Contact Technical Support.",
  },
  {
    code: "MDN93006AWRN",
    message: "System Status is Unfreeze.",
  },
  {
    code: "MDN93007AWRN",
    message: "Uploaded ATA = [0] data less than system date",
  },
  {
    code: "MDN90048AWRN",
    message: "Processing [0] [1]",
  },
  {
    code: "MDN97049AWRN",
    message:
      "Barcode = [0] will be treated as Dummy Barcode because of not exist in R-Module Master (TB_M_R_MODULE), Mode=[1], Location From=[2], Location To=[3], RECORD_ID=[4].",
  },
  {
    code: "MDN97090AWRN",
    message: "Scan transaction for [0] not found in delivery instruction sheet.",
  },
  {
    code: "MDN91001AERR",
    message: "Effective Date Period is overlapped with existing records.",
  },
  {
    code: "MDN90029AERR",
    message: "Data can not be deleted because effective date is in the past.",
  },
  {
    code: "MDN90017AINF",
    message: "Data is [0] successfully",
  },
  {
    key: MessageType.ADDED,
    code: "MDN90015AINF",
    message: "Data is added successfully.",
  },
  {
    key: MessageType.DELETE_EFFECTIVE_DATE_TO_PASS,
    code: "MSTD1005AERR",
    message: "Cannot [0] ,because Effective Date To = [1] already passed",
  },
  {
    key: MessageType.DELETE_EFFECTIVE_DATE_FROM_PASS,
    code: "MSTD1005AERR",
    message: "Cannot [0] ,because Effective Date FROM = [1] already passed",
  },
  {
    code: "MDN91003AERR",
    message: "Same container group can not have similar R-Package Type.",
  },
  {
    code: "MDN90015AINF",
    message: "Data is [0] successfully.",
  },
  {
    code: "MDN91008INF",
    message: "Barcode report is generated. Please save the file.",
  },
  {
    code: "MDN91009ERR",
    message: "[0] can not be same value with [1].",
  },
];
