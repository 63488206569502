import React, { Fragment } from "react";
//UTIL
import { responseErrors } from "utils";
// COMPONENT
import { Grid } from "@mui/material";
import { DataGridTable } from "components/UI";
//TYPE
import { API_MONTHLY } from "shared/constants/api-name/forecast/monthly";
import { PAGINATION } from "shared/constants/table.constant";
//SERVICE
import { useSearchApmcMutation } from "shared/services/inventory-forecast";
import { useLoadingContext } from "shared/contexts/LoadingContext";
export default function BodySectionApmc(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    onSearch,
    searchForm,
    table: { rowSelectionModel, setRowSelectionModel, setRowModesModel, rowModesModel },
    pagination: { pageNumber, pagination, setPageNumber, setPagination },
    setMsg: { setMsgError },
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  const searchDataApmc = useSearchApmcMutation();
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        [API_MONTHLY.FORECAST_TYPE_CD]: searchForm?.forecastTypeCd,
        [API_MONTHLY.FORECAST_MONTH]: searchForm?.forecastMonth,
        [PAGINATION.PAGE_NUMBER]: value,
      };
      startLoading();
      // ? REQUEST DATA
      const searchApmc = await searchDataApmc(body);
      stopLoading();
      // ? TRANSFORM DATA
      const data =
        searchApmc?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_MONTHLY.ROW_NUMBER]: item.rowNumber,
          [API_MONTHLY.FORECAST_TYPE]: item.forecastType,
          [API_MONTHLY.FORECAST_MONTH]: item.forecastMonth,
          [API_MONTHLY.DATA_TYPE]: item.dataType,
          [API_MONTHLY.DATA_TYPE_CD]: item.dataTypeCd,
          [API_MONTHLY.ORG_FILE_NAME]: item.orgFileName,
          [API_MONTHLY.UPLOAD_STATUS]: item.uploadStatus,
          [API_MONTHLY.UPLOAD_BY]: item.uploadBy,
          [API_MONTHLY.UPLOAD_DATE]: item.uploadDt,
          [API_MONTHLY.USE_STATUS]: item.useSts,
          [API_MONTHLY.USE_DATE]: item.useDt,
          [API_MONTHLY.USE_BY]: item.useBy,
          [API_MONTHLY.FILE_UPLOAD_ID]: item.fileUploadId,
          [API_MONTHLY.UPDATE_DATE]: item.updateDt,
          [API_MONTHLY.UPDATE_BY]: item.updateBy,
          [API_MONTHLY.FILE_UPLOAD_ID]: item.fileUploadId,
        })) ?? [];

      setPagination(searchApmc?.result?.pagination ?? {});
      setPageNumber(searchApmc?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  return (
    <Fragment>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <DataGridTable
          mode={mode}
          setMode={setMode}
          onSearch={onSearch}
          rows={rows}
          checkboxSelection={false}
          isMultipleSelection={false}
          rowHeight={45}
          processRowUpdate={null}
          rowSelection={{
            rowModesModel: rowModesModel,
            setRowModesModel: setRowModesModel,
            rowSelectionModel: rowSelectionModel,
            setRowSelectionModel: setRowSelectionModel,
          }}
          pagination={{
            pagination: pagination,
            pageNumber: pageNumber,
            handleChangePagination: handleChangePagination,
          }}
          column={{
            columns: columns,
            columnGroupingModel: null,
            columnVisibilityModel: {
              [API_MONTHLY.FILE_UPLOAD_ID]: false,
              [API_MONTHLY.MODULE_ID]: false,
              [API_MONTHLY.FUNCTION_ID]: false,
              [API_MONTHLY.D_HODTCRE]: false,
              [API_MONTHLY.API_ID]: false,
            },
          }}
        />
      </Grid>
    </Fragment>
  );
}
