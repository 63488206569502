import React from "react";
import { Box, Grid } from "@mui/material";
import { DEFAULT_COLOR_GRAPH } from "../constants/constant";
export default function LabelGraph() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item>
          <div
            style={{
              float: "left",
              width: "30px",
              borderBottom: `2px solid ${DEFAULT_COLOR_GRAPH.RED}`,
              height: "5px",
              marginTop: "2px",
            }}></div>
          &nbsp;
          <div style={{ width: "40px", float: "right" }}>Max</div>
        </Grid>
        <Grid item>
          <div
            style={{
              float: "left",
              width: "30px",
              borderBottom: `2px solid ${DEFAULT_COLOR_GRAPH.BLACK}`,
              height: "5px",
              marginTop: "2px",
            }}></div>
          &nbsp;
          <div style={{ width: "300px", float: "right" }}>Ending Balance + (Confirmed + Allocated Status)</div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <div
            style={{
              float: "left",
              width: "30px",
              borderBottom: `2px solid ${DEFAULT_COLOR_GRAPH.GREEN}`,
              height: "5px",
              marginTop: "2px",
            }}></div>
          &nbsp;
          <div style={{ width: "40px", float: "right" }}>Min</div>
        </Grid>
        <Grid item>
          <div
            style={{
              float: "left",
              width: "30px",
              borderBottom: `2px solid ${DEFAULT_COLOR_GRAPH.BLUE}`,
              height: "5px",
              marginTop: "2px",
            }}></div>
          &nbsp;
          <div style={{ width: "250px", float: "right" }}>Updated Ending</div>
        </Grid>
      </Grid>
    </Box>
  );
}
