import React from "react";
import { NumericFormat } from "react-number-format";
import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInput-root": {
    border: `1.5px solid ${
      error ? theme.palette.primary.main : theme.palette.secondary.medium
    }`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: ".3rem",
    
  },
  "& .MuiInput-root.Mui-focused": {
    border: `1.5px solid ${theme.palette.tertiary.main} !important`,
    borderRadius: ".3rem",
  },
  "& .MuiInputBase-input": {
    textAlign: "right",
    padding: "4px 8px !important",
  },
}));

const InputCurrency = ({
  value,
  disabled = false,
  className,
  required,
  params,
  style,
  decimalScale = 0,
  maxLimit = 1000000000,
  onChange,
  tabIndex = -1,
  ...props
}) => {
  return (
    <div style={{ width: "100%", display: "grid" }}>
      <NumericFormat
        value={value === 0 ? "" : value}
        disabled={disabled}
        className={className}
        thousandSeparator=","
        decimalScale={decimalScale}
        fixedDecimalScale
        customInput={StyledTextField}
        onValueChange={onChange}
        required={required}
        allowNegative={false}
        allowLeadingZeros={false}
        style={{ ...style }}
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue < maxLimit || !floatValue;
        }}
        {...params}
        inputProps={{ tabIndex }}
      />
    </div>
  );
};
export default InputCurrency;
