import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
// SHARED COMPONENTS
import { DatePickerField, DropDown } from "components/UI";
// Type
import { ModeAction } from "state/enum";
import { API_NAME_IMPORT_INVOICE_DOWNLOAD } from "shared/constants";

export default function FilterBar({ form, setForm, mode, setMode, setMsg, dataList: { exporterList } }) {
  useEffect(() => {
    document.getElementById("input_etd_from").focus();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>*ETD From :</strong>
        <DatePickerField
          required
          fullWidth={true}
          id="input_etd_from"
          value={form?.etdFrom}
          onChange={newValue => {
            setForm(prev => ({ ...prev, [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_FROM]: newValue }));
          }}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>ETD To :</strong>
        <DatePickerField
          fullWidth={true}
          id="input_etd_to"
          value={form?.etdTo}
          onChange={newValue => {
            setForm(prev => ({ ...prev, [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_TO]: newValue }));
          }}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>ETA From :</strong>
        <DatePickerField
          fullWidth={true}
          id="input_eta_from"
          value={form?.etaFrom}
          onChange={newValue => {
            setForm(prev => ({ ...prev, [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_FROM]: newValue }));
          }}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>ETA To :</strong>
        <DatePickerField
          fullWidth={true}
          id="input_eta_to"
          value={form?.etaTo}
          onChange={newValue => {
            setForm(prev => ({ ...prev, [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_TO]: newValue }));
          }}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>Exporter : </strong>
        <DropDown
          id="select-exporter"
          value={form?.exporterId}
          onChange={e => {
            setForm(prev => ({ ...prev, [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXPORTER_ID]: e.target.value }));
          }}
          menu={exporterList?.map(val => ({ key: val.companyId, value: val.companyAbbr }))}
          defaultValue=""
          disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          placeholder="<All>"
          tabIndex={1}
        />
      </Grid>
    </Grid>
  );
}

FilterBar.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  setMsg: PropTypes.func.isRequired,
  dataList: PropTypes.shape({
    exporterList: PropTypes.arrayOf(
      PropTypes.shape({ companyId: PropTypes.string.isRequired, companyAbbr: PropTypes.string.isRequired })
    ).isRequired,
  }).isRequired,
};
