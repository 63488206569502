import React, { Fragment, useEffect, useState } from "react";
import { Grid, Box, Divider } from "@mui/material";
//Component
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, DropDown } from "components/UI";
import Grouping from "pages/Forecast/ShipmentManagementScreen/components/Grouping/Grouping";
import Mapping from "pages/Forecast/ShipmentManagementScreen/components/Mapping/Mapping";
//TYPE
import { ModeAction } from "state/enum";
//Service
import {
  useShipmentOperationTypeQuery,
  useShipmentExporterQuery,
  useShipmentImporterQuery,
  useShipmentRPackageQuery,
  useShipmentStatusQuery,
  useForecastIsAPMCUserQuery,
} from "shared/services/inventory-forecast";
import { DEFAULT_FORM } from "./constants/shipment.constant";
import { API_SHIPMENT, COMMON_ENUM } from "shared/constants";
import { useSelector } from "react-redux";
export default function ShipmentManagementScreen() {
  const [appId, setAppId] = useState(null);
  const userInfo = useSelector(state => state.auth.user);

  const [form, setForm] = useState({
    ...DEFAULT_FORM,
    [API_SHIPMENT.USER_ID]: userInfo.userName,
    isAPMCUser: userInfo.companyAbbr !== COMMON_ENUM.C_SM_CTG_TMAP_EM,
  });
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);
  //API
  const { data: operationList } = useShipmentOperationTypeQuery();
  const { data: exporterList } = useShipmentExporterQuery(userInfo.companyAbbr);
  const { data: importerList } = useShipmentImporterQuery();
  const { data: rPkgList } = useShipmentRPackageQuery();
  const { data: statusList } = useShipmentStatusQuery(userInfo.companyAbbr);
  const { data: checkAPMCUser } = useForecastIsAPMCUserQuery(userInfo.companyAbbr);
  const handleChangeOperation = () => {
    if (form?.operationId === "G") {
      return (
        <Grouping
          form={form}
          setForm={setForm}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          mode={mode}
          setMode={setMode}
          setMsg={{ setMsgError, setMsgAlert }}
          dataList={{ exporterList, importerList, rPkgList, statusList }}
          functionId={"WDN920K0"}
        />
      );
    } else if (form?.operationId === "M") {
      return (
        <Mapping
          form={form}
          setForm={setForm}
          setMsg={{ setMsgError, setMsgAlert }}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          mode={mode}
          setMode={setMode}
          setAppId={setAppId}
          dataList={{ exporterList, importerList, rPkgList, statusList }}
          functionId={"WDN920K0"}
        />
      );
    } else return "";
  };

  const handleFocus = e => {
    if (form?.operationId === "") {
      const handleTabKey = event => {
        if (event.key === "Tab") {
          event.preventDefault();
        }
      };

      e.target.addEventListener("keydown", handleTabKey);

      e.target.addEventListener("blur", () => {
        e.target.removeEventListener("keydown", handleTabKey);
      });
    }
  };
  useEffect(() => {
    if (checkAPMCUser) {
      setForm(prev => ({
        ...prev,
        isAPMCUser: checkAPMCUser?.isAPMCUser,
      }));
    }
  }, [checkAPMCUser]);
  return (
    <Box sx={{ padding: "1rem" }}>
      <HeaderContentBar title="WDN920K0 : Shipment Management Screen" />
      <AlertMessage type={"warning"} message={msgError} />
      <AlertMessage type={"success"} message={msgAlert} />
      <div id="appid" style={{ display: "none" }}>
        {appId}
      </div>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "325px" }}>* Operation : </strong>
          <DropDown
            data-testid="input-operation"
            id="input-operation"
            required
            fullWidth={true}
            value={form?.operationId}
            onChange={e => {
              setForm({
                [API_SHIPMENT.OPERATION_ID]: e.target.value,
                [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
                [API_SHIPMENT.COMPANY_ABBR]: userInfo.companyAbbr,
                [API_SHIPMENT.USER_ID]: userInfo.userName,
                [API_SHIPMENT.EXPORTER_ID]: "",
                // Id of TMATH
                [API_SHIPMENT.IMPORTER_ID]: "CMP_1208_000001",
                [API_SHIPMENT.R_RKG_OWNER_ID]: "",
                [API_SHIPMENT.FORECAST_MONTH]: "",
                [API_SHIPMENT.SHIPMENT_STATUS_ID]: "",
                [API_SHIPMENT.ETD_FORM]: "",
                [API_SHIPMENT.ETD_TO]: "",
                [API_SHIPMENT.ETA_FORM]: "",
                [API_SHIPMENT.ETA_TO]: "",
                [API_SHIPMENT.BL_FILE_UPLOAD]: "",
                [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
                [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
                [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
                [API_SHIPMENT.NCV_FILE_NAME]: "",
                [API_SHIPMENT.BL_FILE_NAME]: "",
                [API_SHIPMENT.TEMP_NCV_FILE]: "",
                [API_SHIPMENT.TEMP_BL_FILE]: "",
                // [API_SHIPMENT.FILE_NAME]: {
                //   [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
                //   [API_SHIPMENT.NCV_FILE_NAME]: "",
                //   [API_SHIPMENT.BL_FILE_NAME]: "",
                // },
                [API_SHIPMENT.FILE]: {
                  [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
                  // [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
                  // [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
                },
                isAPMCUser: checkAPMCUser?.isAPMCUser ?? userInfo.companyAbbr !== COMMON_ENUM.C_SM_CTG_TMAP_EM,
              });
              setMsgAlert([]);
              setMsgError([]);
              setOnSearch(false);
              setMode(ModeAction.VIEW);
            }}
            menu={operationList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<Select>"
            defaultValue=""
            autoFocus={true}
            onFocus={handleFocus}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "0" }}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {handleChangeOperation()}
        </Grid>
      </Grid>
    </Box>
  );
}
