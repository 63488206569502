import { TextField, styled } from "@mui/material";
import React from "react";

const StyledTextField = styled(TextField)(({ theme, error, textalign }) => ({
  "& .MuiInput-root": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: ".3rem",
  },
  "& .MuiInputBase-input": {
    textAlign: textalign,
    padding: "4px 8px !important",
  },
  "& .MuiInput-root.Mui-focused": {
    border: `1.5px solid black !important`,
    borderRadius: ".3rem",
  },
}));

const InputText = ({
  fullWidth = false,
  id,
  placeholder = "",
  value = "",
  error = false,
  helperText = "",
  onChange = undefined,
  required = undefined,
  style = {},
  type = "text",
  disabled,
  className,
  maxLength,
  focused,
  regularExp,
  textalign = "start",
  tabIndex = -1,
  onChangeFn,
  onBlur,
  ...props
}) => {
  const handleChange = e => {
    try {
      const regularExpCheck = regularExp ?? /^[a-zA-Z0-9]*$/;
      const trimmedValue = e.target.value;

      if (regularExpCheck && !regularExpCheck.test(trimmedValue)) {
        return;
      }

      if (onChange) {
        // Create a new event with the trimmed value
        const newEvent = {
          ...e,
          target: { ...e.target, value: trimmedValue },
        };
        onChange(newEvent);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBlur = e => {
    try {
      const trimmedValue = e.target.value.trim();
      if (trimmedValue) {
        // Create a new event with the trimmed value
        const newEvent = {
          ...e,
          target: { ...e.target, value: trimmedValue },
        };
        onChange(newEvent);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledTextField
      {...props}
      data-testid={id}
      id={id}
      focused={focused}
      type={type}
      fullWidth={fullWidth}
      margin="dense"
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
      inputProps={{
        style: {
          paddingLeft: "12px",
          fontSize: "12px",
          ...style,
        },
        maxLength: maxLength,
        tabIndex,
      }}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      value={value}
      onChange={onChangeFn ? onChangeFn : handleChange}
      required={required}
      disabled={disabled}
      className={className}
      onBlur={onBlur ? onBlur : handleBlur}
      textalign={textalign}
    />
  );
};

export default InputText;
