import { Grid } from "@mui/material";
import React from "react";
import TemplateBar from "./TemplateBar";
import { InputUploadFile } from "components/UI";
import { responseErrors } from "utils";
import { isUndefined } from "lodash";

export default function HeaderSection({ form, setForm, setMsg: { setMsgError, setMsgAlert } }) {
  // ? Upload File
  const uploadProcess = async (field, file, maxLength) => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      if (isUndefined(file)) {
        setForm(prev => ({
          ...prev,
          file: {},
          fileName: "",
        }));
        return true;
      }

      setForm(prev => ({
        ...prev,
        file: file,
        fileName: file.name,
      }));
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  return (
    <Grid container spacing={2} sx={{ alignItems: "center" }}>
      <Grid item xs={12}>
        <strong>Please browse the Excel file, and click Upload button.</strong>
      </Grid>
      <Grid item xs={1}>
        <strong>*Excel File :</strong>
      </Grid>
      <Grid item xs={5}>
        <InputUploadFile
          id="input-upload-excel-r-pkg"
          fileType=".xlsx"
          required={true}
          onConfirmDelete={false}
          value={form?.file}
          uploadProcess={uploadProcess}
          maxLength={50}
          tabIndex={1}
          autoFocus={true}
        />
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <TemplateBar setMsg={{ setMsgError, setMsgAlert }} />
      </Grid>
    </Grid>
  );
}
