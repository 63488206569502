import { Box, Grid } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { GridRowModes } from "@mui/x-data-grid";
import { InputText, InputDropDown } from "components/UI/index";
import { ModalPrice } from "pages/Master/RpackageTypeScreen/RpackageType/ModalPrice";
import {
  setDataPriceList,
  parseCurrencyString,
  responseErrors,
  messageTypeDisplay,
  responseDownloadFileErrors,
  responseSuccess,
  findObject,
} from "utils";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import ActionBar from "../../Partials/ActionBar";
import { MessageType, ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import SubmitBar from "pages/Partials/SubmitBar";
import DataTable from "pages/DataTable";
import ModalPreviewImage from "pages/Master/RpackageTypeScreen/RpackageType/ModalPreviewImage";
import { userProfile } from "constant";
import { validationUploadFile } from "utils/validation";
import {
  useRPkgTypeMasterCreateMutation,
  useRPkgTypeMasterEditMutation,
  useRPkgTypeMasterDownloadTemplateMutation,
  useRPkgTypeMasterUploadImageMutation,
  useRPkgTypeMasterDeleteMutation,
  useRPkgTypeMasterRPkgOwnerQuery,
  useRPkgTypeMasterRPkgOwnerEditQuery,
  useRPkgTypeMasterSearchMutation,
  useRPkgTypeMasterCategoryQuery,
  useRPkgTypeMasterGetRPkgTypeQuery,
} from "shared/services/master";

import { COMMON_ENUM, FIRST_PAGE } from "shared/constants";
import useColumnRPackageType from "./hooks/useColumnRPackageType";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useLoadingContext } from "shared/contexts/LoadingContext";

const RpackageTypeScreen = () => {
  const { startLoading, stopLoading } = useLoadingContext();
  const user = useSelector(state => state.auth.user);
  const dateTime = useSelector(state => state.common.dateTime);
  const currentDate = dateTime
    ? dayjs(dateTime, COMMON_ENUM.DATETIME_FORMAT).format("DD/MM/YYYY")
    : dayjs().format("DD/MM/YYYY");
  const confirmDialogCtx = useConfirmDialogContext();
  const [mode, setMode] = useState("view");
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [searchForm, setSearchForm] = useState({
    rPkgOwnerCompId: "",
    rPkgTypeId: "",
    rPkgDesc: "",
  });

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const handleChangePagination = async (event, value) => {
    setMsgError([]);
    setMsgAlert([]);

    setPageNumber(value);

    const { searchData, data } = await getSearch(value);

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);
    setRows(data);
  };

  // 2. api
  const { data: ownerData, isSuccess: isSuccessOwnerData } = useRPkgTypeMasterRPkgOwnerQuery();
  const { data: ownerDataEdit } = useRPkgTypeMasterRPkgOwnerEditQuery();
  const createRPkgType = useRPkgTypeMasterCreateMutation();
  const editRPkgType = useRPkgTypeMasterEditMutation();
  const downloadRPkgType = useRPkgTypeMasterDownloadTemplateMutation();
  const uploadFile = useRPkgTypeMasterUploadImageMutation();
  const deleteRPkgType = useRPkgTypeMasterDeleteMutation();
  const {
    data: typeData,
    isSuccess: isSuccessTypeData,
    refetch: refetchGetType,
  } = useRPkgTypeMasterGetRPkgTypeQuery({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompId: searchForm.rPkgOwnerCompId,
  });

  const searchDataAsync = useRPkgTypeMasterSearchMutation();

  const { data: categoryPrice } = useRPkgTypeMasterCategoryQuery();

  // 3. initail value search from
  useEffect(() => {
    if (isSuccessOwnerData) {
      // TODO: R-Package Owner ค่า Default ต้องเป็น Company ที่ Login
      setSearchForm(old => ({
        ...old,
        rPkgOwnerCompId: ownerData?.[0]?.rPkgOwnerCompId,
      }));
      document.getElementById("select_rPkgOwnerCompId").focus();
    }
  }, [isSuccessOwnerData, ownerData]);

  const getSearch = async (pageNumber = 1) => {
    setOnSearch(true);
    const body = {
      rPkgOwnerCompId: searchForm?.rPkgOwnerCompId,
      rPkgType: searchForm?.rPkgTypeId,
      rPkgDesc: searchForm?.rPkgDesc,
      pageNumber: pageNumber,
    };
    const searchData = await searchDataAsync(body);

    const data =
      searchData?.result?.data?.map((item, index) => ({
        no: index + 1,
        rowNumber: item?.rowNumber,
        rPkgOwnerCompId: item.companyId,
        categoryCd: item.categoryCd,
        rPkgType: item.rPkgType,
        rPkgTypeId: item.rPkgTypeId,
        rPkgDesc: item.rPkgDesc,
        weight: item.weight,
        asbLength: item.asbLength,
        asbWidth: item.asbWidth,
        asbHeight: item.asbHeight,
        pic: item.asbPic,
        dsbLength: item.dsbLength,
        dsbWidth: item.dsbWidth,
        dsbHeight: item.dsbHeight,
        dsbPic: item.dsbPic,
        price: item.currencys,
        currencys: item.currencys,
        color: item.color.trim(),
        effDtFrom: item.effDtFrom,
        effDtTo: item.effDtTo,
        value: item.value,
        updateBy: item.updateBy,
        updateDt: item.updateDt,
      })) ?? [];

    return { searchData, data };
  };

  // 6. handleSearch
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.VIEW);
      setPageNumber(FIRST_PAGE);
      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});
      // validate
      let stopProcess = false;

      if (searchForm.rPkgOwnerCompId === "") {
        const msg = messageTypeDisplay(MessageType.EMPTY, ["R-Package Owner"]);
        setMsgError(old => [...old, msg]);
        stopProcess = true;
      }

      const regex1 = /^\*.+$/;
      const regex2 = /^[^*]+\*[^*]+$/;
      const regex3 = /^[^*]+\*[^*]+\*$/;
      const regex4 = /^\*$/;

      if (
        // !regex.test(searchForm.rPkgDesc) && searchForm.rPkgDesc !== ""
        (regex1.test(searchForm.rPkgDesc) ||
          regex2.test(searchForm.rPkgDesc) ||
          regex3.test(searchForm.rPkgDesc) ||
          regex4.test(searchForm.rPkgDesc)) &&
        searchForm.rPkgDesc !== ""
      ) {
        const msg = messageTypeDisplay(MessageType.INVALID, ["R-Package Type Description"]);
        setMsgError(old => [...old, msg]);
        stopProcess = true;
      }

      if (stopProcess) {
        window.scrollTo(0, 0);
        return;
      }

      const { searchData, data } = await getSearch(1);

      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError(old => [...old, msg]);
        stopProcess = true;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // 7. handleDeleteClick
  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const selectedNo = rowSelectionModel[0];
    const rowsSelected = rows.find(v => v.rowNumber === selectedNo);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });

    if (!confirm) {
      return;
    }

    // TODO: Action Delete
    try {
      const response = await deleteRPkgType(
        { updateBy: userProfile.userId, updateDt: rowsSelected.updateDt },
        rowsSelected?.rPkgTypeId
      );

      const { searchData, data } = await getSearch(pageNumber);

      if (data.length > 0) {
        setPagination(searchData?.result?.pagination ?? {});
        setPageNumber(searchData?.result?.pagination?.pageNumber);
        setRows(data);
      } else {
        setSearchForm({
          rPkgOwnerCompId: "CMP_1208_000001", // TODO get profile
          rPkgTypeId: "",
          rPkgDesc: "",
        });
        setRows([]);
      }

      setRowSelectionModel([]);
      setMode(ModeAction.VIEW);

      // const msg = messageTypeDisplay(MessageType.DELETED);
      const msg = responseSuccess(response);

      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      refetchGetType();
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  // 8. processRowUpdate (Action Add and Edit)
  const processRowUpdate = async newRow => {
    console.log("processRowUpdate");

    // validate
    setMsgError([]);
    setMsgAlert([]);

    if (newRow.isNew) {
      try {
        const body = {
          rPkgOwnerCompId: newRow.rPkgOwnerCompId,
          rPkgOwnerCompArrr: newRow.rPkgOwnerCompId
            ? findObject({
                data: ownerDataEdit,
                value: newRow.rPkgOwnerCompId,
                property: "rPkgOwnerCompId",
                field: "rPkgOwnerCompAbbr",
              })
            : "",
          // dataOwner: userProfile.dataOwner,
          categoryCd: newRow.categoryCd,
          rPkgType: newRow.rPkgType?.toUpperCase(),
          rPkgDesc: newRow.rPkgDesc,
          weight: newRow.weight ? parseCurrencyString(newRow.weight) : "",
          asbLength: newRow.asbLength ? parseCurrencyString(newRow.asbLength) : "",
          asbWidth: newRow.asbWidth ? parseCurrencyString(newRow.asbWidth) : "",
          asbHeight: newRow.asbHeight ? parseCurrencyString(newRow.asbHeight) : "",
          asbPic: newRow.pic,
          dsbLength: newRow.dsbLength ? parseCurrencyString(newRow.dsbLength) : "",
          dsbWidth: newRow.dsbWidth ? parseCurrencyString(newRow.dsbWidth) : "",
          dsbHeight: newRow.dsbHeight ? parseCurrencyString(newRow.dsbHeight) : "",
          dsbPic: newRow.dsbPic,
          color: newRow.color.trim(),
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          createBy: user.userName,
          currencys:
            newRow.currencys && newRow.currencys.length > 0
              ? newRow.currencys?.map(v => {
                  return {
                    seqNo: v.seqNo,
                    price: v.price ? parseCurrencyString(v.price) : "",
                    currentCd: v.currentCd,
                  };
                })
              : "",
        };
        console.log(body);

        const response = await createRPkgType(body);
        const msg = responseSuccess(response);
        // const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
        refetchGetType();
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      // action Update
      try {
        const rPkgTypeId = newRow.rPkgTypeId;
        const body = {
          rPkgOwnerCompId: newRow.rPkgOwnerCompId,
          rPkgOwnerCompArrr: newRow.rPkgOwnerCompId
            ? findObject({
                data: ownerDataEdit,
                value: newRow.rPkgOwnerCompId,
                property: "rPkgOwnerCompId",
                field: "rPkgOwnerCompAbbr",
              })
            : "",
          // dataOwner: userProfile.dataOwner,
          // categoryCd: newRow.categoryCd,
          rPkgType: newRow.rPkgType?.toUpperCase(),
          rPkgDesc: newRow.rPkgDesc,
          weight: parseCurrencyString(newRow.weight),
          asbLength: parseCurrencyString(newRow.asbLength),
          asbWidth: parseCurrencyString(newRow.asbWidth),
          asbHeight: parseCurrencyString(newRow.asbHeight),
          asbPic: newRow.pic,
          dsbLength: parseCurrencyString(newRow.dsbLength),
          dsbWidth: parseCurrencyString(newRow.dsbWidth),
          dsbHeight: parseCurrencyString(newRow.dsbHeight),
          dsbPic: newRow.dsbPic,
          color: newRow.color.trim(),
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          updateBy: user.userName,
          updateDt: newRow.updateDt,
          currencys: newRow.currencys?.map(v => {
            return {
              seqNo: v.seqNo,
              price: parseFloat(v.price.replace(",", "")),
              currentCd: v.currentCd,
            };
          }),
        };
        const response = await editRPkgType(body, rPkgTypeId);
        const msg = responseSuccess(response);
        // const msg = messageTypeDisplay(MessageType.UPDATED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    }

    if (rows.length === 1 && ModeAction.ADD === mode) {
      setOnSearch(false);
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
    } else {
      const { searchData, data } = await getSearch();

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm({
      rPkgDesc: "",
      rPkgOwnerCompId: "CMP_1208_000001", // TODO get profile
      rPkgTypeId: "",
    });
    setRows([]);
    setPagination({});
    setPageNumber(1);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    try {
      // validate
      setMsgError([]);
      setMsgAlert([]);
      try {
        const { data } = await getSearch(1);
        if (!data.length) {
          const msg = messageTypeDisplay(MessageType.NOT_FOUND);
          setMsgError(old => [...old, msg]);
          return;
        }
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      // const filename = `R_Package_Type_Master_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      const body = {
        dataOwner: userProfile.dataOwner,
        rPkgOwnerCompId: searchForm.rPkgOwnerCompId,
        rPkgType: searchForm.rPkgTypeId,
        rPkgDesc: searchForm.rPkgDesc,
        pageNumber: pageNumber,
        // rowsPerPage: 10,
      };
      // action download
      await downloadRPkgType(body);
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleCopyClick = () => {
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    setMode(ModeAction.ADD);

    const rowCopy = rows.find(v => v.rowNumber === rowSelectionModel[0]);
    const maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

    setRows(oldRows => [
      ...oldRows,
      {
        rowNumber: maxNo + 1,
        rPkgOwnerCompId: rowCopy.rPkgOwnerCompId,
        categoryCd: rowCopy.categoryCd,
        rPkgType: rowCopy.rPkgType,
        rPkgTypeId: rowCopy.rPkgTypeId,
        rPkgDesc: rowCopy.rPkgDesc,
        weight: rowCopy.weight,
        asbLength: rowCopy.asbLength,
        asbWidth: rowCopy.asbWidth,
        asbHeight: rowCopy.asbHeight,
        dsbLength: rowCopy.dsbLength,
        dsbWidth: rowCopy.dsbWidth,
        dsbHeight: rowCopy.dsbHeight,
        price: (() => setDataPriceList(rowCopy.price))(),
        currencys: rowCopy.currencys,
        color: rowCopy.color.trim(),
        effDtFrom: rowCopy.effDtFrom,
        effDtTo: rowCopy.effDtTo,
        isNew: true,
      },
    ]);

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNo + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNo + 1, rowCopy.rowNumber]);
  };

  const onRowSelectionModelChange = newRowSelectionModel => {
    setMsgError([]);
    setMsgAlert([]);

    if (newRowSelectionModel?.length) {
      setMode(ModeAction.SELECTED);
    } else {
      setMode(ModeAction.VIEW);
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  const handleEdit = async () => {
    setMsgError([]);
    setMsgAlert([]);

    // validate
    const newRow = rows.find(val => val.rowNumber === rowSelectionModel[0]);

    if (dayjs(currentDate, "DD/MM/YYYY").isAfter(dayjs(newRow.effDtTo, COMMON_ENUM.DATE_FORMAT))) {
      const msg = messageTypeDisplay(MessageType.DELETE_EFFECTIVE_DATE_TO_PASS, [
        "delete or edit R-Package Type",
        dayjs(newRow.effDtTo, COMMON_ENUM.DATE_FORMAT).format("DD/MM/YYYY"),
      ]);
      setMsgError([msg]);
      window.scrollTo(0, 0);
      return false;
    }

    setMode(ModeAction.EDIT);
    let tempRowModesModel = rowModesModel;

    const rowSelect = rowSelectionModel.map(val => {
      tempRowModesModel = {
        ...tempRowModesModel,
        [val]: { mode: GridRowModes.Edit },
      };
    });

    if (rowSelect) {
      setRowModesModel(tempRowModesModel);
    }
  };

  // modal price
  const [isOpenModalPrice, setIsOpenModalPrice] = useState(false);
  const [rowNumberPrice, setRowNumberPrice] = useState(null);
  const [rowInitialDataPrice, setRowInitialDataPrice] = useState([]);

  const handleModalPrice = (id, initialData = []) => {
    setIsOpenModalPrice(true);
    setRowNumberPrice(id);
    setRowInitialDataPrice(initialData);
  };

  // modal preview image
  const [isOpenModalImage, setIsOpenModalImage] = useState(false);
  const [rowNumberImage, setRowNumberImage] = useState(null);
  const [modalImageType, setModalImageType] = useState(null);

  const handleModalImage = (id, type) => {
    setIsOpenModalImage(true);
    setRowNumberImage(id);
    setModalImageType(type);
  };

  const handleUploadPic = async (type, file, columnName) => {
    // TODO: Action Upload
    try {
      setMsgError([]);
      // validate
      const { isSuccess, errors } = validationUploadFile({
        file: file,
        rule: [
          {
            type: MessageType.TYPE_IMAGE,
            key: [file.name, "PNG", "JPG", "JPEG"],
          },
          {
            type: MessageType.SIZE_IMAGE,
            key: [columnName, "5.0 MB"],
            maxSize: 1, // mb
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const res = await uploadFile({ image: file }, type === "dsbPic" ? "dsb" : "asb");
      stopLoading();
      if ("statusCode" in res && res.statusCode === 200) {
        alert("upload successfully");
        // return { path: res.result.path, preview: res.result.previewPath, filename: res.result.filename };
        return res.result.path;
      }
      return false;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  useEffect(() => {
    if (isSuccessTypeData) {
      setSearchForm(old => ({
        ...old,
        rPkgTypeId: "",
      }));
    }
  }, [isSuccessTypeData]);

  // 4. columns
  const columns = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useColumnRPackageType(
        rows,
        mode,
        ownerDataEdit,
        categoryPrice,
        rowSelectionModel,
        handleUploadPic,
        handleModalImage,
        handleModalPrice,
        currentDate
        // handleChangeColor
      ),
    [rows, rowSelectionModel, mode]
  );

  // 5. columnGroupingModel
  const columnGroupingModel = [
    {
      groupId: "No.",
      headerAlign: "center",
      children: [{ field: "rowNumber" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Owner",
      headerAlign: "center",
      children: [{ field: "rPkgOwnerCompId" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Category",
      headerAlign: "center",
      children: [{ field: "categoryCd" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Type",
      headerAlign: "center",
      children: [{ field: "rPkgType" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Type Description",
      headerAlign: "center",
      children: [{ field: "rPkgDesc" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Weight (Kg)",
      headerAlign: "center",
      children: [{ field: "weight" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Assembly Size(mm)",
      headerAlign: "center",
      children: [{ field: "asbLength" }, { field: "asbWidth" }, { field: "asbHeight" }, { field: "pic" }],
      headerClassName: "table-header-group",
    },
    {
      groupId: "Dis-assembly Size(mm)",
      headerAlign: "center",
      children: [{ field: "dsbLength" }, { field: "dsbWidth" }, { field: "dsbHeight" }, { field: "dsbPic" }],
      headerClassName: "table-header-group",
    },
    {
      groupId: "Price",
      headerAlign: "center",
      children: [{ field: "price" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Color",
      headerAlign: "center",
      children: [{ field: "color" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Effective Date (DD/MM/YYYY)",
      headerAlign: "center",
      children: [{ field: "effDtFrom" }, { field: "effDtTo" }],
      headerClassName: "table-header-group",
    },
  ];

  return (
    <Fragment>
      <Box sx={{ padding: "0rem 1rem 1rem 1rem" }}>
        {/* Header */}
        <HeaderContentBar title="WDN91010 : R-Package Type Master Maintenance Screen" />
        {/* Alert Message */}
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={4} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "250px" }}>* R-Package Owner : </strong>
            <InputDropDown
              id="select_rPkgOwnerCompId"
              required
              value={searchForm.rPkgOwnerCompId}
              onChange={e => {
                setSearchForm(old => ({
                  ...old,
                  rPkgOwnerCompId: e.target.value,
                }));
              }}
              memu={ownerData?.map(val => ({
                key: val.rPkgOwnerCompId,
                value: val.rPkgOwnerCompAbbr,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<Select>"
              defaultValue=""
              autoFocus={true}
              tabIndex={1}
            />
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "250px" }}>R-Package Type : </strong>
            <InputDropDown
              id="select_rPkgTypeId"
              value={searchForm.rPkgTypeId}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  rPkgTypeId: e.target.value,
                }))
              }
              memu={typeData?.map(val => ({
                key: val.rPkgType,
                value: val.rPkgType,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
              tabIndex={1}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Type Description:</strong>
            <InputText
              id="input_rPkgDesc"
              fullWidth={true}
              value={searchForm.rPkgDesc}
              onChange={e => {
                setSearchForm(old => ({
                  ...old,
                  rPkgDesc: e.target.value,
                }));
              }}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              maxLength={30}
              regularExp={/^.*$/}
              tabIndex={1}
            />
          </Grid>
        </Grid>
      </Box>
      <ActionBar
        mode={mode}
        rows={rows}
        columns={columns}
        setRows={setRows}
        setMode={setMode}
        setMsgError={setMsgError}
        setMsgAlert={setMsgAlert}
        onSearch={onSearch}
        setOnSearch={setOnSearch}
        setRowModesModel={setRowModesModel}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        rowModesModel={rowModesModel}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleDownloadExcel={handleDownloadExcel}
        handleDeleteClick={handleDeleteClick}
        handleCopyClick={handleCopyClick}
        handleEdit={handleEdit}
        firstField={"select_rPkgOwnerCompId"}
        functionId={"WDN91010"}
        tabIndex={1}
        tabIndexNext={1}
      />
      <Box sx={{ padding: "0rem 1rem 0rem 1rem" }}>
        <Grid>
          {onSearch && rows.length !== 0 && (
            <DataTable
              mode={mode}
              onSearch={onSearch}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              setMode={setMode}
              rows={rows}
              rowModesModel={rowModesModel}
              columns={columns}
              setRowModesModel={setRowModesModel}
              processRowUpdate={processRowUpdate}
              pagination={pagination}
              pageNumber={pageNumber}
              handleChangePagination={handleChangePagination}
              columnGroupingModel={columnGroupingModel}
              columnVisibilityModel={{
                currencys: false,
                rPkgTypeId: false,
              }}
              onRowSelectionModelChange={onRowSelectionModelChange}
              slotProps={{
                baseCheckbox: {
                  tabIndex: 1,
                },
              }}
            />
          )}
          <SubmitBar
            mode={mode}
            rows={rows}
            setMode={setMode}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            setMsgError={setMsgError}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            functionId={"WDN91010"}
            tabIndex={1}
          />
        </Grid>
      </Box>

      {/* ModalPrice */}
      <ModalPrice
        open={isOpenModalPrice}
        setOpen={setIsOpenModalPrice}
        rowNumber={rowNumberPrice}
        initialData={rowInitialDataPrice}
        setRowsCurrencys={setRows}
        rowsCurrencys={rows}
        categoryPrice={categoryPrice?.find(v => v.cd === rowInitialDataPrice?.categoryCd)}
        modeRows={mode === "selected" ? "view" : mode}
        functionId={"WDN91012"}
      />

      {/* ModalPreviewImage */}
      <ModalPreviewImage
        open={isOpenModalImage}
        setOpen={setIsOpenModalImage}
        row={rowNumberImage}
        // initialData={rows}
        type={modalImageType}
        functionId={"WDN91011"}
      />
    </Fragment>
  );
};

export default RpackageTypeScreen;
