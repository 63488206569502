import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import { InputButton } from "components/UI";
import { ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { isNull } from "lodash";
export default function ButtonSubmit({
  mode,
  rows,
  setMode,
  setRows,
  setRowModesModel,
  setMsgError,
  rowModesModel,
  rowSelectionModel,
  setRowSelectionModel,
  setOnSearch,
  isRPkg = false,
  executeFunction = null,
  functionId,
}) {
  const confirmDialogCtx = useConfirmDialogContext();

  useMemo(() => mode, [mode]);

  const handleSaveClick = async () => {
    try {
      const saveConfirm = await confirmDialogCtx.success({ type: "confirmSave" });
      if (!saveConfirm) {
        return;
      }
      if (mode === ModeAction.ADD) {
        //To find maximum rowNumber
        const latestKey = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

        setRowModesModel({
          ...rowModesModel,
          [latestKey]: { mode: GridRowModes.View },
        });
        return;
      } else {
        let tempRowModesModel = rowModesModel;
        const rowSelect = rowSelectionModel.map(val => {
          tempRowModesModel = {
            ...tempRowModesModel,
            [val]: { mode: GridRowModes.View },
          };
          // return tempRowModesModel;
        });

        rowSelect && setRowModesModel(tempRowModesModel);
        return;
      }
    } catch (error) {
      setMsgError([error]);
    }
  };
  const handleCancelClick = async () => {
    const confirm = await confirmDialogCtx.success({ type: "confirmCancel" });
    if (!confirm) {
      return;
    }

    setMsgError([]);
    if (mode === ModeAction.ADD) {
      //To find maximum rowNumber
      const latestKey = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

      setRowModesModel({
        ...rowModesModel,
        [latestKey]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const newRows = rows.filter(row => !row.isNew);
      setRows(newRows); //Keep only not new
      if (!isNull(executeFunction)) {
        await executeFunction(newRows);
      }
    } else {
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map(val => {
        tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.View, ignoreModifications: true },
        };
      });

      rowSelect && setRowModesModel(tempRowModesModel);
    }
    if (!isNull(executeFunction)) {
      await executeFunction();
    }
    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
  };

  return (
    <>
      {[ModeAction.ADD, ModeAction.EDIT, ModeAction.COPY].includes(mode) && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <InputButton
            id="btn_save"
            value={`${isRPkg ? "Confirm" : "Confirm Plan"}`}
            onClick={handleSaveClick}
            name={`${functionId}Confirm`}
            tabIndex={0}
          />
          <InputButton
            id="btn_cancel"
            value={"Cancel"}
            onClick={handleCancelClick}
            name={`${functionId}Cancel`}
            tabIndex={0}
          />
        </Grid>
      )}
    </>
  );
}
