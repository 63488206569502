import React from "react";
import { Grid } from "@mui/material";
import { InputButton } from "components/UI";
import { BUTTON_VALUE } from "shared/constants";

export default function ButtonBar(props) {
  const { functionId, handleSearch, handleClear } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <InputButton
          id="btn_search"
          value={BUTTON_VALUE.SEARCH}
          onClick={handleSearch}
          tabIndex={0}
          name={`${functionId}Search`}
        />
        <InputButton
          id="btn_clear"
          tabIndex={0}
          value={BUTTON_VALUE.CLEAR}
          onClick={handleClear}
          name={`${functionId}Clear`}
        />
      </Grid>
    </Grid>
  );
}
