/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Grid } from "@mui/material";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { InputDropDown, AlertMessage, InputText } from "components/UI/index";
import { MessageType, ModeAction } from "state/enum";
import { HeaderContentBar } from "components/Layout";
import ActionBar from "pages/Partials/ActionBar";
import { findObject, messageTypeDisplay, responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import SubmitBar from "pages/Partials/SubmitBar";
import DataTable from "pages/DataTable";
import {
  useCompanyPlantInTable,
  useGetSubPlant,
  useGetSubPlantInTable,
  useInOutStatus,
  useRoutePointCategory,
  useRoutePointCtgListSearch,
  useSearchRoutePoint,
  useRoutePathDownloadExcelMutation,
  useDeleteRoutePoint,
  useUpdateRoutePoint,
  useCreateRoutePoint,
  useGetCountry,
  useGetCountryInTable,
} from "shared/services/master/routepoint";
import { ModalPossibleStatus } from "pages/Master/RoutePointScreen/RoutePoint";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { GridRowModes } from "@mui/x-data-grid";
import { userProfile } from "constant";
import { validationRequestErrors, validationSearchForm } from "utils/validation";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import useColumnRoutePoint from "./hooks/useColumnRoutePoint";
import { COMMON_ENUM, FIRST_PAGE } from "shared/constants";

const RoutePointScreen = () => {
  const userInfo = useSelector(state => state.auth.user);
  const confirmDialogCtx = useConfirmDialogContext();

  useEffect(() => {
    document.getElementById("select_countryCd").focus();
  }, []);

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    if (Object.keys(rowModesModel).length !== 0) {
      setTimeout(() => {
        const el = document.getElementById("WDN91030_PossibleStatus");
        if (el) {
          el.classList.add("Mui-focusVisible");
          el.focus();
        }
      }, 500);
    }
  }, [rowModesModel]);

  const [routePointCategoryInTable, setRoutePointCategoryInTable] = useState("");
  const [countryCdInTable, setCountryCdInTable] = useState("");

  const [mode, setMode] = useState("view");

  const [searchForm, setSearchForm] = useState({
    countryCd: "",
    routePointCd: "",
    routePointName: "",
    extensionCd: "",
  });

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);

    // TODO: Action Search
    const searchData = await searchDataAsync({
      countryCd: searchForm.countryCd,
      routePointCd: searchForm.routePointCd,
      routePointName: searchForm.routePointName,
      extensionCd: searchForm.extensionCd,
      pageNumber: value,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);
    setRows(data);
  };

  const [onSearch, setOnSearch] = useState(false);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  // 2. api
  const downloadExcel = useRoutePathDownloadExcelMutation();

  const { data: countryData, refetch: refecthCountryData } = useGetCountry();
  const { data: countryInTableData } = useGetCountryInTable();

  const { data: routePointCategoryData } = useRoutePointCtgListSearch();
  const { data: routePointCategoryInTableData } = useRoutePointCategory();

  const cdOrder = { N: 1, P: 2, C: 3, D: 4 };
  const { data: subPlantData } = useGetSubPlant();
  subPlantData?.sort((a, b) => cdOrder[a.cd] - cdOrder[b.cd]);

  const { data: subPlantInTableData } = useGetSubPlantInTable();
  subPlantInTableData?.sort((a, b) => cdOrder[a.cd] - cdOrder[b.cd]);
  const { data: companyPlantData } = useCompanyPlantInTable({
    dataOwner: COMMON_ENUM.C_SM_CTG_TMAP_EM,
    cd: countryCdInTable,
  });

  const { data: inOutStatusData } = useInOutStatus();

  const searchDataAsync = useSearchRoutePoint();
  const deleteRoutePoint = useDeleteRoutePoint();
  const updateRoutePoint = useUpdateRoutePoint();
  const createRoutePoint = useCreateRoutePoint();

  const handleCopy = useCallback(() => {
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);

    const rowCopy = rows.find(v => v.rowNumber === rowSelectionModel[0]);
    const maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

    setRows(oldRows => [
      ...oldRows,
      {
        rowNumber: maxNo + 1,
        routePointCategory: rowCopy.routePointCategory,
        routePointCategoryName: rowCopy.routePointCategoryName,
        countryCd: rowCopy.countryCd,
        companyPlant: rowCopy.plantId,
        extensionCd: rowCopy.extensionCd,
        extensionValue: rowCopy.extensionValue,
        routePointName: rowCopy.routePointName,
        possibleStatusId: rowCopy.possibleStatusId,
        possibleStatus: rowCopy.possibleStatus,
        trackSts: rowCopy.trackSts,
        enableFlag: rowCopy.enableFlag,
        isNew: true,
      },
    ]);

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNo + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNo + 1, rowCopy.rowNumber]);
  }, [rowSelectionModel, rows]);

  const handleModalPossible = id => {
    setRowNumberPossible(id);
    setIsOpenModalPossible(true);
  };
  // 4. columns
  const columns = useMemo(() => {
    return useColumnRoutePoint({
      mode,
      rows,
      routePointCategoryInTable,
      setRoutePointCategoryInTable,
      setCountryCdInTable,
      routePointCategoryInTableData,
      countryInTableData,
      companyPlantData,
      subPlantInTableData,
      rowSelectionModel,
      handleModalPossible,
      inOutStatusData,
    });
  }, [rows, companyPlantData, routePointCategoryInTable, mode]);

  // get search
  const getSearch = async (pageNumber = 1) => {
    // TODO: Action Search
    const searchData = await searchDataAsync({
      dataOwner: userProfile.dataOwner,
      countryCd: searchForm.countryCd,
      routePointCd: searchForm.routePointCd,
      routePointName: searchForm.routePointName,
      extensionCd: searchForm.extensionCd,
      pageNumber: pageNumber,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];
    setOnSearch(true);

    return { searchData, data };
  };

  // modal possible
  const [selectedPossible, setSelectedPossible] = useState([]);
  const [rowNumberPossible, setRowNumberPossible] = useState(null);
  const [isOpenModalPossible, setIsOpenModalPossible] = useState(false);

  const handleSearch = async e => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setRowSelectionModel([]);
      setRowModesModel({});
      setMode(ModeAction.VIEW);
      setPageNumber(FIRST_PAGE);
      setPagination({});

      const { searchData, data } = await getSearch();

      const { isSuccess, errors } = validationSearchForm({
        rule: [
          {
            data: data,
            type: MessageType.NOT_FOUND,
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setRowModesModel({});
    setPageNumber(FIRST_PAGE);
    setPagination({});
    setRows([]);
    setOnSearch(false);
    setSearchForm(prev => ({
      ...prev,
      countryCd: "",
      routePointCd: "",
      routePointName: "",
      extensionCd: "",
    }));
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    try {
      const { data } = await getSearch();
      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError(old => [...old, msg]);
        return;
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
    try {
      const option = {
        dataOwner: userProfile.dataOwner,
        countryCd: searchForm.countryCd,
        routePointCd: searchForm.routePointCd,
        routePointName: searchForm.routePointName,
        extensionCd: searchForm.extensionCd,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };

      await downloadExcel(option);
      return;
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }

    try {
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);
      const response = await deleteRoutePoint(
        { updateBy: userInfo.userName, updateDt: row.updateDt },
        row?.routePointId
      );

      const { searchData, data } = await getSearch(pageNumber);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      const msg = responseSuccess(response);
      // const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const processRowUpdate = async newRow => {
    setMsgError([]);
    setMsgAlert([]);

    const { isSuccess, errors } = validationRequestErrors({
      columns: columns,
      data: newRow,
    });

    if (!isSuccess) {
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }

    if (newRow.isNew) {
      try {
        const body = {
          dataOwner: userProfile.dataOwner,
          routePointCd: newRow.routePointCategory,
          routePointName: newRow.routePointName,
          countryCd: newRow.countryCd,
          companyId: findObject({
            data: companyPlantData,
            value: newRow.companyPlant,
            property: "plantId",
            field: "companyId",
          }),
          companyAbbr: findObject({
            data: companyPlantData,
            value: newRow.companyPlant,
            property: "plantId",
            field: "companyAbbr",
          }),
          plantId: newRow.companyPlant,
          impExpCd: findObject({
            data: companyPlantData,
            value: newRow.companyPlant,
            property: "plantId",
            field: "impExpCd",
          }),
          extensionCd: newRow.extensionCd,
          trackSts: newRow.trackSts,
          enableFlag: isEmpty(newRow.enableFlag) || newRow.enableFlag === "Y" ? "Y" : "N",
          refFileUploadId: "",
          createBy: userInfo.userName,
          possibleStatusId: newRow.possibleStatusId
            ? newRow.possibleStatusId?.map(v => ({
                seqNo: v.seqNo,
                routePointSts: v.routePointSts,
                refFileUploadId: "",
              }))
            : [],
        };
        const response = await createRoutePoint(body);

        const msg = responseSuccess(response);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      try {
        const row = rows.find(v => v.rowNumber === rowSelectionModel[0]);
        const body = {
          dataOwner: userProfile.dataOwner,
          trackSts: newRow.trackSts,
          enableFlag: isEmpty(newRow.enableFlag) || newRow.enableFlag === "Y" ? "Y" : "N",
          updateBy: userInfo.userName,
          updateDt: row.updateDt,
          possibleStatusId:
            newRow.possibleStatusId?.map(v => ({
              routePointStsId: v.routePointStsId,
              seqNo: v.seqNo,
              routePointSts: v.routePointSts,
              refFileUploadId: v.refFileUploadId,
            })) || [],
        };
        const response = await updateRoutePoint(body, newRow.routePointId);

        const msg = responseSuccess(response);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    }
    if (rows.length === 1 && ModeAction.ADD === mode) {
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
    } else {
      const { searchData, data } = await getSearch(pageNumber);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };
  const handleAddClick = () => {
    let maxNo = -1;
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    // setAddButton(true);

    const field = {};
    columns.forEach(column => {
      if (column.field === "rowNumber") {
        maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));
        field[column.field] = rows.length ? maxNo + 1 : maxNo;
      } else {
        field[column.field] = "";
      }
    });

    setRows(oldRows => [
      ...oldRows,
      {
        ...field,
        enableFlag: "Y",
        isNew: true,
      },
    ]);

    const maxNoModel = rows.length ? maxNo + 1 : maxNo;

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNoModel]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNoModel]);
  };
  useEffect(() => {
    refecthCountryData();
  }, []);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN91030 : Route Point Master Maintenance Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "300px" }}>Country : </strong>
            <InputDropDown
              id="select_countryCd"
              value={searchForm.countryCd}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  countryCd: e.target.value,
                }))
              }
              memu={countryData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              tabIndex={1}
              placeholder="<All>"
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "300px" }}>Route Point Category : </strong>
            <InputDropDown
              id="select_routePointCd"
              value={searchForm.routePointCd}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  routePointCd: e.target.value,
                }))
              }
              memu={routePointCategoryData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              tabIndex={1}
              placeholder="<All>"
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "300px" }}>Route Point Name : </strong>
            <InputText
              id="input_routePointName"
              fullWidth={true}
              value={searchForm.routePointName}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  routePointName: e.target.value,
                }))
              }
              tabIndex={1}
              regularExp={/^[a-zA-Z0-9_*-/\\ /s]*$/}
              maxLength={30}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "300px" }}>Sub Plant : </strong>
            <InputDropDown
              id="select_extensionCd"
              value={searchForm.extensionCd}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  extensionCd: e.target.value,
                }))
              }
              memu={subPlantData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              tabIndex={1}
              placeholder="<All>"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
      <>
        <ActionBar
          mode={mode}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setMode={setMode}
          setMsgError={setMsgError}
          setMsgAlert={setMsgAlert}
          setOnSearch={setOnSearch}
          setRowModesModel={setRowModesModel}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          rowModesModel={rowModesModel}
          handleSearch={handleSearch}
          handleClear={handleClear}
          handleDownloadExcel={handleDownloadExcel}
          handleDeleteClick={handleDeleteClick}
          handleAdd={handleAddClick}
          handleCopy={handleCopy}
          firstField={"select_countryCd"}
          functionId={"WDN91030"}
          tabIndex={1}
          tabIndexNext={2}
        />
        <Box sx={{ padding: "1rem" }}>
          <Grid>
            {onSearch && (
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={null}
                paginateTabIndex={1}
                columnVisibilityModel={{
                  possibleStatusId: false,
                }}
                slotProps={{
                  baseCheckbox: {
                    tabIndex: 1,
                  },
                }}
              />
            )}
            <SubmitBar
              mode={mode}
              rows={rows}
              setMode={setMode}
              setRows={setRows}
              setRowModesModel={setRowModesModel}
              setMsgError={setMsgError}
              tabIndex={1}
              rowModesModel={rowModesModel}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              functionId={"WDN91030"}
              routePointCategoryInTable={routePointCategoryInTable}
              setRoutePointCategoryInTable={setRoutePointCategoryInTable}
            />
          </Grid>
        </Box>
      </>
      {/* ModalPossibleStatus */}
      <ModalPossibleStatus
        open={isOpenModalPossible}
        setOpen={setIsOpenModalPossible}
        setSelectedPossible={setSelectedPossible}
        rows={rows}
        setRows={setRows}
        rowNumber={rowNumberPossible}
        mode={mode}
        functionId={"WDN91030"}
      />
    </Fragment>
  );
};

export default RoutePointScreen;
