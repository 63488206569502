import React, { useState, useCallback, useEffect } from "react";
import { Button } from "@mui/material";

const InputButton = ({
  id,
  value,
  variant = "contained",
  color = "primary",
  type = "button",
  disabled = false,
  onClick = undefined,
  size = "medium",
  style = {},
  props,
  minWidth = "100px",
  onKeyDown,
  name,
  noRoleRequired = false,
  tabIndex = -1,
  autoFocus = false,
  marginRight = "8px",
}) => {
  const [hasPermission, setHasPermission] = useState(true);

  return (
    (hasPermission || noRoleRequired) && (
      <Button
        {...props}
        data-testid={id}
        id={id}
        type={type}
        variant={variant}
        color={color}
        disabled={disabled}
        onClick={onClick}
        size={size}
        onKeyDown={onKeyDown}
        style={{
          textTransform: "none",
          marginRight: marginRight,
          minWidth: minWidth,
          ...style,
        }}
        tabIndex={tabIndex}
        autoFocus={autoFocus}>
        {value}
      </Button>
    )
  );
};

export default InputButton;
