import { Grid } from "@mui/material";
import { InputDropDown, InputText } from "components/UI";
import React from "react";
import { ModeAction } from "state/enum";

export default function ContainerCriterial(props) {
  const { mode, searchForm, setSearchForm, apmcData, containerTypeData } = props;
  return (
    <>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>APMC :</strong>
        <InputDropDown
          id="select_apmc"
          value={searchForm.refPlantId}
          onChange={e => {
            const refCompanyId = apmcData.filter(item => item.refPlantId === e.target.value)[0]?.refCompanyId;
            setSearchForm(old => ({
              ...old,
              refCompanyId: refCompanyId,
              refPlantId: e.target.value,
            }));
          }}
          memu={apmcData?.map(val => ({
            key: val.refPlantId,
            value: val.companyAbbrImpExpCd,
          }))}
          placeholder="<All>"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>Container Group Code :</strong>
        <InputText
          fullWidth
          id="input_containerGroupCode"
          value={searchForm.containerGroupCode}
          onChange={e =>
            setSearchForm(old => ({
              ...old,
              containerGroupCode: e.target.value?.toUpperCase(),
            }))
          }
          maxLength={10}
          regularExp={/^[A-Za-z0-9\s]*$/}
        />
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>Container Type: </strong>
        <InputDropDown
          id="select_containerType"
          value={searchForm.containerType}
          onChange={e =>
            setSearchForm(old => ({
              ...old,
              containerType: e.target.value,
            }))
          }
          memu={containerTypeData?.map(val => ({
            key: val.containerType,
            value: val.containerType,
          }))}
          disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          placeholder="<All>"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={1} />
    </>
  );
}
