import { Box, Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_DAILY_INVENTORY } from "pages/Report/constants/constants";
import React, { Fragment, useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useExcelDownloadOnlineMutation,
  useReportCommonGetCountryListQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeCategoryQuery,
  useReportCommonGetWarehouseListQuery,
  useReportCommonRPackageCategoryQuery,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseErrors } from "utils";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";

export default function DailyInventoryManagementReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_DAILY_INVENTORY);
  const userInfo = useSelector(state => state.auth.user);
  const { data: countryList } = useReportCommonGetCountryListQuery(userInfo.country, userInfo.companyAbbr);
  const { data: warehouseList } = useReportCommonGetWarehouseListQuery(form?.countryId, userInfo.companyAbbr);

  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgCategoryList } = useReportCommonRPackageCategoryQuery();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeCategoryQuery(
    form?.rPkgTypeOwner,
    form?.rPkgTypeCategory
  );

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      setDisabledSubmitBtn(true);
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userInfo.userName,
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        userCompAbbr: userInfo.companyAbbr,
        country: form?.countryId,
        warehouse: form?.warehouseId,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        month: form?.month,
        rPackageCategory: form?.rPkgTypeCategory,
      };
      const pathReport = "generate-daily-inventory-management-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      waitAndDownloadExcel(420, response.data, props.setMsgError, stopLoading);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      const msgError = responseErrors(error);
      props.setMsgError(msgError);
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-country").focus();
  }, []);

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={5} style={{ display: "flex", paddingTop: "8px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "Daily Inventory Management Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "8px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Country : </strong>
          <DropDown
            id="select-country"
            fullWidth
            required
            value={form?.countryId}
            defaultValue=""
            placeholder="<Select>"
            menu={countryList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={async e => {
              setForm(old => ({
                ...old,
                countryId: e.target.value,
                warehouseId: "",
              }));
            }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "8px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Warehouse : </strong>
          <DropDown
            id="select-warehouse"
            fullWidth
            required
            value={form?.warehouseId}
            defaultValue=""
            placeholder="<Select>"
            menu={warehouseList?.map(val => ({
              key: val.label,
              value: val.label,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                warehouseId: e.target.value,
              }))
            }
            disabled={!form?.countryId}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "5px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Month : </strong>
          <DatePickerField
            views={["month", "year"]}
            format="MM/YYYY"
            mask="MM/YYYY"
            fullWidth={true}
            value={form?.month}
            onChange={e =>
              setForm(old => ({
                ...old,
                month: e,
              }))
            }
            required
            autoFocus={false}
          />
        </Grid>
        <Grid item xs={1} style={{ margin: "auto 0px" }}>
          (MM/YYYY)
        </Grid>
        <Grid item xs={5} style={{ display: "flex", paddingTop: "8px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
                rPkgTypeCategory: "",
                rPkgType: "",
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "8px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*R-Package Category : </strong>
          <DropDown
            id="select-rpackage-category"
            fullWidth
            required
            value={form?.rPkgTypeCategory}
            defaultValue=""
            placeholder="<Select>"
            rest={{
              label: "label",
            }}
            menu={rPkgCategoryList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeCategory: e.target.value,
                rPkgType: "",
              }))
            }
            disabled={!form.rPkgTypeOwner}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "8px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Type : </strong>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgTypeList?.map(val => ({
              key: val.rPkgType,
              value: val.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form.rPkgTypeCategory}
          />
        </Grid>
      </Grid>
      <Box style={{ display: "flex", paddingTop: "8px" }}>
        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_DAILY_INVENTORY}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            props.setMsgAlert([]);
            props.setMsgError([]);
          }}
          firstField={"select-country"}
        />
      </Box>
    </Fragment>
  );
}
