import React, {useState, useEffect} from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel, styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useGridApiContext } from "@mui/x-data-grid";
import { isNull } from "lodash";
import usePreventScrollOnFocus from "../PreventScrollOnFocus"

const StyledSelectField = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    padding: "5px 0px 5px 12px",
    color: `${theme.palette.primary.dark}`,
  },
  "& :focus-within": {
    border: `1.5px solid ${theme.palette.primary.dark} !important`,
    color: `${theme.palette.primary.dark}`,
  },
}));

export default function EditableDropDown({
  value,
  menu,
  field,
  id,
  disabled = false,
  className,
  required,
  placeholder,
  defaultValue = "",
  isShow = true,
  autoFocus = false,
  onChange = null,
  onSearchAdd = false,
  tabIndex = -1
}) {
  const apiRef = useGridApiContext();
  const ref = React.useRef();
  const preventScrollOnFocus = usePreventScrollOnFocus();

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight <= 740);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerHeight <= 740);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleValueChange = (event) => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id: id, field: field, value: newValue });

    if (!isNull(onChange)) {
      onChange(event);
    }
  };

  const normalizedValue = isNull(value) ? "" : value;

  return (
    isShow && (
      <div style={{ margin: "0 5px", width: "100%" }}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel
            id="test-select-label"
            shrink={false}
            size="small"
            variant="outlined"
            style={{
              left: "5%",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {normalizedValue === "" && placeholder}
          </InputLabel>
          <Select
            data-testid={`${id}-${field}`}
            ref={ref}
            sx={{
              minWidth: "100%",
              backgroundColor: "white",
              "&:focus": {
                border: "1px solid #000 !important",
              },
            }}
            // size="small"
            value={normalizedValue}
            autoFocus={autoFocus}
            onChange={handleValueChange}
            disabled={disabled}
            className={className}
            required={required}
            MenuProps={{
              PaperProps: {
                style: {
                  overflowY: "scroll",
                  maxHeight: "60%",
                  transform: onSearchAdd ? isSmallScreen ? "translateY(50%)" : null : null,
                },
              },
              onFocus: preventScrollOnFocus,
              disableScrollLock: true,
            }}
            input={<StyledSelectField inputProps={{ tabIndex }} />}
          >
            <MenuItem value={defaultValue}>
              <span>{placeholder}</span>
            </MenuItem>
            {menu &&
              menu.map((val, index) => (
                <MenuItem key={index} value={val.key}>
                  {val.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    )
  );
}
