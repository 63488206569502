import React from "react";
import { COLUMN_NAME } from "../constants/table.constant";
import { REPORT_STATUS } from "../constants/onDemand.constant";
import { API_NAME_ON_DEMAND_EXCEL } from "shared/constants";
import { ModeAction } from "state/enum";
import { Link } from "@mui/material";

export default function useColumn(rows, mode, handleDownload) {
  return [
    // Col 1
    {
      field: API_NAME_ON_DEMAND_EXCEL.ROW_NUMBER,
      headerName: COLUMN_NAME.NO,
      headerAlign: "center",
      align: "right",
      minWidth: 50,
      flex: 0.1,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params?.value;
      },
    },
    // Col 2
    {
      field: API_NAME_ON_DEMAND_EXCEL.DOC_ID,
      headerName: COLUMN_NAME.DOC_NO,
      headerAlign: "center",
      align: "center",
      minWidth: 110,
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    // Col 3
    {
      field: API_NAME_ON_DEMAND_EXCEL.REPORT_NAME,
      headerName: COLUMN_NAME.REPORT_NAME,
      headerAlign: "center",
      align: "left",
      minWidth: 300,
      flex: 0.3,
      sortable: false,
      editable: false,
    },
    // Col 4
    {
      field: API_NAME_ON_DEMAND_EXCEL.NO_OF_ZIP_FILE,
      headerName: COLUMN_NAME.NO_OF_ZIP_FILE,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col 5
    {
      field: API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE,
      headerName: COLUMN_NAME.REQUEST_DATE,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    // Col 6
    {
      field: API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS,
      headerName: COLUMN_NAME.REPORT_STATUS,
      headerAlign: "center",
      align: "left",
      minWidth: 120,
      flex: 0.2,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.value === REPORT_STATUS.DOWNLOAD) {
          return (
            <Link
              href="#"
              tabIndex={1}
              style={{ color: "blue", textDecorationColor: "blue" }}
              onClick={e => handleDownload(e, params.row)}>
              {params?.value}
            </Link>
          );
        } else {
          return params.value;
        }
      },
    },
    // Col 7
    {
      field: API_NAME_ON_DEMAND_EXCEL.GENERATED_BY,
      headerName: COLUMN_NAME.GENERATED_BY,
      headerAlign: "center",
      align: "left",
      minWidth: 275,
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    {
      field: API_NAME_ON_DEMAND_EXCEL.FILE_NO,
      editable: false,
    },
    {
      field: API_NAME_ON_DEMAND_EXCEL.FILE_COUNT,
      editable: false,
    },
    {
      field: API_NAME_ON_DEMAND_EXCEL.FILE_NAME,
      editable: false,
    },
    {
      field: API_NAME_ON_DEMAND_EXCEL.FILE_SIZE,
      editable: false,
    },
    {
      field: API_NAME_ON_DEMAND_EXCEL.FILE_STATUS,
      editable: false,
    },
    {
      field: API_NAME_ON_DEMAND_EXCEL.OVERRIDE_PATH,
      editable: false,
    },
    {
      field: API_NAME_ON_DEMAND_EXCEL.STATUS_ID,
      editable: false,
    },
  ];
}
