import React from "react";
import PropTypes from "prop-types";
import { useGridApiContext } from "@mui/x-data-grid";
import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme, error, props }) => ({
  "& .MuiInput-root": { backgroundColor: theme.palette.primary.light, borderRadius: ".3rem" },
  "& .MuiInput-root.Mui-focused": {
    borderRadius: ".3rem",
    color: `${theme.palette.primary.dark}`,
    border: `2px solid black`,
  },
  "& .MuiInputBase-input.MuiInput-input": { padding: "4px 8px!important" },
  "& .MuiInputBase-root": { border: `2px solid black` },
}));

EditableTextField.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  field: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  regularExp: PropTypes.instanceOf(RegExp),
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  onUpperCase: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  textAlign: PropTypes.string,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default function EditableTextField(props) {
  const {
    value,
    setValue = undefined,
    field,
    id,
    style = {},
    placeholder = "",
    type = "text",
    regularExp,
    maxLength,
    autoFocus = false,
    required,
    onUpperCase = false,
    disabled = false,
    fullWidth = false,
    multiline = false,
    textAlign = "start",
    onKeyDown,
    tabIndex = -1,
  } = props;

  const apiRef = useGridApiContext();

  const handleValueChange = event => {
    const newValue = event.target.value; // The new value entered by the user
    if (regularExp && !regularExp.test(newValue)) return;

    const updatedValue = onUpperCase ? newValue?.toUpperCase() : newValue;
    apiRef.current.setEditCellValue({ id, field, value: updatedValue });
    if (setValue) setValue(updatedValue, id);
  };

  return (
    <div style={{ margin: "0 5px", display: "grid", width: "100%" }}>
      <StyledTextField
        data-testid={`${id}-${field}`}
        margin="dense"
        size="small"
        variant="standard"
        inputProps={{ maxLength: maxLength, style: { textAlign: textAlign }, tabIndex }}
        InputProps={{ sx: { height: "auto", ...style }, disableUnderline: true, required: required }}
        multiline={multiline}
        autoFocus={autoFocus}
        type={type}
        fullWidth={fullWidth}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        value={value}
        onChange={handleValueChange}
        required={required}
        disabled={disabled}
      />
    </div>
  );
}
