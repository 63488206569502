export const API_WAREHOUSE_SUMMARY = {
  ROW_NUMBER: "rowNumber",
  R_PKG_OWNER: "rPkgOwner",
  WARE_HOUSE_NAME: "wareHouseName",
  R_PKG_TYPE: "rPkgType",
  // Graph
  WH_IMP_EXP_CODE: "whImpExpCd",
  FORECAST_DATE: "forecastDt",
  OVERFLOW_STK_QTY: "overFlowStkQty",
  ENDING_BALANCE: "endingBalance",
  SAFETY_STK_QTY: "safetyStkQty",
  //Table
  SHIPMENT_CODE: "shipmentCd",
  CONTAINER_NO: "containerNo",
  ALLOCATE_STATUS: "allocateStatus",
  ALLOCATE_DATE: "allocateDt",
  READY_FOR_DATE: "readyForDt",
  PLAN_RCV_DATE: "planRcvDt",
  PLANT_ID: "plantId",
  R_PKG_QTY: "qty",
  UPDATE_DATE: "updateDt",

  WAREHOUSE_ID: "whCompanyAbbrId",
  WAREHOUSE_ABBR: "whCompanyAbbr",
};
