import { useCustomQuery } from "shared/services/base.service";
import _ from "lodash";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;

export const useGetMasterType = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/COMMON/STK_INDICATOR_TYPE/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetMasterCountry = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/COMMON/COUNTRY_CD/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRoutePointCountryDropDown = ({ cd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/safety-overflow-stock/country/${cd}/COMMON/COUNTRY_CD`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: data => data.result,
  });
};

export const useGetRoutePointCategoryDropDown = ({ cd, dataOwner }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/safety-overflow-stock/route-point/${cd}/COMMON/ROUTE_POINT_CATEGORY`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: data => data.result,
  });
};

export const useGetRoutePointNameDropDown = ({ cd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/safety-overflow-stock/route-point/name/${cd}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: data => data.result,
  });
};

export const useGetRpkgOwnerDropDown = ({ cd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/safety-overflow-stock/r-package/owner/${cd}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: data => data.result,
  });
};

export const useGetRpkgTypeDropDown = ({ cd = "O", rPkgOwnerCompAbbr = "TMATH" }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/safety-overflow-stock/r-package/name/${cd}/${rPkgOwnerCompAbbr}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd) && !_.isEmpty(rPkgOwnerCompAbbr),
    select: data => data.result,
  });
};

export const useGetRpkgOwnerTableDropDown = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/safety-overflow-stock/r-package/company`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRpkgTypeTableDropDown = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/safety-overflow-stock/r-package/detail`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRoutePointNameTableDropDown = ({ cd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/safety-overflow-stock/route-point/detail/${cd}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: data => data.result,
  });
};
