import React, { useEffect, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel, styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";

const StyledSelectField = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    padding: "4px 8px",
    "&:focus": {
      border: `1.5px solid black !important`,
      borderRadius: ".3rem",
      color: "#000",
    },
  },
}));

const InputDropDown = ({
  id,
  value,
  name = "",
  memu,
  onChange,
  disabled = false,
  bgColor = "white",
  className = "",
  required,
  placeholder = "<Select>",
  defaultValue = "",
  focus = false,
  tabIndex = -1,
  rest,
}) => {
  const selectRef = useRef(null);

  const handleKeyDown = e => {
    if (!memu || !Array.isArray(memu)) return;

    if (!selectRef.current?.contains(document.activeElement)) return;

    const fullList = [{ key: "", value: placeholder }, ...memu];

    const currentIndex = fullList.findIndex(item => item.key === value);

    if (e.key === "ArrowRight") {
      const nextIndex = currentIndex + 1;
      if (nextIndex < fullList.length) {
        onChange({ target: { value: fullList[nextIndex].key } });
      }
    }

    if (e.key === "ArrowLeft") {
      const prevIndex = currentIndex - 1;
      if (prevIndex >= 0) {
        onChange({ target: { value: fullList[prevIndex].key } });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [value, memu]);

  return (
    <FormControl style={{ width: "100%", margin: "4px 0px" }} ref={selectRef}>
      <InputLabel
        id="test-select-label"
        shrink={false}
        variant="outlined"
        style={{
          top: "50%",
          left: "5%",
          transform: "translateY(-50%)",
        }}>
        {!memu ? placeholder : memu && !memu?.map(item => item.key).includes(value) && placeholder}
      </InputLabel>
      <Select
        data-testid={id}
        id={id}
        name={name}
        sx={{ minWidth: "100%", background: bgColor }}
        value={value}
        label=""
        onChange={onChange}
        disabled={disabled}
        className={className}
        required={required}
        placeholder={placeholder}
        autoFocus={focus}
        style={{
          fontSize: "12px",
          border: required ? "1.7px solid" : "1px solid",
          borderRadius: "4px",
        }}
        input={
          <StyledSelectField
            inputProps={{
              tabIndex,
              disabled,
            }}
          />
        }
        {...rest}>
        <MenuItem value={defaultValue} disabled={disabled}>
          <span>{placeholder}</span>
        </MenuItem>
        {memu &&
          memu.map((val, index) => (
            <MenuItem key={index} value={val.key} disabled={disabled}>
              {val.value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default InputDropDown;
