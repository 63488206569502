export const WEB_FUNCTION_ID = {
  FORECAST_MONTHLY: "WDN92010",
  FORECAST_APMC: "WDN92020",
  FORECAST_SHIPMENT: "WDN920K0",
  HANDHELD_SEND_DATA: "WDN97050",
  STD_MOVEMENT: "BDN93060",
  CONTAINER_UPDATE: "BDN930L0",
  REPROCESS_ROEM: "WDN98150_R",
  REPROCESS_SCAN: "WDN98150_S",
};
