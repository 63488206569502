import { Grid } from "@mui/material";
import DataTable from "pages/DataTable";
import React from "react";
import { useContainerGroupSearchMutation } from "shared/services/master";
import { responseErrors } from "utils";

export default function ContainerTable(props) {
  const {
    onSearch,
    searchForm,
    rows,
    setRows,
    mode,
    setMode,
    rowSelectionModel,
    setRowSelectionModel,
    rowModesModel,
    setRowModesModel,
    columns,
    pagination,
    setPagination,
    pageNumber,
    setPageNumber,
    setMsgError,
  } = props;

  const searchDataAsync = useContainerGroupSearchMutation();

  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const searchData = await searchDataAsync({
        ...searchForm,
        pageNumber: value,
        // rowsPerPage: 10,
      });

      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: index + 1,
          ...item,
        })) ?? [];

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);

      setRows(data);
    } catch (error) {
      setMsgError(responseErrors(error));
    }
  };
  return (
    <>
      <Grid>
        {onSearch && rows.length !== 0 && (
          <DataTable
            mode={mode}
            onSearch={onSearch}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            setMode={setMode}
            rows={rows}
            rowModesModel={rowModesModel}
            columns={columns}
            setRowModesModel={setRowModesModel}
            processRowUpdate={null}
            pagination={pagination}
            pageNumber={pageNumber}
            handleChangePagination={handleChangePagination}
            columnGroupingModel={null}
            columnVisibilityModel={{
              currencys: false,
              rPkgTypeId: false,
            }}
          />
        )}
        {/* <SubmitBar
          mode={mode}
          rows={rows}
          setMode={setMode}
          setRows={setRows}
          setRowModesModel={setRowModesModel}
          setMsgError={setMsgError}
          rowModesModel={rowModesModel}
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          functionId={"WDN91090"}
        /> */}
      </Grid>
    </>
  );
}
