import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { InputButton } from "components/UI";
//UTIL
import { findObject, responseErrors, messageDisplay } from "utils";
import { BUTTON_VALUE } from "shared/constants";
// Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { ModeAction } from "state/enum";
import { simulateGraph, transformObjectGraph } from "../hooks/format";
//Constant
import { MSG_TYPE } from "state/enum";
//Service
import { useWareHouseSummarySearchGraphMutation } from "shared/services/inventory";
import { deepClone } from "@mui/x-data-grid/utils/utils";

export default function ButtonGraph(props) {
  const {
    form,
    defaultGraphData,
    setDefaultGraph,
    graphData,
    setGraph,
    setMode,
    setOnSearch,
    setOpenGraph,
    defaultRows,
    setRows,
    getWarehouse,
    rPkg,
    isLoadingView,
    loading: { startLoading, stopLoading },
    stateBtn: { simulateBtn, cancelBtn, setGeneratedGraph, generatedGraph },
    setMsg: { setMsgAlert, setMsgError },
    dataList: { rPackageOwnerList, rPackageTypeList },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    rowsChanged,
    functionId,
  } = props;
  const confirmDialogCtx = useConfirmDialogContext();
  const searchGraph = useWareHouseSummarySearchGraphMutation();
  const handleSimulate = useCallback(async () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      console.log("defaultGraphData >> ", defaultGraphData);
      console.log("graphData >> ", graphData);

      const checkAllocateDtNull = rowsChanged.filter(row => row.allocateDt === null);
      if (checkAllocateDtNull.length > 0) {
        let msg;
        let isValid = true;
        //! validate required field
        if (checkAllocateDtNull.length > 0) {
          checkAllocateDtNull.forEach(row => {
            msg = messageDisplay({
              type: MSG_TYPE.ERROR,
              code: "MDN96008AERR",
              msg: [`Row ${row?.rowNumber}`, `Container ${row?.containerNo}`, "Allocation Date and Warehouse"],
            });
            isValid = false;
            setMsgError(prev => [...prev, [msg]]);
            window.scroll(0, 0);
          });
        }
        if (!isValid) {
          return;
        }
      }

      if (defaultGraphData.length > 0) {
        //const rowsWithoutAllocateDt = rowSelectionModel.filter(row => row.allocateDt === null);
        const rowsWithAllocateDt = rowSelectionModel.filter(row => row.allocateDt !== null);
        const simulateData = simulateGraph(rowsWithAllocateDt, defaultGraphData, rPkg);
        setGraph(simulateData);
        setOpenGraph(true);
        return;
      }

      const bodySearch = {
        dataOwner: form?.dataOwner,
        rPkgOwner: !form?.rPackageOwnerId
          ? ""
          : findObject({
              data: rPackageOwnerList,
              value: form?.rPackageOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            }),
        rPkgType: !form?.rPackageTypeId
          ? ""
          : findObject({
              data: rPackageTypeList,
              value: form?.rPackageTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }),
        location: getWarehouse(form?.wareHouseId),
      };
      startLoading();
      const data = await searchGraph(bodySearch);
      stopLoading();

      if (data?.result?.data?.length <= 0) {
        setGraph([]);
        setDefaultGraph([]);
        setOpenGraph(false);
        setOnSearch(true);
      }
      const transform = transformObjectGraph(data?.result);
      setGraph(transform);
      setDefaultGraph(transform);
      setOpenGraph(true);
      setOnSearch(true);

      const rowsWithAllocateDt = rowSelectionModel.filter(row => row.allocateDt !== null);
      const simulateData = simulateGraph(rowsWithAllocateDt, transform, rPkg);

      setGraph(simulateData);
      setOpenGraph(true);
      return;
    } catch (error) {
      console.error(error);
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  }, [defaultGraphData, rPkg, rowSelectionModel, setGraph, setMsgAlert, setMsgError, setOpenGraph]);

  const handleCancel = async () => {
    try {
      //reset
      setMsgAlert([]);
      setMsgError([]);
      const allocateConfirm = await confirmDialogCtx.success({
        type: "INVENTORY_CONFIRM_CANCEL",
      });
      if (!allocateConfirm) {
        return;
      }

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRowModesModel({});
      setRows(deepClone(defaultRows));
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  return (
    <Box>
      <InputButton
        id="button-simulate"
        value={"Simulate"}
        disabled={simulateBtn || isLoadingView || generatedGraph}
        onClick={handleSimulate}
        name={`${functionId}Simulate`}
        tabIndex={1}
      />
      <InputButton
        id="button-cancel"
        value={BUTTON_VALUE.CANCEL}
        disabled={cancelBtn}
        onClick={handleCancel}
        name={`${functionId}Cancel`}
        tabIndex={1}
      />
    </Box>
  );
}
