import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, IconButton, Popover, TextField, styled } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon, DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputMask from "react-input-mask";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { getLocalDate, getLocalDateForValidate } from "utils/init-config-date";
import dayjs from "dayjs";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    backgroundColor: theme.palette.primary.light,
    textAlign: "left",
    padding: "4px 8px",
    fontSize: "12px",
  },
  "& .MuiInput-root:focus": {
    border: `2px solid black !important`,
    borderRadius: ".3rem",
    color: "#000",
  },
  "& .MuiOutlinedInput-root:focus": {
    border: `2px solid black !important`,
    borderRadius: ".3rem",
    color: "#000",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `2px solid black !important`,
  },
}));

const StyledDateCalendar = styled(DateCalendar)(({ theme, error }) => ({
  "& .MuiButtonBase-root": {
    "&.MuiPickersDay-root:hover": {
      backgroundColor: `${theme.palette.secondary.medium}`,
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${theme.palette.tertiary.chip}`,
    color: "#000000",
  },
}));

const DatePickerField = ({
  id,
  mask = "DD/MM/YYYY",
  onChange,
  value,
  required,
  fullWidth = false,
  disabled = false,
  autoFocus = false,
  format = "DD/MM/YYYY",
  views = ["day", "month", "year"],
  tabIndex = -1,
}) => {
  const priority = {
    day: 1,
    month: 2,
    year: 3,
  };
  const state = views.sort((a, b) => priority[a] - priority[b]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [select, setSelect] = useState(getLocalDate());
  const [date, setDate] = useState(value);
  const [currentView, setCurrentView] = useState();

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setCurrentView(state[0]);
  };

  const handleClose = useCallback((e, reason) => {
    if (reason === "backdropClick") {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(null);
  }, []);

  const handleSelect = date => {
    try {
      setSelect(date);
      setDate(getLocalDate(date).format(format));
      onChange(getLocalDate(date).format(format));

      if (currentView === state[0]) {
        handleClose();
      }

      return;
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = event => {
    const newValue = event.target.value;
    if (!newValue) {
      setDate("");
      setSelect(getLocalDate());
      onChange("");
      return;
    }
    setDate(newValue);
    onChange(newValue);
    if (!getLocalDateForValidate(newValue, format, true)) {
      setSelect(getLocalDate());
      return;
    }
    setSelect(getLocalDate(newValue, format));
  };

  const formattedValue = date => {
    try {
      if (!date) {
        return "";
      }

      if (typeof date === "string") {
        return getLocalDate(date, format);
      }

      return date;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handlerArrow = (props, state) => {
    const date = dayjs(select).add(state === "left" ? 1 : -1, "month");
    props.onMonthChange(date, state);
    setSelect(date);
  };

  const onMonthChange = () => {
    if (views.includes("day")) {
      setCurrentView("day");
    }
  };

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "row wrap",
        alignItems: "center",
      }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputMask
          id={id}
          data-testid={id}
          mask={mask}
          formatChars={{
            Y: "[0-9]",
            M: "[0-9]",
            D: "[0-9]",
          }}
          value={date}
          required={required}
          disabled={disabled}
          fullWidth={fullWidth}
          onChange={handleChange}
          placeholder={format}
          maskChar={null}
          alwaysShowMask={false}
          autoFocus={autoFocus}>
          {inputProps => <StyledTextField fullWidth disabled={disabled} inputProps={{ ...inputProps, tabIndex }} />}
        </InputMask>
        <IconButton onClick={handleClick}>
          <InsertInvitationIcon sx={{ color: "#58595B" }} />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          o
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <StyledDateCalendar
            views={views}
            value={formattedValue(select)}
            onChange={handleSelect}
            onViewChange={v => setCurrentView(v)}
            onYearChange={() => setCurrentView("month")}
            onMonthChange={onMonthChange}
            openTo={currentView}
            slots={{
              calendarHeader: props => {
                return (
                  <Box sx={{ pl: "24px", pr: "24px", mt: "16px", mb: "8px", display: "flex", alignItems: "center" }}>
                    <Button
                      sx={{
                        "font-size": "14px",
                        color: "#000000",
                      }}
                      onClick={() => setCurrentView("year")}>
                      {select.year()}
                    </Button>
                    <Button
                      sx={{
                        "font-size": "14px",
                        color: "#000000",
                        "text-transform": "capitalize",
                      }}
                      onClick={() => setCurrentView("month")}>
                      {dayjs().month(select.month()).format("MMMM")}
                    </Button>

                    <Box sx={{ ml: "auto" }}>
                      <ArrowLeftIcon
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => handlerArrow(props, "right")}
                      />
                      <ArrowRightIcon
                        sx={{
                          cursor: "pointer",
                          ml: "2rem",
                        }}
                        onClick={() => handlerArrow(props, "left")}
                      />
                    </Box>
                  </Box>
                );
              },
            }}
          />
        </Popover>
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerField;
