import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userProfile } from "constant";
import { Box, Dialog, DialogContent, DialogContentText } from "@mui/material";
import ConfirmDialog from "components/UI/ConfirmDialog";
import { formatCurrency, message } from "utils";
import "./ModalPreviewImage.css";
import { AlertMessage } from "components/UI";
import { getLocalDate } from "utils/init-config-date";
import { useRPkgTypeMasterDownloadImageMutation } from "shared/services/master";

const ModalPreviewImage = ({ open = true, setOpen, row, type, functionId }) => {
  const [isConfirmCloseModal, setConfirmCloseModal] = useState(false);
  const [imageBlob, setImageBlob] = useState(null);

  const [msgError, setMsgError] = useState([]);
  const downloadImage = useRPkgTypeMasterDownloadImageMutation();
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const { data } = await downloadImage(type === "asb" ? row.pic : row.dsbPic);
        setImageBlob(data);
      } catch (error) {
        if (error?.response?.status === 404) {
          setMsgError(["image not found"]);
        } else {
          setMsgError([error.message]);
        }
        console.error("Error fetching image:", error);
      }
    };

    if (open) {
      fetchImage();
    }
  }, [row, type, open]);

  const handleClose = () => {
    setConfirmCloseModal(true);
  };

  const handleCloseConfirm = () => {
    setMsgError([]);
    setImageBlob(null);
    setOpen(false);
  };

  const user = useSelector(state => state.auth.user);
  const USER = `User: ${user?.displayName ?? userProfile.displayName}`;

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            <Box className="area-row-header">
              <div className="btn-close" onClick={handleClose}>
                Close
              </div>
              <div>{USER}</div>
            </Box>
            <Box className="area-row-header">
              <div>WDN91011 : R-Package Type Master Maintenance Picture Sub Screen</div>
              <div>{getLocalDate().format("DD MMM YYYY HH:mm")}</div>
            </Box>
            <AlertMessage type={"warning"} message={msgError} />
          </DialogContentText>
          <Box className="box">
            <div className="sub-box">
              {/* <img src="" className="preview" /> */}
              {imageBlob && <img src={URL.createObjectURL(imageBlob)} className="preview" alt="" />}
            </div>
            <div className="sub-box">
              <table>
                <tr>
                  <td className="bg-light-gray" width="40%">
                    R-Package Category
                  </td>
                  <td>{row && row.value}</td>
                </tr>
                <tr>
                  <td className="bg-light-gray">R-Package Type</td>
                  <td>{row && row.rPkgType}</td>
                </tr>
                <tr>
                  <td className="bg-light-gray">R-Package Description</td>
                  <td>{row && row.rPkgDesc}</td>
                </tr>
                <tr>
                  <td className="bg-light-gray">Weight (kg)</td>
                  <td>{row && formatCurrency({ number: row.weight, digits: 3 })}</td>
                </tr>
                <tr>
                  <td className="bg-light-gray">Length (mm)</td>
                  <td>{row && (type === "asb" ? row.asbLength : row.dsbLength)}</td>
                </tr>
                <tr>
                  <td className="bg-light-gray">Width (mm)</td>
                  <td>{row && (type === "asb" ? row.asbWidth : row.dsbWidth)}</td>
                </tr>
                <tr>
                  <td className="bg-light-gray">Height (mm)</td>
                  <td>{row && (type === "asb" ? row.asbHeight : row.dsbHeight)}</td>
                </tr>
              </table>
            </div>
          </Box>
          <Box
            style={{
              background: "#EB0A1E",
              textAlign: "center",
              color: "#fff",
              padding: "4px 0",
              marginTop: "24px",
            }}>
            &copy; 2024 -TOYOTA MOTOR ASIA (THAILAND) CO., LTD. All Rights Reserved
          </Box>
        </DialogContent>
      </Dialog>

      {/* ConfirmDialog Close Modal */}
      <ConfirmDialog
        open={isConfirmCloseModal}
        onClose={() => setConfirmCloseModal(false)}
        onConfirm={() => {
          setConfirmCloseModal(false);
          handleCloseConfirm();
        }}
        message={message({ type: "closeScreen" })}
        noRoleRequired={true}
      />
    </React.Fragment>
  );
};

export default ModalPreviewImage;
