import React, { useCallback, useState, useEffect } from "react";
import { Box, Button, IconButton, Popover, TextField, styled } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon, DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputMask from "react-input-mask";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { useGridApiContext } from "@mui/x-data-grid";
import { isNull } from "lodash";
import { getLocalDate, getLocalDateForValidate } from "utils/init-config-date";
import dayjs from "dayjs";
import { isBlankOrNull } from "utils";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `2px solid black`,
    borderRadius: ".3rem",
    backgroundColor: theme.palette.primary.light,
    textAlign: "left",
    padding: "4px 8px",
  },
  "& .MuiInput-root:focus": {
    border: `2px solid black !important`,
    borderRadius: ".3rem",
    color: "#000",
  },
}));

const StyledDateCalendar = styled(DateCalendar)(({ theme }) => ({
  "& .MuiButtonBase-root": {
    "&.MuiPickersDay-root:hover": {
      backgroundColor: `${theme.palette.secondary.medium}`,
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${theme.palette.tertiary.chip}`,
    color: "#000000",
  },
}));

export default function EditableDatePicker({
  value = null,
  field,
  id,
  format = "DD/MM/YYYY",
  mask = "DD/MM/YYYY",
  views = ["day", "month", "year"],
  // fullWidth,
  // maxDate,
  // minDate,
  disabled = false,
  autoFocus = false,
  required = false,
  disablePast = false,
  disableKey = false,
  onChange = null,
  tabIndex = -1,
}) {
  const priority = {
    day: 1,
    month: 2,
    year: 3,
  };
  const state = views.sort((a, b) => priority[a] - priority[b]);

  const [select, setSelect] = React.useState(getLocalDate());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [date, setDate] = React.useState(value);
  const [currentView, setCurrentView] = useState();

  const open = Boolean(anchorEl);
  const apiRef = useGridApiContext();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setCurrentView(state[0]);
  };

  const handleClose = useCallback((e, reason) => {
    if (reason === "backdropClick") {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(null);
  }, []);

  const handleSelect = value => {
    try {
      setSelect(value);
      setDate(getLocalDate(value).format(format));
      apiRef.current.setEditCellValue({
        id: id,
        field: field,
        value: getLocalDate(value).format(format),
      });
      if (!isNull(onChange)) {
        onChange(getLocalDate(value).format(format));
      }

      if (currentView === state[0]) {
        handleClose();
      }

      return;
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = event => {
    try {
      const newValue = event.target.value;

      if (isBlankOrNull(newValue)) {
        setDate("");
        setSelect(getLocalDate());
        apiRef.current.setEditCellValue({
          id: id,
          field: field,
          value: "",
        });
        return;
      }
      apiRef.current.setEditCellValue({
        id: id,
        field: field,
        value: newValue,
      });
      setDate(newValue);
      if (!getLocalDateForValidate(newValue, format, true)) {
        setSelect(getLocalDate());
        return;
      }
      setSelect(getLocalDate(newValue, format));
      return;
    } catch (error) {
      return false;
    }
  };

  const formattedValue = date => {
    try {
      // date equal null
      if (!date) {
        return "";
      }
      if (typeof date === "string") {
        return getLocalDate(date, format);
      }

      return date;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const onMonthChange = () => {
    if (views.includes("day")) {
      setCurrentView("day");
    }
  };

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handlerArrow = (props, state) => {
    const date = dayjs(select).add(state === "left" ? 1 : -1, "month");
    props.onMonthChange(date, state);
    setSelect(date);
  };

  return (
    <Box
      width={"90%"}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "row wrap",
        alignItems: "center",
      }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputMask
          data-testid={`${id}-${field}`}
          mask={mask}
          formatChars={{
            Y: "[0-9]",
            M: "[0-9]",
            D: "[0-9]",
          }}
          value={date}
          required={required}
          disabled={disabled || disableKey}
          onChange={handleChange}
          maskChar={null}
          alwaysShowMask={false}
          autoFocus={autoFocus}
          placeholder={format}>
          {inputProps => <StyledTextField disabled={disabled || disableKey} inputProps={{ ...inputProps, tabIndex }} />}
        </InputMask>
        <IconButton onClick={handleClick}>
          <InsertInvitationIcon sx={{ color: "#58595B" }} />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <StyledDateCalendar
            views={views}
            value={formattedValue(select)}
            onChange={handleSelect}
            onViewChange={v => setCurrentView(v)}
            onYearChange={() => setCurrentView("month")}
            onMonthChange={onMonthChange}
            openTo={currentView}
            disablePast={disablePast}
            slots={{
              calendarHeader: props => {
                return (
                  <Box sx={{ pl: "24px", pr: "24px", mt: "16px", mb: "8px", display: "flex", alignItems: "center" }}>
                    <Button
                      sx={{
                        "font-size": "14px",
                        color: "#000000",
                      }}
                      onClick={() => setCurrentView("year")}>
                      {select.year()}
                    </Button>
                    <Button
                      sx={{
                        "font-size": "14px",
                        color: "#000000",
                        "text-transform": "capitalize",
                      }}
                      onClick={() => setCurrentView("month")}>
                      {dayjs().month(select.month()).format("MMMM")}
                    </Button>

                    <Box sx={{ ml: "auto" }}>
                      <ArrowLeftIcon
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => handlerArrow(props, "right")}
                      />
                      <ArrowRightIcon
                        sx={{
                          cursor: "pointer",
                          ml: "2rem",
                        }}
                        onClick={() => handlerArrow(props, "left")}
                      />
                    </Box>
                  </Box>
                );
              },
            }}
          />
        </Popover>
      </LocalizationProvider>
    </Box>
  );
}
