import React from "react";
import { DataGridTable } from "components/UI";
import { responseErrors } from "utils";
import { API_NAME_ON_DEMAND_EXCEL, PAGINATION } from "shared/constants";
export default function TableBar(props) {
  const {
    form,
    mode,
    setMode,
    rows,
    setRows,
    columns,
    setPagination,
    setPageNumber,
    setRowModesModel,
    setMsg: { setMsgAlert, setMsgError },
    rowModesModel,
    rowSelectionModel,
    setRowSelectionModel,
    pagination,
    pageNumber,
    getSearch,
    slotProps,
  } = props;

  const handleChangePagination = async (event, value) => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      setPageNumber(value);
      const body = {
        [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: form?.requestDate,
        [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: form?.reportStatus,
        [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: form?.reportName,
        [API_NAME_ON_DEMAND_EXCEL.USER_ID]: form?.userId,
        [API_NAME_ON_DEMAND_EXCEL.ORDER_BY_DATE]: form?.orderByDate,
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: 10,
      };
      const { searchData, data } = await getSearch(body);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);

      setRows(data);
    } catch (error) {
      console.log(error);
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  return (
    <DataGridTable
      id="table-on-demand-excel"
      mode={mode}
      setMode={setMode}
      rowHeight={50}
      isMultipleSelection={false}
      onCellClick={false}
      rows={rows}
      processRowUpdate={null}
      rowSelection={{
        rowModesModel: rowModesModel,
        setRowModesModel: setRowModesModel,
        rowSelectionModel: rowSelectionModel,
        setRowSelectionModel: setRowSelectionModel,
      }}
      pagination={{
        pagination: pagination,
        pageNumber: pageNumber,
        handleChangePagination: handleChangePagination,
      }}
      paginateTabIndex={1}
      slotProps={slotProps}
      column={{
        columns: columns,
        columnGroupingModel: null,
        columnVisibilityModel: {
          [API_NAME_ON_DEMAND_EXCEL.FILE_NO]: false,
          [API_NAME_ON_DEMAND_EXCEL.FILE_COUNT]: false,
          [API_NAME_ON_DEMAND_EXCEL.FILE_NAME]: false,
          [API_NAME_ON_DEMAND_EXCEL.FILE_SIZE]: false,
          [API_NAME_ON_DEMAND_EXCEL.FILE_STATUS]: false,
          [API_NAME_ON_DEMAND_EXCEL.OVERRIDE_PATH]: false,
          [API_NAME_ON_DEMAND_EXCEL.STATUS_ID]: false,
        },
      }}
    />
  );
}
