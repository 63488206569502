import React from "react";
import { EditableDatePicker, EditableTextField } from "components/UI";
import { COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO } from "../constants/table";
import { ModeAction } from "state/enum";
import { API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO } from "shared/constants/api-name/invoice/exportInvoiceCreation";
export const useColumnCancalVanningResultSubScreen = ({
  rows,
  mode,
  // setValueFieldTable,
  dataList: {},
}) => {
  return [
    // Col 1
    {
      field: API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.ROW_NUMBER,
      headerName: COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.NO,
      headerAlign: "center",
      align: "right",
      width: 50,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.EXP_CD,
      headerName: COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.EXPORTER_CODE,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 3
    {
      field: API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.IMPORTER_COMPANY,
      headerName: COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.IMPORTER_COMPANY,
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.impCompanyAbbr;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 4
    {
      field: API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.RENBAN_NO,
      headerName: COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.RENBAN_NO,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 5
    {
      field: API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.ETD_DATE,
      headerName: COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.ETD_DATE,
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 6
    {
      field: API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.CONTAINER_NO,
      headerName: COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.CONTAINER_NO,
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.containerNo;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
    // Col 7
    {
      field: API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.QTY,
      headerName: COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.QTY,
      headerAlign: "center",
      align: "right",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.qty;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableDatePicker {...params} required />;
        }
        return params.value;
      },
    },
    // Col 8
    {
      field: API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.INVOICE_NO,
      headerName: COLUMN_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.INVOICE_NO,
      headerAlign: "center",
      align: "left",
      minWidth: 150,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.row.invNo;
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} required defaultValue="" maxLength={40} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField value={params?.value} {...params} required defaultValue="" />;
        }
        return params.value;
      },
    },
  ];
};
