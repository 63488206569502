import React, { Fragment } from "react";
import { Grid } from "@mui/material";
// SHARED COMPONENTS
import { DropDown, InputMask } from "components/UI";
// Type
import { ModeAction } from "state/enum";
import { API_NAME_APMC } from "shared/constants";
import TemplateBar from "./TemplateBar";

export default function FilterBar({
  form,
  setForm,
  mode,
  setMode,
  setMsg,
  dataList: { modeList, dataTypeList, uploadStatusList },
  ...rest
}) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>*Forecast Monthly :</strong>
        <InputMask
          id="input-apmc-month"
          mask="mM/YYYY"
          required
          fullWidth={true}
          formatChars={{
            Y: "[0-9]",
            m: "[0-1]",
            M: "[0-9]",
          }}
          maskChar={null}
          alwaysShowMask={false}
          value={form?.forecastMonth}
          onChange={e => {
            setForm(prev => ({
              ...prev,
              [API_NAME_APMC.FORECAST_MONTH]: e.target.value,
            }));
          }}
          disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          autoFocus={true}
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={7} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px" }}>(MM/YYYY)</strong>
      </Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>Mode :</strong>
        <DropDown
          id="input-apmc-mode"
          value={form?.mode}
          onChange={e => {
            setForm(prev => ({
              ...prev,
              [API_NAME_APMC.MODE]: e.target.value,
            }));
          }}
          menu={modeList?.map(val => ({
            key: val?.value,
            value: val?.value,
          }))}
          disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          defaultValue=""
          placeholder="<All>"
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>Data Type : </strong>
        <DropDown
          id="select-apmc-data-type"
          value={form?.dataTypeCd}
          onChange={e => {
            setForm(prev => ({
              ...prev,
              [API_NAME_APMC.DATA_TYPE_CD]: e.target.value,
            }));
          }}
          menu={dataTypeList?.map(val => ({
            key: val.cd,
            value: val.value,
          }))}
          defaultValue=""
          disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          placeholder="<All>"
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "325px" }}>Upload status : </strong>
        <DropDown
          id="select-apmc-upload-status"
          value={form?.uploadStatusCd}
          onChange={e => {
            setForm(prev => ({
              ...prev,
              [API_NAME_APMC.UPLOAD_STATUS_CD]: e.target.value,
            }));
          }}
          menu={uploadStatusList?.map(val => ({
            key: val.cd,
            value: val.value,
          }))}
          disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          placeholder="<All>"
          defaultValue=""
          tabIndex={1}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={12} style={{ marginTop: "15px", display: "flex" }}>
        <strong style={{ margin: "auto 0px", width: "194px" }}>Plan Template : </strong>
        <TemplateBar setMsg={setMsg} style={{ marginTop: "15px" }} tabIndex={1} />
      </Grid>
    </Grid>
  );
}
