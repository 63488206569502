import { Box, styled } from "@mui/material";
import * as React from "react";
import Sidebar from "./SideBar/Sidebar";
import route from "components/Layout/SideBar/route.constant";
import { Footer, Navbar } from ".";
import { Outlet } from "react-router-dom";

const MainWrapper = styled(Box)({ display: "flex", flexDirection: "column", height: "100vh" });

const ContentWrapper = styled(Box)({ padding: "calc(0.8% + 28px) 0", overflow: "scroll", zoom: "80%", width: "100%" });

const ScreenWrapper = styled(Box)({ display: "flex", flex: 1 });

const Main = () => {
  return (
    <MainWrapper>
      <Navbar />
      <ScreenWrapper>
        <Sidebar items={route} />

        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
        <Footer />
      </ScreenWrapper>
    </MainWrapper>
  );
};

export default Main;
