import React, { Fragment } from "react";
import { TanStackTable } from "components/UI";
import SubmitBar from "pages/Forecast/ShipmentManagementScreen/components/Mapping/components/SubmitBar";
//UTIL
import { responseErrors, messageDisplay, isBlankOrNull } from "utils";
import { validationSearchForm } from "utils/validation";
import { formatRowsMapping } from "pages/Forecast/ShipmentManagementScreen/utils/formatData";
//Type
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { API_SHIPMENT, COMMON_ENUM, ROW_PER_PAGE, WEB_FUNCTION_ID } from "shared/constants";
import { useMappingCreateMutation, useMappingUpdateMutation } from "shared/services/inventory-forecast";
import { isEmpty } from "lodash";

// Service
import { useShipmentSearchMutation } from "shared/services/inventory-forecast";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import sleep from "shared/hooks/Sleep";
export default function MappingTable(props) {
  const {
    form,
    mode,
    setMode,
    columns,
    rows,
    setRows,
    setRPkg,
    setOnSearch,
    setAppId,
    refresh,
    dataList: { importerList, rPkgList },
    rowSelection: { selectedRowKeys, setSelectedRowKeys, selectedRows, setSelectedRows },
    pagination: { pageNumber, setPageNumber, pagination, setPagination },
    setMsg: { setMsgAlert, setMsgError },
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  const createShipment = useMappingCreateMutation();
  const updateShipment = useMappingUpdateMutation();
  const searchShipment = useShipmentSearchMutation();
  const handleChangePagination = async (event, value) => {
    try {
      event.preventDefault();
      setMsgAlert([]);
      setMsgError([]);
      // Reset Data of Table
      // initial mode
      setMode(ModeAction.VIEW);
      setSelectedRowKeys([]);
      setSelectedRows({});
      setPageNumber(value);
      // Search data
      const bodySearch = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.OPERATION]: form?.operationId,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? importerList.find(v => v.companyId === form?.importerId).companyAbbr
          : "",
        [API_SHIPMENT.R_RKG_OWNER]: form.rPkgOwnerId
          ? rPkgList.find(v => v.rPkgOwnerCompId === form?.rPkgOwnerId).rPkgOwnerCompAbbr
          : "",
        [API_SHIPMENT.FORECAST_MONTH]: form.forecastMonth,
        [API_SHIPMENT.SHIPMENT_STATUS]: form.shipmentStsId,
        [API_SHIPMENT.ETD_FORM]: form.etdFrom,
        [API_SHIPMENT.ETD_TO]: form.etdTo,
        [API_SHIPMENT.ETA_FORM]: form.etaFrom,
        [API_SHIPMENT.ETA_TO]: form.etaTo,
        pageNumber: value,
        rowsPerPage: ROW_PER_PAGE,
      };
      startLoading();
      const searchData = await searchShipment(bodySearch);
      stopLoading();
      if (!searchData?.result?.data?.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] });
        setMsgError([msg]);
        return false;
      }
      if (searchData?.result?.data) {
        const { data, rPkg } = formatRowsMapping(searchData?.result?.data);
        setRows(data);
        setRPkg(rPkg);
        setPagination(searchData?.result?.pagination ?? {});
        setPageNumber(searchData?.result?.pagination?.pageNumber);
      }
      setOnSearch(true);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleCreate = async newRow => {
    try {
      const body = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.CREATE_BY]: form?.userId,
        [API_SHIPMENT.OPERATION]: form.operationId,
        [API_SHIPMENT.FORECAST_MONTH]: newRow?.forecastMonth,
        [API_SHIPMENT.EXPORTER]: newRow?.exporter,
        [API_SHIPMENT.IMPORTER]: newRow?.importer,
        [API_SHIPMENT.ORG_ACTL_FILE_NAME]: form?.fileName?.orgActlFileName,
        [API_SHIPMENT.FILE]: form?.file?.orgActlFileName,
        [API_SHIPMENT.FUNCTION_ID]: WEB_FUNCTION_ID.FORECAST_SHIPMENT,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_SHIPMENT.ORG_ACTL_FILE_NAME,
            type: MessageType.EMPTY,
            key: ["Actual"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const createData = new FormData();
      createData.append([API_SHIPMENT.DATA_OWNER], body.dataOwner);
      createData.append([API_SHIPMENT.COMPANY_ABBR], body.companyAbbr);
      createData.append([API_SHIPMENT.OPERATION], body.operation);
      createData.append([API_SHIPMENT.EXPORTER], body.exporter);
      createData.append([API_SHIPMENT.IMPORTER], body.importer);
      createData.append([API_SHIPMENT.FORECAST_MONTH], body.forecastMonth);
      createData.append([API_SHIPMENT.FUNCTION_ID], body.functionId);
      createData.append([API_SHIPMENT.CREATE_BY], body.createBy);
      createData.append([API_SHIPMENT.FILE], body.file);
      const response = await createShipment(createData);

      // MDN90034AINF: Posting on demand batch is completed successfully.
      if (response?.statusCode === 200) {
        setMsgAlert([response?.result?.message]);
        setAppId(response?.result?.appId);
      } else {
        setMsgAlert(["MSTD1046AERR: Posting On Demand Batch is failed."]);
      }
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleUpdate = async row => {
    try {
      let msg;

      const shipmentSummaryHId = row?.shipmentSummaryHId ? row?.shipmentSummaryHId : "";
      const shipmentCd = row?.shipmentCd ? row?.shipmentCd : "";

      const body = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.UPDATE_BY]: form?.userId,
        [API_SHIPMENT.UPDATE_DATE]: row?.updateDt,
        [API_SHIPMENT.OPERATION]: form?.operationId,
        [API_SHIPMENT.FORECAST_MONTH]: row?.forecastMonth,
        [API_SHIPMENT.EXPORTER]: row.exporter,
        [API_SHIPMENT.IMPORTER]: row.importer,
        shipmentCD: row.shipmentCd,
        [API_SHIPMENT.ORG_ACTL_FILE_NAME]: form.fileName.orgActlFileName,
        [API_SHIPMENT.FILE]: form?.file?.orgActlFileName,
        [API_SHIPMENT.ORG_NCV_FILE_NAME]: form.orgNcvFileName ? form.orgNcvFileName.name : row?.orgNcvFileName || "",
        [API_SHIPMENT.ORG_BL_FILE_NAME]: form.orgBlFileName ? form.orgBlFileName.name : row?.orgBlFileName || "",
        [API_SHIPMENT.NCV_FILE_NAME]: form.ncvFileName ? form.ncvFileName : row?.ncvFileName || "",
        [API_SHIPMENT.BL_FILE_NAME]: form.blFileName ? form.blFileName : row?.blFileName || "",
        [API_SHIPMENT.NCV_FILE_UPLOAD]: form.ncvFileUpload ? form.ncvFileUpload : "",
        [API_SHIPMENT.BL_FILE_UPLOAD]: form.blFileUpload ? form.blFileUpload : "",
        [API_SHIPMENT.FUNCTION_ID]: WEB_FUNCTION_ID.FORECAST_SHIPMENT,
      };

      // Validate Actual file
      if (row.shipmentStsId === COMMON_ENUM.SHIPMENT_STATUS_PLAN && isEmpty(body.orgActlFileName)) {
        msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0074AERR", msg: [] });
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return false;
      }
      let updateData;
      let hasActualFile = Object.keys(body.file).length > 0 || body.file.name;
      if (hasActualFile) {
        updateData = new FormData();
        updateData.append([API_SHIPMENT.COMPANY_ABBR], body.companyAbbr);
        updateData.append([API_SHIPMENT.OPERATION], body.operation);
        updateData.append([API_SHIPMENT.EXPORTER], body.exporter);
        updateData.append([API_SHIPMENT.IMPORTER], body.importer);
        updateData.append([API_SHIPMENT.FORECAST_MONTH], body.forecastMonth);
        updateData.append("shipmentCD", body.shipmentCD);
        updateData.append([API_SHIPMENT.FUNCTION_ID], body.functionId);
        updateData.append([API_SHIPMENT.CREATE_BY], body.updateBy);
        updateData.append([API_SHIPMENT.UPDATE_BY], body.updateBy);
        updateData.append([API_SHIPMENT.UPDATE_DATE], body.updateDt);
        updateData.append([API_SHIPMENT.ORG_NCV_FILE_NAME], body.orgNcvFileName);
        updateData.append([API_SHIPMENT.ORG_BL_FILE_NAME], body.orgBlFileName);
        updateData.append([API_SHIPMENT.NCV_FILE_NAME], body.ncvFileName);
        updateData.append([API_SHIPMENT.BL_FILE_NAME], body.blFileName);
        updateData.append([API_SHIPMENT.NCV_FILE_UPLOAD], body.ncvFileUpload);
        updateData.append([API_SHIPMENT.BL_FILE_UPLOAD], body.blFileUpload);
        updateData.append([API_SHIPMENT.FILE], body.file);
      } else {
        updateData = { ...body };
      }
      // TODO File not found when change file name in locally
      // console.log(updateData, isBlankOrNull(updateData.file));
      // if (isBlankOrNull(updateData.file)) {
      //   const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0031AERR", msg: ["File"] });
      //   setMsgError([msg]);
      //   sleep(5000);
      //   window.scrollTo(0, 0);
      //   return false;
      // }
      const response = await updateShipment(shipmentSummaryHId, shipmentCd, updateData, hasActualFile);
      if (response?.statusCode === 200) {
        setMsgAlert([response?.result?.message]);
        setAppId(response?.result?.appId);
      } else {
        setMsgAlert(["MSTD1046AERR: Posting On Demand Batch is failed."]);
      }
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const processUpdateRow = async newRow => {
    try {
      let response;
      setMsgError([]);
      setMsgAlert([]);
      startLoading();
      if (mode === ModeAction.ADD) {
        response = await handleCreate(newRow);
      } else {
        response = await handleUpdate(newRow);
      }
      // throw to onProcessRowUpdate is error
      if (!response) {
        stopLoading();
        return false;
      }

      await refresh();
      stopLoading();
      setMode(ModeAction.VIEW);
      setSelectedRowKeys([]);
      setSelectedRows({});

      const updatedRow = { ...newRow, isNew: false };
      return updatedRow;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleRowDisableSelect = row =>
    !(
      row?.original?.cancelFlag === "Y" ||
      row.original.shipmentStsId === COMMON_ENUM.SHIPMENT_STATUS_CANCEL ||
      row.original.shipmentSts === "Cancel"
    );

  return (
    <Fragment>
      <TanStackTable
        rows={rows}
        columns={columns}
        tableHeight="600px"
        mode={mode}
        setMode={setMode}
        columnVisibility={{
          [API_SHIPMENT.SHIPMENT_STATUS]: false,
          [API_SHIPMENT.SHIPMENT_CANCEL_FLAG]: false,
          [API_SHIPMENT.SHIPMENT_SUM_UPDATE_DATE]: false,
          [API_SHIPMENT.SHIPMENT_SUMMARY_H_ID]: false,
        }}
        // getRowId={row => console.log(row)}
        onPagination={true}
        pagination={pagination}
        pageNumber={pageNumber}
        rowSelectionKey={selectedRows}
        setRowSelectionKey={setSelectedRows}
        rowSelection={selectedRowKeys}
        setRowSelect={setSelectedRowKeys}
        enableRowSelection={true}
        enableMultiRowSelection={false}
        handleChangePagination={handleChangePagination}
        handleRowDisableSelect={handleRowDisableSelect}
        isShipment={true}
      />

      <SubmitBar
        mode={mode}
        rows={rows}
        setMode={setMode}
        setRows={setRows}
        processUpdateRow={processUpdateRow}
        setMsg={{ setMsgAlert, setMsgError }}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </Fragment>
  );
}
