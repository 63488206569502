import { useCustomQuery } from "shared/services/base.service";
import { isBlankOrNull } from "utils";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
const RPkgMasterCommonApiService = () => ({
  useRPkgMasterGetCategoryRPkgTypeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/COMMON/R_PKG_GROUP/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useRPkgMasterGetGroupRPkgTypeQuery: ({ dataOwner, rPkgOwnerCompAbbr, cd }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/r-package/type/group/${dataOwner}/${rPkgOwnerCompAbbr}/${cd}`,
      method: "GET",
      enabled: !isBlankOrNull(cd),
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useRPkgMasterGetMakerRPkgTypeQuery: ({ dataOwner, cd }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/maker/${dataOwner}/${cd}`,
      method: "GET",
      enabled: !isBlankOrNull(cd),
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useRPkgMasterGetMakerAllRPkgTypeQuery: ({ dataOwner, cd }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/maker/all/${dataOwner}/${cd}`,
      method: "GET",
      enabled: !isBlankOrNull(cd),
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useRPkgMasterGetLocationRPkgTypeQuery: ({ dataOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/r-module/route-path/${dataOwner}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useRPkgMasterGetRPkgTypeInTableQuery: ({ dataOwner, rPkgOwnerCompAbbr, cd }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/r-package/${dataOwner}/${rPkgOwnerCompAbbr}/${cd}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
});

export const {
  useRPkgMasterGetCategoryRPkgTypeQuery,
  useRPkgMasterGetGroupRPkgTypeQuery,
  useRPkgMasterGetMakerRPkgTypeQuery,
  useRPkgMasterGetMakerAllRPkgTypeQuery,
  useRPkgMasterGetLocationRPkgTypeQuery,
  useRPkgMasterGetRPkgTypeInTableQuery,
} = RPkgMasterCommonApiService();
