import React from "react";
// Components
import { Grid, Divider } from "@mui/material";
import { InputButton } from "components/UI";
// Utils
import { messageDisplay, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
import { transformCancelSubScreenData } from "../../../hooks/tranformData";
//Service
import { useExportInvoiceSearchCancelVanningResultSubScreenMutation } from "shared/services/invoice";
//Type
import { DEFAULT_STATE_WDN95012 } from "../../../constants/constants";
import { BUTTON_VALUE, FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { userProfile } from "constant";
import { useSelector } from "react-redux";
export default function ButtonCriterial(props) {
  const {
    setMode,
    form,
    setForm,
    setRows,
    setOnSearch,
    setMsg: { setMsgAlert, setMsgError },
    stateButton: { searchBtn, clearBtn },
    rowSelection: { setRowModesModel, setRowSelectionModel },
    pagination: { setPagination, setPageNumber },
    functionId,
  } = props;
  const userInfo = useSelector(state => state.auth.user);
  const searchExportSubScreenInfo = useExportInvoiceSearchCancelVanningResultSubScreenMutation();
  const handleSearch = async event => {
    try {
      event.preventDefault();
      setMsgAlert([]);
      setMsgError([]);
      // Reset Data of Table
      setPageNumber(FIRST_PAGE);
      setPagination({});

      setMode(ModeAction.VIEW);
      setOnSearch(false);

      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});
      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        etdDt: form?.etdDt,
        companyId: form?.companyId,
        containerNo: form?.containerNo,
        userId: userInfo.userName,
        loginUserCompanyAbbr: userInfo.companyAbbr, // TODO: mock constants here
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "etdDt",
            type: MessageType.EMPTY,
            key: ["ETD"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      const { result } = await searchExportSubScreenInfo(body);
      if (!result?.data?.length) {
        setMsgError([
          messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0059AERR",
            msg: "",
          }),
        ]);
        return;
      }
      const data = transformCancelSubScreenData(result?.data);
      setRows(data);
      setPagination(result?.pagination ?? {});
      setPageNumber(result?.pagination?.pageNumber);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setForm(DEFAULT_STATE_WDN95012);
    //clear local storage
    // setShippingInfo(DEFAULT_LOCAL_STATE_WDN95030);
    // Reset Data of Table
    setPageNumber(FIRST_PAGE);
    setRows([]);
    setPagination({});
    setRowSelectionModel([]);
    setRowModesModel({});
    //Reset state
    setOnSearch(false);
    setMode(ModeAction.VIEW);
  };
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <InputButton
          id="button-search"
          value={BUTTON_VALUE.SEARCH}
          disabled={searchBtn}
          onClick={handleSearch}
          name={`${functionId}Search`}
          tabIndex={0}
        />
        <InputButton
          id="button-clear"
          value={BUTTON_VALUE.CLEAR}
          disabled={clearBtn}
          onClick={handleClear}
          name={`${functionId}Clear`}
          tabIndex={0}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
