import ConfirmDialog from "components/UI/ConfirmDialog";
import React, { useContext, useState } from "react";
import { message } from "utils";

const ConfirmDialogContext = React.createContext({
  // eslint-disable-next-line no-unused-vars
  success: ({ type = undefined, msg = "" }) => {},
  error: () => {},
});

const ConfirmDialogProvider = props => {
  //   const [notification, setNotification] = useState(null);
  const [notificationText, setNotificationText] = useState(null);
  const [isConfirmShow, SetisConfirmShow] = useState(false);
  const resolver = React.useRef();

  const success = ({ type = undefined, msg = "" }) => {
    setNotificationText(message({ type, msg }));
    SetisConfirmShow(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };
  const error = text => {
    setNotificationText(text);
    SetisConfirmShow(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const clear = () => {
    resolver.current && resolver.current(false);

    SetisConfirmShow(() => false);
  };

  const confirm = async () => {
    resolver.current && resolver.current(true);

    SetisConfirmShow(() => false);
  };

  return (
    <ConfirmDialogContext.Provider
      value={{
        success,
        error,
      }}>
      {props.children}
      <ConfirmDialog
        open={isConfirmShow}
        onClose={clear}
        onConfirm={confirm}
        message={notificationText}
        noRoleRequired={true}
      />
    </ConfirmDialogContext.Provider>
  );
};

export { ConfirmDialogProvider };
export default ConfirmDialogContext;
export const useConfirmDialogContext = () => useContext(ConfirmDialogContext);
