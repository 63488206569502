import { MessageList } from "state/types/message";
import { MSG_LIST } from "state/types/msg";
import _ from "lodash";
export const message = ({ type = undefined, msg = "" }) => {
  const msgType = {
    confirmSave: `MDN90014ACFM: Do you want to confirm saving the record?`,
    confirmPrint: `MDN91005ACFM: Do you want to confirm printing the barcode?`,
    confirmCancel: `MDN90019ACFM: Do you want to abort the operation?`,
    confirmDelete: `MDN90018ACFM: Do you want to confirm deletion the record?`,
    notFound: `MSTD0059AERR: No data found`,
    empty: `MSTD0031AERR: ${msg} should not be empty`,
    closeScreen: `MDN90039ACFM: Do you want to close the screen? `,
    formatPrice: `MSTD0056AERR: Invalid numeric format for Price. The numeric format must be 9,999.99`,
    deleted: ` MDN90017AINF: Data is deleted successfully.`,
    duplicateCurrency: `MSTD0039: Duplication found for Currency`,
    singleRecord: `MSTD1017: A sigle record must be selected to execute Edit operation.`,
    noSelectedRecord: `MSTD1017: A sigle record must be selected to execute Edit operation.`,
    added: `MDN90015AINF: Data is added successfully.`,
    updated: `MDN90016AINF: Data is updated successfully.`,
    // priceMoreThan0: `MSTD0021AERR: Price must be greater than 0 `,
    typeImage: `MSTD7013BEER File ${msg} is not expected file type [PNG, JPG, JPEG]`,
    sizeImage: `MSD90001AERR File must not be able to upload images larger than 1MB.`,
    // seqNoMoreThan0: `MSTD0021AERR: Sequence must be greater than 0`,
    deleteEffectiveDateToPass: `MSTD1005AERR Cannot delete R-Package Type because Effective Date To = ${msg} already passed`,
    moreThan0: `MSTD0021AERR: ${msg} must be greater than 0 `,
    pastToday: `MSTD0020AERR: ${msg} must be equal to or later than today`,
    effDateMoreThan0: `MSTD0019AERR Effective Date (To) must be equal to or later than Effective Date (From)`,
    FORECAST_SHIPMENT_CONFIRM_CANCEL: `MDN90022ACFM: Are you sure you want to Cancel Shipment the record?`,
    FORECAST_MONTHLY_CONFIRM_ACTIVATE: `MDN92015ACFM: Do you want to Activate Forecasting?`,
    COMMON_ON_DEMAND_CONFIRM_DELETE: "MSTD0001ACFM: Are you sure you want to delete the record ?",
    COMMON_ON_DEMAND_CONFIRM_CANCEL: "MSTD0004ACFM: Are you sure you want to abort the operation?",
    COMMON_REPROCESS_CONFIRM_REPROCESS: "MSTD0001ACFM: Are you sure you want to reprocess the record ?",
    COMMON_REPROCESS_CONFIRM_IGNORE: "MSTD0002ACFM: Are you sure you want to Ignore the record ?",
    COMMON_ON_DEMAND_BATCH_CONFIRM_EXECUTE: "MSTD0121ACFM: Are you sure you want to execute the batch ?",
    COMMON_EXCEL_DOWNLOAD: `MSTD0114ACFM: ${msg} Found, Do you wish to proceed ?`,
    INVENTORY_CONFIRM_ALLOCATED: `MDN90014ACFM: Do you want to confirm saving the record ?`,
    INVENTORY_CONFIRM_CANCEL: `MDN90019ACFM: Do you want to abort the operation ?`,
    SHIPPING_INFO_CONFIRM_DELETE: `MDN90018ACFM: Do you want to confirm deletion the record ?`,
    WDN95031_CONFIRM_MATCHING: `MSTD0004ACFM: Are you sure you want to confirm all records ?`,
    MDN93007ACFM_CONFIRM_FREEZE: `MDN93007ACFM: Do you want to freeze System?`,
    MDN93007ACFM_CONFIRM_UNFREEZE: `MDN93007ACFM: Do you want to unfreeze System?`,
    MSTD0039AERR_DUPLICATE: `MSTD0039AERR: Duplicate found for ${msg}`,
  };
  return msgType[type] ?? msg;
};

export const messageTypeDisplay = (type, key = undefined || []) => {
  const obj = MessageList.find(v => v.key === type);

  if (!_.isEmpty(key)) {
    let modifiedMessage = obj.message;

    key?.forEach((value, index) => {
      const placeholder = `[${index}]`;
      modifiedMessage = modifiedMessage.replace(placeholder, value);
    });

    return `${obj.code}: ${modifiedMessage}`;
  }

  return `${obj.code}: ${obj.message}`;
};

export const messageDisplay = ({ type, code, msg }) => {
  try {
    const obj = MSG_LIST.filter(v => v.key === type).find(i => i.code === code);
    // ? IF NOT EDIT MESSAGE WILL SEND DIRECTlY
    if (msg.length === 0) return `${obj.code}: ${obj.message}`;

    // ? IF EDIT MESSAGE ADJUST MESSAGE
    let regexPattern = [];
    let newMsg = `${obj.code}: ${obj.message}`;
    msg.forEach((element, index) => {
      regexPattern = new RegExp(`\\{${index}\\}`, "g");
      newMsg = newMsg.replace(regexPattern, element);
    });
    return newMsg;
  } catch (error) {
    console.log(error);
    return;
  }
};
