import React, { useState, useMemo } from "react";
// Utils
import { messageDisplay, responseErrors } from "utils";
// SHARE COMPONENT
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import { Box, Grid } from "@mui/material";
// Hook
import { useColumn } from "./hooks/useColumn";
// COMPONENT
import FilterBar from "./components/FilterBar";
import ButtonBar from "./components/ButtonBar";
import Table from "./components/Table";
// API
import {
  useGetBrokerCd,
  useGetExporterCd,
  useImportInvoiceDownloadSearchMutation,
} from "shared/services/invoice/import-invoice-download-service";

// TYPE
import { ModeAction, MSG_TYPE } from "state/enum";
import { API_NAME_IMPORT_INVOICE_DOWNLOAD } from "shared/constants/api-name/invoice/importInvoiceDownload";

const DEFAULT_VALUE = {
  [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_FROM]: "",
  [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_TO]: "",
  [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_FROM]: "",
  [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_TO]: "",
  [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXPORTER_ID]: "",
};
export default function ImportInvoiceDownloadScreen() {
  // API
  const importInvoiceDownloadSearch = useImportInvoiceDownloadSearchMutation();
  const { data: exporterList } = useGetExporterCd();
  const { data: brokerList } = useGetBrokerCd();

  // Form
  const [form, setForm] = useState(DEFAULT_VALUE);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);

  // ? Table

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const [disable, setDisable] = useState(false);

  const columnData = useColumn({ rows, mode, dataList: { brokerList }, setDisable, disable });
  const columns = useMemo(() => columnData, [columnData]);

  const getSearch = async body => {
    try {
      const searchData = await importInvoiceDownloadSearch(body);
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ROW_NUMBER]: item.rowNumber,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.FORECAST_MONTH]: item.forecastMonth,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.DATA_TYPE]: item.source,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.MODE]: item.mode,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.DATA_OWNER]: item.dataOwner,

          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ORG_FILE_NAME]: item.orgFileName,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.FILE_UPLOAD_ID]: item.fileUploadId,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.FILE_NAME]: item.fileName,

          [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPLOAD_IMP_EXP_CD]: item.uploadImpExpCd,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPLOAD_STATUS]: item.uploadSts,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPLOAD_DATE]: item.uploadDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPLOAD_BY]: item.uploadBy,

          [API_NAME_IMPORT_INVOICE_DOWNLOAD.USE_STATUS]: item.useSts,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.USE_DATE]: item.useDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.USE_BY]: item.useBy,

          [API_NAME_IMPORT_INVOICE_DOWNLOAD.MODULE_ID]: item?.moduleId,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.FUNCTION_ID]: item?.functionId,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.D_HODTCRE]: item?.dHODTCRE,
        })) ?? [];

      // ? CHECK DATA NOT FOUND
      if (!data?.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] });
        setMsgError([msg]);
        return false;
      }

      return { searchData, data };
    } catch (error) {
      console.log(error);
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <HeaderContentBar title="WDN95020 : Import Invoice Download Screen" />
      <AlertMessage type={"warning"} message={msgError} />
      <AlertMessage type={"success"} message={msgAlert} />
      <Grid container spacing={0} sx={{ alignItems: "center" }}>
        <Grid item xs={12}>
          <FilterBar
            form={form}
            setForm={setForm}
            mode={mode}
            setMode={setMode}
            setMsg={{ setMsgAlert: setMsgAlert, setMsgError: setMsgError }}
            dataList={{ exporterList: exporterList }}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonBar
            form={form}
            setForm={setForm}
            mode={mode}
            setMode={setMode}
            rows={rows}
            setRows={setRows}
            columns={columns}
            setOnSearch={setOnSearch}
            setDisable={setDisable}
            dataList={{ exporterList: exporterList }}
            getSearch={getSearch}
            setMsg={{ setMsgAlert: setMsgAlert, setMsgError: setMsgError }}
            pagination={{
              pagination: pagination,
              pageNumber: pageNumber,
              setPagination: setPagination,
              setPageNumber: setPageNumber,
            }}
            rowSelection={{
              rowSelectionModel: rowSelectionModel,
              setRowSelectionModel: setRowSelectionModel,
              rowModesModel: rowModesModel,
              setRowModesModel: setRowModesModel,
            }}
            functionId={"WDN95020"}
          />
        </Grid>

        {onSearch && (
          <Grid item xs={12}>
            <Table
              form={form}
              mode={mode}
              setMode={setMode}
              rows={rows}
              setRows={setRows}
              columns={columns}
              onSearch={onSearch}
              setOnSearch={setOnSearch}
              getSearch={getSearch}
              setMsg={{ setMsgAlert: setMsgAlert, setMsgError: setMsgError }}
              pagination={{
                pagination: pagination,
                pageNumber: pageNumber,
                setPagination: setPagination,
                setPageNumber: setPageNumber,
              }}
              rowSelection={{
                rowSelectionModel: rowSelectionModel,
                setRowSelectionModel: setRowSelectionModel,
                rowModesModel: rowModesModel,
                setRowModesModel: setRowModesModel,
              }}
              rest={{ slotProps: { baseCheckbox: { tabIndex: 1 } } }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
