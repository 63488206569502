import { useCustomQuery } from "shared/services/base.service";
import _ from "lodash";
import { COMMON_ENUM } from "shared/constants";
import { isBlankOrNull } from "utils";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useGetType = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/COMMON/TYPE_OF_DIMENSION/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetContainerTypeDropDown = ({ dataOwner }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/volumn-dimension/r-package/container-type/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};
export const useGetContainerTypeCriterial = ({ dataOwner, type }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/volumn-dimension/container-type/${dataOwner}/${type}`,
    method: "GET",
    enabled: !isBlankOrNull(type),
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetImpCdDropDown = ({ dataOwner }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/volumn-dimension/importer/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetRpkgTypeDropDown = ({ dataOwner }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/volumn-dimension/r-package-type/${dataOwner}/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetImpCdTableDropDown = ({ dataOwner }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/volumn-dimension/importer/plant/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetRpkgCategoryTableDropDown = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/${COMMON_ENUM.C_SM_CTG_TMAP_EM}/R_PKG_CATEGORY/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetRpkgTypeTableDropDown = ({ dataOwner, rPkgOwnerCompAbbr, categoryCd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/volumn-dimension/r-package-type/${dataOwner}/${rPkgOwnerCompAbbr}/${categoryCd}`,
    method: "GET",
    enabled: !isBlankOrNull(categoryCd),
    key: [categoryCd],
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetparentRpkgTypeTableDropDown = ({ dataOwner, parentRPkgTypeTable }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/volumn-dimension/r-package-type/ignore/${dataOwner}/${dataOwner}/${parentRPkgTypeTable}`,
    method: "GET",
    key: [parentRPkgTypeTable],
    enabled: !_.isEmpty(parentRPkgTypeTable),
    select: data => data.result,
    keepPreviousData: true,
  });
};
