import { useMutateData, useMutateDownload } from "shared/services/base.service";

const BaseServicePath = process.env.REACT_APP_API_PREFIX;

export const useSearchNewBarcodePrint = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/barcode-print/new/search`,
      method: "POST",
      data: body,
    });
  };
};

export const useNewPrint = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/barcode-print/new-print`,
      method: "POST",
      data: body,
    });
  };
};
export const useRePrint = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/barcode-print/re-print`,
      method: "POST",
      data: body,
    });
  };
};

export const useHistorySearch = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/barcode-print/history/search`,
      method: "POST",
      data: body,
    });
  };
};
