import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { DataGridTable } from "components/UI";
import ButtonSubmit from "./ButtonSubmit";
//Util
import { messageDisplay, responseErrors } from "utils";
import {
  transformDataContainer,
  transformDataRPkg,
} from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/transformData";
import { validationSearchForm } from "utils/validation";
// Service
import { useContainerSearchMutation, useRPackageSearchMutation } from "shared/services/invoice";
//type
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { COLUMN_SHIPPING_INFO } from "pages/Invoice/ShippingInformationMaintenanceScreen/constants/table";
import { COMMON_ENUM, FIRST_PAGE, PAGINATION } from "shared/constants";
import { isEmpty } from "lodash";
import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";
import { GROUP_CODE_TYPE } from "pages/Invoice/ShippingInformationMaintenanceScreen/constants/constant";
import { useSelector } from "react-redux";

export default function TableContainer(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    form,
    setForm,
    setOnSearch,
    sumQtyRPkg,
    updateTempContainer,
    copyRPackageFunction,
    updateTempRPkg,
    loading: { startLoading, stopLoading },
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    functionId,
  } = props;
  const userInfo = useSelector(state => state.auth.user);
  // Api
  const searchContainer = useContainerSearchMutation();
  const searchRPackage = useRPackageSearchMutation();
  const columnGroupingModel = [
    {
      groupId: COLUMN_SHIPPING_INFO.NO,
      headerAlign: "center",
      children: [{ field: "rowNumber" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: COLUMN_SHIPPING_INFO.SHIPPING_PLAN,
      headerAlign: "center",
      children: [
        { field: "planContainerNo" },
        { field: "planRModuleQty" },
        { field: "planRBoxDunQty" },
        { field: "planRPkgDetail" },
      ],
      headerClassName: "table-header-group",
    },
    {
      groupId: COLUMN_SHIPPING_INFO.ACTUAL_VANNING,
      headerAlign: "center",
      children: [
        { field: "actualContainerNo" },
        { field: "actualRModuleQty" },
        { field: "actualRBoxDunQty" },
        { field: "actualRPkgDetail" },
        { field: "actualPackingMonth" },
        { field: "actualVanningPLant" },
        { field: "actualOrderType" },
      ],
      headerClassName: "table-header-group",
    },
    {
      groupId: COLUMN_SHIPPING_INFO.STATUS,
      headerAlign: "center",
      children: [{ field: "status" }],
      headerClassName: "align-items-end",
    },
  ];
  const classNameOfCell = {
    "& .theme-cell-row-stats-incomplete": {
      backgroundColor: "#FFD7D7",
    },
  };
  // custom style of row when statue is incomplete and on edit mode
  const handleClassNameRow = params => {
    if (params?.row?.status === "N") {
      return "custom-row";
    } else {
      return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
    }
  };

  const handleChangePagination = async (event, value) => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      setPageNumber(value);
      const ROW_PER_PAGE_TWENTY = 20;

      const body = {
        shippingInfoHID: form?.shippingHdrID,
        expCd: form?.exporterCd,
        impCompanyAbbr: form?.importerComp,
        etd: form?.etdDt, // DD/MM/YYYY
        impCd: "", // optional
        expCdSearch: "", // optional
        userId: userInfo.userName,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE_TWENTY,
      };
      const searchData = await searchContainer(body);
      // if (!searchData?.result?.data?.length) {
      //   return;
      // }
      const data = transformDataContainer(searchData?.result?.data);
      setForm(prev => ({ ...prev, rows: data }));
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleCreate = async newRow => {
    try {
      let msg;
      const { isSuccess, errors } = validationSearchForm({
        data: newRow,
        rule: [
          {
            field: "planContainerNo",
            type: MessageType.EMPTY,
            key: ["Container No"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return { action: false, updated: {} };
      }

      // check duplicate container no
      const dupContainer = rows.find(row => row.planContainerNo === newRow.planContainerNo && !row?.isNew);
      if (dupContainer) {
        msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MSTD0039AERR",
          msg: [`Container No. = ${dupContainer?.planContainerNo}`],
        });
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return { action: false, updated: newRow };
      }
      const reMap = rows?.find(val => val?.planContainerNo === newRow?.planContainerNo);
      if (reMap) {
        newRow.planRBoxDunQty = reMap.planRBoxDunQty;
        newRow.planRModuleQty = reMap.planRModuleQty;
        newRow.contSessionId = reMap.contSessionId;
      }

      let notEqual = false;
      if (newRow.planContainerNo !== newRow?.actualContainerNo) notEqual = true;
      if (parseInt(newRow.planRModuleQty) !== parseInt(newRow.actualRModuleQty)) notEqual = true;
      if (parseInt(newRow.planRBoxDunQty) !== parseInt(newRow.actualRBoxDunQty)) notEqual = true;

      // check vanning status
      newRow.status = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;

      msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN90042AINF",
        msg: [],
      });
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return { action: true, updated: newRow };
    } catch (error) {
      console.error(error);
      return { action: false, updated: newRow };
    }
  };
  const handleUpdate = async row => {
    try {
      let msg;

      const { isSuccess, errors } = validationSearchForm({
        data: row,
        rule: [
          {
            field: "planContainerNo",
            type: MessageType.EMPTY,
            key: ["Container No"],
          },
          {
            field: "planContainerNo",
            type: MessageType.INPUT_INVALID_STRING,
            key: ["Container No"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return { action: false, updated: {} };
      }
      // check duplicate container no
      const dupContainer = rows.find(
        ele => ele?.planContainerNo === row?.planContainerNo && ele?.rowNumber !== row?.rowNumber
      );
      if (dupContainer) {
        msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MSTD0039AERR",
          msg: [`Container No. = ${dupContainer?.planContainerNo}`],
        });
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return { action: false, updated: {} };
      }
      const reMap = rows?.find(val => val?.planContainerNo === row?.planContainerNo);
      if (reMap) {
        row.planRBoxDunQty = reMap.planRBoxDunQty;
        row.planRModuleQty = reMap.planRModuleQty;
        row.contSessionId = reMap.contSessionId;
      }

      // In case vanning status = Y , user change shipping container(plan) means planContainer != actualContainer
      // system will delete the row that user edit than add new row for new planContainer
      let newRow;
      if (row.status === COMMON_ENUM.VANNING_STATUS_COMPLETED) {
        if (!isEmpty(row?.actualContainerNo) && row?.planContainerNo !== row?.actualContainerNo) {
          newRow = {
            ...row,
            rowNumber: rows?.length + 1,
            planRBoxDunQty: "",
            planRModuleQty: "",
            actualContainerNo: "",
            actualRModuleQty: "",
            actualRBoxDunQty: "",
            actualPackingMonth: "",
            actualVanningPLant: "",
            actualOrderType: "",
            vanningInfoHId: "",
            shippingInfoDContId: "",
            renbanNo: "",
            status: COMMON_ENUM.VANNING_STATUS_INCOMPLETE,
            isNew: false,
          };
          // delete the row that user delete
          row.planContainerNo = "";
          row.planRModuleQty = "";
          row.planRBoxDunQty = "";
          row.status = COMMON_ENUM.VANNING_STATUS_INCOMPLETE;
        } else {
          let notEqual = false;
          if (row.planContainerNo !== row?.actualContainerNo) notEqual = true;
          if (parseInt(row.planRModuleQty) !== parseInt(row.actualRModuleQty)) notEqual = true;
          if (parseInt(row.planRBoxDunQty) !== parseInt(row.actualRBoxDunQty)) notEqual = true;
          // check vanning status
          row.status = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;
        }
      } else {
        let notEqual = false;
        if (row.planContainerNo !== row?.actualContainerNo) notEqual = true;
        if (parseInt(row.planRModuleQty) !== parseInt(row.actualRModuleQty)) notEqual = true;
        if (parseInt(row.planRBoxDunQty) !== parseInt(row.actualRBoxDunQty)) notEqual = true;
        row["status"] = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;
      }

      msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN90043AINF",
        msg: [],
      });

      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return { action: true, updated: row, newContainer: newRow };
    } catch (error) {
      console.error(error);
      const err = responseErrors(error);
      setMsgError(err);
      return { action: false, updated: {} };
    }
  };
  const handleCopyActualData = async row => {
    try {
      // // ! need sum qty first
      // const { sumRMQty, sumOTQty } = await getSumQty(row);
      // sleep(3000);
      let notEqual = false;

      if (row?.status === COMMON_ENUM.VANNING_STATUS_INCOMPLETE) {
        if (row.planContainerNo !== row?.actualContainerNo) notEqual = true;
        if (parseInt(row.planRModuleQty) !== parseInt(row.actualRModuleQty)) notEqual = true;
        if (parseInt(row.planRBoxDunQty) !== parseInt(row.actualRBoxDunQty)) notEqual = true;

        row["planContainerNo"] = row?.actualContainerNo;
        row["planRModuleQty"] = row?.actualRModuleQty;
        row["planRBoxDunQty"] = row?.actualRBoxDunQty;
        row["status"] = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;
      }
      await copyRPackageFunction(row);
      const msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN90042AINF",
        msg: [],
      });
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return { action: true, updated: row };
    } catch (error) {
      console.error(error);
      const err = responseErrors(error);
      setMsgError(err);
      return { action: false, updated: {} };
    }
  };
  const handleProcessRowUpdateError = useCallback(error => {
    console.error(` Error message from table:${error.message}`);
    // console.error(` children:${error.message}, severity: ${error}`);
  }, []);
  const processRowUpdate = async newRow => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      let updatedRow;
      let createNewRow;
      let response;
      if (newRow?.isNew) {
        const { action, updated } = await handleCreate(newRow);
        response = action;
        updatedRow = { ...updated, isNew: false };
      }
      // Copy Actual Operation
      else if (mode === ModeAction.COPY) {
        const { action, updated } = await handleCopyActualData(newRow);
        response = action;
        updatedRow = { ...updated };
      } else {
        const { action, updated, newContainer } = await handleUpdate(newRow);
        response = action;
        updatedRow = { ...updated };
        createNewRow = newContainer;
      }
      // throw to onProcessRowUpdate is error
      if (!response) return false;
      let tempRows;
      let isUpdateRow = false;
      let tempFormRows = rows;

      // let tempFormRows = form?.rows;
      tempRows = rows?.map((row, index) => {
        if (row.rowNumber === updatedRow.rowNumber) {
          isUpdateRow = true;
          return { ...tempFormRows[index], ...updatedRow };
        }
        return row;
      });

      let temp;
      if (!!createNewRow) {
        temp = isUpdateRow ? [...tempRows, createNewRow] : [...tempFormRows, updatedRow, createNewRow];
        setForm(prev => ({
          ...prev,
          rows: temp,
          [API_INVOICE_SHIPPING_INFO.CONT_SESSION_ID]: "",
        }));
        setRows(temp);
      } else {
        temp = isUpdateRow ? tempRows : [...tempFormRows, updatedRow];
        setForm(prev => ({
          ...prev,
          rows: temp,
          [API_INVOICE_SHIPPING_INFO.CONT_SESSION_ID]: "",
        }));
        setRows(temp);
      }
      startLoading();
      await updateTempContainer(temp, form?.shippingSessionId);

      // const body = {
      //   shippingInfoHID: form?.shippingHdrID,
      //   expCd: form?.exporterCd,
      //   impCompanyAbbr: form?.importerComp,
      //   etd: form?.etdDt, // DD/MM/YYYY
      //   impCd: form?.importerCd, // optional
      //   expCdSearch: "", // optional
      //   // sesionnId: form?.shippingSessionId, // optional
      //   [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
      //   [PAGINATION.ROW_PER_PAGE]: 20,
      // };
      // const searchData = await searchContainer(body);
      stopLoading();
      // const data = transformDataContainer(searchData?.result?.data, orderTypeList);
      // setRows(data);
      setOnSearch(true);

      setMode(ModeAction.VIEW);
      setRowModesModel({});
      setRowSelectionModel([]);
      return updatedRow;
    } catch (error) {
      console.error(error);
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  const calQty = async (containerList, rPkgList) => {
    console.log(containerList, rPkgList);

    try {
      let rmQty = 0;
      let otQty = 0;

      let containerNo;
      const tempContainer = [...containerList];
      for (const index in tempContainer) {
        rmQty = 0;
        otQty = 0;
        const container = tempContainer[index];
        containerNo = form?.containerType === "P" ? container?.planContainerNo : container?.actualContainerNo;
        if (containerNo === form?.containerNo) {
          for (const indx in rPkgList) {
            const rPackage = rPkgList[indx];

            let groupCd = rPackage?.groupCd;
            if (!rPackage?.groupCd) {
              groupCd = await sumQtyRPkg.checkGroup(rPackage?.rPkgType);
              rPackage.groupC = groupCd;
            }

            if (groupCd === GROUP_CODE_TYPE.R_MODULE) {
              rmQty += Number(rPackage?.planQty);
            } else if (groupCd === GROUP_CODE_TYPE.OTHER_MAT) {
              otQty += Number(rPackage?.planQty);
            }
          }
          container.planRModuleQty = rmQty;
          container.planRBoxDunQty = otQty;
          container.contSessionId = form?.contSessionId;
          break;
        }
        continue;
      }
      await updateTempContainer(tempContainer, form?.shippingSessionId);
      return;
    } catch (error) {
      stopLoading();
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  const handleCancel = async rowsMui => {
    try {
      if (mode === ModeAction.ADD) {
        startLoading();
        await updateTempContainer(rowsMui, form?.shippingSessionId);
        stopLoading();
      } else if (mode === ModeAction.EDIT) {
        const body = {
          shippingInfoDContId: form?.shippingInfoDContId,
          vanningInfoHId: form?.vanInfoHdrID, // optional
          containerNo: form?.containerNo,
          containerType: form?.containerType, // P = Plan, A = Actual
          [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
          [PAGINATION.ROW_PER_PAGE]: 10,
          sesionnId: "",
        };
        startLoading();
        const searchData = await searchRPackage(body);
        const formData = transformDataRPkg(searchData?.result?.data);
        await updateTempRPkg(formData, form?.contSessionId);
        await calQty(rows, formData);
        stopLoading();
      }
      return;
    } catch (error) {
      stopLoading();

      console.error(error);
    }
  };
  return (
    <React.Fragment>
      <Box>
        <DataGridTable
          id="table-container-sub"
          mode={mode}
          setMode={setMode}
          rows={rows}
          onCellClick={false}
          onCellDoubleClick={false}
          isMultipleSelection={true}
          checkboxSelection={true}
          disableRowSelectionOnClick={true}
          rowHeight={45}
          tableHeight="400px"
          classNameOfCell={classNameOfCell}
          handleClassNameRow={handleClassNameRow}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          handleChangePagination={handleChangePagination}
          column={{
            columns,
            columnGroupingModel,
            columnVisibilityModel: {
              shippingInfoDContId: false,
              vanningInfoHId: false,
              renbanNo: false,
            },
          }}
          rowSelection={{
            rowModesModel,
            setRowModesModel,
            rowSelectionModel,
            setRowSelectionModel,
          }}
          onPagination={false}
          pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
        />
      </Box>
      <ButtonSubmit
        mode={mode}
        setMode={setMode}
        rows={rows}
        setRows={setRows}
        rowModesModel={rowModesModel}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        setOnSearch={setOnSearch}
        executeFunction={handleCancel}
        functionId={functionId}
      />
    </React.Fragment>
  );
}
