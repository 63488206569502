import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { InputButton } from "components/UI";
import { ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";

const SubmitBar = ({
  mode,
  rows,
  setMode,
  setRows,
  selectedRowKeys,
  setSelectedRowKeys,
  selectedRows,
  setSelectedRows,
  processUpdateRow,
  setMsg: { setMsgAlert, setMsgError },
}) => {
  const confirmDialogCtx = useConfirmDialogContext();

  useMemo(() => mode, [mode]);

  const handleSave = async () => {
    try {
      const saveConfirm = await confirmDialogCtx.success({ type: "confirmSave" });
      if (!saveConfirm) {
        return;
      }
      let tempRowSelectedModel;
      if (mode === ModeAction.ADD) {
        tempRowSelectedModel = selectedRowKeys[0];
        return await processUpdateRow(tempRowSelectedModel);
      }
      // edit mode
      else if (mode === ModeAction.EDIT) {
        for (const [key, value] of Object.entries(selectedRows)) {
          tempRowSelectedModel = value ? rows.find((row, index) => index === Number(key)) : {};
        }
        setSelectedRowKeys([tempRowSelectedModel]);
        return await processUpdateRow(tempRowSelectedModel);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleCancel = async () => {
    try {
      const confirm = await confirmDialogCtx.success({ type: "confirmCancel" });
      if (!confirm) {
        return;
      }

      setMsgError([]);
      setMsgAlert([]);
      if (mode === ModeAction.ADD) {
        setSelectedRows({
          ...selectedRows,
        });
        setRows(rows.filter(row => !row.isNew)); //Keep only not new
      } else {
        let tempRowModesModel = selectedRows;
        const rowSelect = selectedRowKeys?.map(val => {
          return (tempRowModesModel = {
            ...tempRowModesModel,
          });
        });
        rowSelect && setSelectedRows(tempRowModesModel);
      }
      setSelectedRowKeys([]);
      setSelectedRows({});
      setMode(ModeAction.VIEW);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {[ModeAction.ADD, ModeAction.EDIT].includes(mode) && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton id="btn_save" value={"Save"} onClick={handleSave} />
          <InputButton id="btn_cancel" value={"Cancel"} onClick={handleCancel} />
        </Grid>
      )}
    </>
  );
};

export default SubmitBar;
