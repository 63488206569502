import React, { useState, useCallback, useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomButton = styled(Button)(({ theme }) => ({
  position: "relative",
  // display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  marginRight: "8px", // Adjust spacing between spinner and text
}));
const LoadingButton = ({
  id,
  value,
  variant = "contained",
  color = "primary",
  type = "button",
  disabled = false,
  onClick = undefined,
  size = "medium",
  style = {},
  props,
  minWidth = "100px",
  onKeyDown,
  loading,
  tabIndex = -1,
}) => {
  return (
    <CustomButton
      {...props}
      data-testid={id}
      id={id}
      tabIndex={tabIndex}
      type={type}
      variant={variant}
      color={color}
      disabled={disabled || loading}
      onClick={onClick}
      size={size}
      onKeyDown={onKeyDown}
      style={{
        textTransform: "none",
        marginRight: "8px",
        minWidth: minWidth,
        ...style,
      }}>
      {loading && <CustomCircularProgress size={24} />}
      <span>{value}</span>
    </CustomButton>
  );
};

export default LoadingButton;
