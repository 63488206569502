import React, { useState, useEffect } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
//UTIL
import { message, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
// COMPONENT
import { InputButton } from "components/UI";
//Hooks
import useButtonMode from "../hooks/useButtonMode";
// TYPE
import { API_NAME_IMPORT_INVOICE_DOWNLOAD, PAGINATION, ROW_PER_PAGE, BUTTON_VALUE, FIRST_PAGE } from "shared/constants";
import { ModeAction, MessageType } from "state/enum";
import { userProfile } from "constant";
import {
  useImportInvoiceDownloadMutation,
  useImportInvoiceDownloadSearchMutation,
} from "shared/services/invoice/import-invoice-download-service";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";

export default function ButtonBar({
  form,
  setForm,
  rows,
  setRows,
  columns,
  mode,
  setMode,
  onSearch,
  setOnSearch,
  setDisable,
  dataList: { modeList, dataTypeList, impExpList },
  getSearch,
  setMsg: { setMsgAlert, setMsgError },
  pagination: { pagination, pageNumber, setPagination, setPageNumber },
  rowSelection: { rowSelectionModel, setRowSelectionModel, rowModesModel, setRowModesModel },
  functionId,
  ...rest
}) {
  const userInfo = useSelector(state => state.auth.user);
  const { startLoading, stopLoading } = useLoadingContext();
  const importInvoiceDownloadSearch = useImportInvoiceDownloadSearchMutation();
  const importInvoiceDownload = useImportInvoiceDownloadMutation();

  // Button
  const [searchButton, setSearchButton] = useState(false);
  const [clearButton, setClearButton] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [deleteButton, setDeleteButton] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useButtonMode({
      mode,
      stateButton: {
        setSearchButton,
        setClearButton,
        setAddButton,
        setEditButton,
        setDeleteButton,
      },
    });
  }, [mode]);
  // ? Handle Button
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      // Reset Data of Table
      setOnSearch(false);
      setDisable(false);
      setMode(ModeAction.VIEW);
      setRows([]);
      setPagination({});
      setPageNumber(FIRST_PAGE);
      setRowSelectionModel([]);
      setRowModesModel({});

      const bodySearch = {
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.DATA_OWNER]: userProfile.dataOwner,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_FROM]: form?.etdFrom,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_TO]: form?.etdTo,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_FROM]: form?.etaFrom,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_TO]: form?.etaTo,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXPORTER_ID]: form?.exporterId,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.USER_ID]: userInfo.userName,
        [API_NAME_IMPORT_INVOICE_DOWNLOAD.USER_LOCATION]: userProfile.dataOwner,

        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };

      const { isSuccess, errors } = validationSearchForm({
        data: bodySearch,
        rule: [
          {
            field: API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_FROM,
            type: MessageType.EMPTY,
            key: ["ETD From"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();

      // ? SET BODY FOR SEARCH
      const searchData = await importInvoiceDownloadSearch(bodySearch);
      stopLoading();
      // ? TRANSFORM DATA
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ROW_NUMBER]: item.rowNumber,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXP_COMPANY_ABBR]: item.expCompanyAbbr,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_NO]: item.invNo,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXP_CD]: item.expCd,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_CD]: item.impCd,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_DT]: item.invDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_DT]: item.etdDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_DT]: item.etaDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.VESSEL_NAME]: item.vesselName,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.R_PKG_TYPE]: item.rPkgType,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.R_PKG_QTY]: item.rPkgQty,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_CUSTOMS_STK_DT]: item.impCustomsStkDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_BROKER_COMPANY_ABBR]: item.impBrokerCompanyAbbr,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_BROKER_COMPANY_ID]: item.impBrokerCompanyId,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.IMP_ENTRY_NO]: item.impEntryNo,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.ORG_BL_FILE_NAME]: item.orgBlFileName,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.BL_FILE_NAME]: item.blFileName,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.NON_COMM_INV_HID]: item.nonCommInvHId,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPDATE_DT]: item.updateDt,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.UPDATE_BY]: item.updateBy,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.INV_EDITABLE]: item.invEditable,
          [API_NAME_IMPORT_INVOICE_DOWNLOAD.TOTAL_M3_DISP_FLAG]: item.totalM3DispFlag,
        })) ?? [];

      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleClear = () => {
    setMsgError([]);
    setMsgAlert([]);

    // reset form
    setForm({
      [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_FROM]: "",
      [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_TO]: "",
      [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_FROM]: "",
      [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_TO]: "",
      [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXPORTER_ID]: "",
    });
    setDisable(false);
    setMode(ModeAction.VIEW);
    setOnSearch(false);
    setRows([]);
    setRowSelectionModel([]);
    setRowModesModel({});
    setPagination({});
    setPageNumber(1);
    document.getElementById("input_etd_from").focus();
  };

  const formatDate = dateStr => {
    const [day, month, year] = dateStr?.split("/");
    return `${year}-${month}-${day}`;
  };

  const handleDownloadInvoice = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);

    const formattedData = rowSelectionModel.map(val => {
      const row = rows.find(v => v.rowNumber === val);
      return {
        dataOwner: userProfile.dataOwner,
        invoiceNo: row ? row.invNo : "",
        fileType: "PDF",
        invoiceDt: row ? `${row.invDt.split("/")[2]}-${row.invDt.split("/")[1]}-${row.invDt.split("/")[0]}` : "",
        expCd: row ? row.expCd : "",
        impCd: row ? row.impCd : "",
        isZip: true,
        totalsM3Flag: "N",
      };
    });

    try {
      const option = {
        dataOwner: userProfile.dataOwner,
        invoiceNo: formattedData.map(v => v.invoiceNo),
        fileType: "PDF",
        invoiceDt: formattedData.map(v => v.invoiceDt),
        expCd: formattedData.map(v => v.expCd),
        impCd: formattedData.map(v => v.impCd),
        isZip: true,
        totalsM3Flag: "N",
      };
      startLoading();
      const { data } = await importInvoiceDownload(option);
      waitAndDownloadExcel(60, data, setMsgError, stopLoading);
      return;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleEdit = () => {
    if (rowSelectionModel.length > 1) {
      // setMsgError();
      alert(message({ type: "singleRecord" }));
      setRowSelectionModel([]);
      setAddButton(false);
      setEditButton(true);
      setDeleteButton(true);
      return;
    }

    const checkInvEditable = rows?.find(v => v.rowNumber === rowSelectionModel[0]).invEditable === "false";

    if (checkInvEditable) {
      setMsgError([
        "MDN95042AERR: Cannot edit Non-commercial invoice data because system already proceed Customs Stock Updating process",
      ]);
      setRowSelectionModel([]);
      setAddButton(false);
      setEditButton(true);
      setDeleteButton(true);
      return;
    }

    setMsgError([]);
    setMsgAlert([]);
    setMode(ModeAction.EDIT);
    setForm(old => ({
      ...old,
    }));
    let tempRowModesModel = rowModesModel;

    const rowSelect = rowSelectionModel.map(val => {
      return (tempRowModesModel = {
        ...tempRowModesModel,
        [val]: { mode: GridRowModes.Edit },
      });
    });

    if (rowSelect) {
      setRowModesModel(tempRowModesModel);
      console.log("x", rowSelectionModel);
    }
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton
            id="btn_search"
            data-testid="btn_search"
            value={BUTTON_VALUE.SEARCH}
            disabled={searchButton}
            onClick={handleSearch}
            name={`${functionId}Search`}
            tabIndex={1}
          />
          <InputButton
            id="btn_clear"
            value={BUTTON_VALUE.CLEAR}
            disabled={clearButton}
            onClick={handleClear}
            name={`${functionId}Clear`}
            tabIndex={1}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {rows.length ? (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <InputButton
              id="btn_add"
              value={"Download Invoice"}
              onClick={handleDownloadInvoice}
              disabled={addButton || !rowSelectionModel.length}
              name={`${functionId}DownloadInvoice`}
              tabIndex={1}
            />
            <InputButton
              id="btn_edit"
              value={"Edit Customs Info."}
              onClick={handleEdit}
              disabled={addButton || !rowSelectionModel.length}
              name={`${functionId}EditCustomsInfo`}
              tabIndex={1}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
}
