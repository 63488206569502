import React, { Fragment, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
//UTIL
import { isUndefined } from "lodash";
import { responseErrors } from "utils";
// Share Component
import { AlertMessage, PopupDialog } from "components/UI";
import { HeaderContentBar } from "components/Layout";
// Page component
import ApmcSourceDataScreen from "./components/ViewApmcSourceDataDetail";
import { SubHeaderSection, ButtonBar, BodySection, HeaderSection } from "./components";
//Hook
import { useColumn } from "./hooks/useColumn";
import useLocalStorage from "shared/hooks/useLocalStorage";
// Type
import { userProfile } from "constant";
import { ModeAction } from "state/enum";
import { DEFAULT_VALUE } from "./constants/monthly.type";
import { API_MONTHLY, PAGINATION, DEFAULT_LOCAL_STATE, FIRST_PAGE } from "shared/constants";
//API
import {
  useGetForecastTypeQuery,
  useGetForecastModeDescQuery,
  useGetForecastDataTypeQuery,
  useGetForecastUploadStatusQuery,
  useMonthlySearchMutation,
  useSearchApmcMutation,
} from "shared/services/inventory-forecast";
import transformResponse from "./hooks/transformData";
import { useSelector } from "react-redux";

export default function ForecastScreen() {
  const userInfo = useSelector(state => state.auth.user);

  const [logDetail, setLogDetail] = useLocalStorage("logDetail", DEFAULT_LOCAL_STATE);

  const [searchForm, setSearchForm] = useState({
    ...DEFAULT_VALUE,
    [API_MONTHLY.USER_ID]: userInfo.userName,
    [API_MONTHLY.USER_COMPANY_ABBR]: userInfo.companyAbbr,
  });

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);
  const [onSearchAdd, setOnSearchAdd] = useState(false);

  // FORECAST
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  // APMC
  const [onOpen, setOnOpen] = useState(false);
  const [formApmc, setFormApmc] = useState({});
  const [dataApmc, setDataApmc] = useState({ searchData: {}, data: [] });

  // ? API
  // INFO QUERY DATA
  const { data: forecastTypeList } = useGetForecastTypeQuery();
  const { data: modeList } = useGetForecastModeDescQuery();
  const { data: dataTypeList } = useGetForecastDataTypeQuery();
  const { data: uploadStatusList } = useGetForecastUploadStatusQuery();
  // INFO MUTATION
  const searchMonthly = useMonthlySearchMutation();
  const searchApmc = useSearchApmcMutation();

  // ? Upload File
  const handleUploadFile = async (field, file, maxLength) => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      if (isUndefined(file)) {
        setSearchForm(prev => ({
          ...prev,
          file: {},
          [API_MONTHLY.ORG_FILE_NAME]: "",
        }));
        return true;
      }

      setSearchForm(prev => ({
        ...prev,
        file: file,
        [API_MONTHLY.ORG_FILE_NAME]: file.name,
      }));

      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // get search
  const getSearch = async body => {
    try {
      console.log(body);

      const searchData = await searchMonthly(body);
      const data = transformResponse(searchData?.result?.data);
      return { searchData, data };
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
    }
  };
  const handleApmcScreen = async (value, event) => {
    try {
      event.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setFormApmc({
        [API_MONTHLY.FORECAST_TYPE_CD]: value?.forecastType,
        [API_MONTHLY.FORECAST_MONTH]: value?.forecastMonth,
        [API_MONTHLY.DATA_TYPE]: value?.dataType,
      });

      const body = {
        [API_MONTHLY.FORECAST_TYPE_CD]: value.forecastType,
        [API_MONTHLY.FORECAST_MONTH]: value.forecastMonth,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
      };

      const searchData = await searchApmc(body);

      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_MONTHLY.ROW_NUMBER]: item?.rowNumber,
          [API_MONTHLY.FORECAST_TYPE]: item?.forecastType,
          [API_MONTHLY.FORECAST_MONTH]: item?.forecastMonth,
          [API_MONTHLY.DATA_TYPE]: item?.dataType,
          [API_MONTHLY.DATA_TYPE_CD]: item?.dataTypeCd,
          [API_MONTHLY.ORG_FILE_NAME]: item?.orgFileName,
          [API_MONTHLY.UPLOAD_STATUS]: item?.uploadStatus,
          [API_MONTHLY.UPLOAD_BY]: item?.uploadBy,
          [API_MONTHLY.UPLOAD_DATE]: item?.uploadDt,
          [API_MONTHLY.USE_STATUS]: item?.useSts,
          [API_MONTHLY.USE_DATE]: item?.useDt,
          [API_MONTHLY.USE_BY]: item?.useBy,
          [API_MONTHLY.FILE_UPLOAD_ID]: item?.fileUploadId,
          [API_MONTHLY.UPDATE_DATE]: item?.updateDt,
          [API_MONTHLY.UPDATE_BY]: item?.updateBy,
          [API_MONTHLY.MODULE_ID]: item?.moduleId,
          [API_MONTHLY.FUNCTION_ID]: item?.functionId,
          [API_MONTHLY.D_HODTCRE]: item?.dHODTCRE,
        })) ?? [];
      setDataApmc({ searchData: searchData, data: data });
      setOnOpen(!onOpen);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleLinkUploadStatus = async (value, event) => {
    try {
      event.preventDefault();
      const monthlyState = {
        dataOwner: userProfile.dataOwner,
        moduleId: value?.moduleId,
        functionId: value?.functionId,
        statusId: "",
        userId: value?.uploadBy,
        appId: value?.aplId,
        dateFrom: value?.uploadDt,
        dateTo: value?.uploadDt,
        levelId: "",
      };
      setLogDetail(monthlyState);
      if (process.env.REACT_APP_SITE === "/") {
        window.open("/common/monitoring/log-monitoring-detail", "_blank");
      } else {
        window.open(
          `${process.env.REACT_APP_SITE ? process.env.REACT_APP_SITE + "/common/monitoring/log-monitoring-detail" : "/common/monitoring/log-monitoring-detail"}`,
          "_blank"
        );
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleLinkGenerateStatus = async (value, event) => {
    try {
      event.preventDefault();
      const monthlyState = {
        dataOwner: userProfile.dataOwner,
        moduleId: value?.moduleId,
        functionId: value?.functionId,
        statusId: "",
        userId: "",
        appId: "",
        dateFrom: value?.useDt,
        dateTo: value?.useDt,
        levelId: "",
      };
      setLogDetail(monthlyState);
      if (process.env.REACT_APP_SITE === "/") {
        window.open("/common/monitoring/log-monitoring-detail", "_blank");
      } else {
        window.open(
          `${process.env.REACT_APP_SITE ? process.env.REACT_APP_SITE + "/common/monitoring/log-monitoring-detail" : "/common/monitoring/log-monitoring-detail"}`,
          "_blank"
        );
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const columns = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useColumn({
        searchForm,
        rows,
        mode,
        setOnOpen,
        onOpen,
        setMsg: { setMsgAlert, setMsgError },
        dataList: { dataTypeList: dataTypeList, modeList: modeList },
        handleUploadFile,
        handleApmcScreen,
        handleLinkUploadStatus,
        handleLinkGenerateStatus,
        onSearchAdd,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, mode, forecastTypeList, modeList, onOpen]
  );
  return (
    <Box sx={{ padding: "1rem" }}>
      <HeaderContentBar title="WDN92010 : Monthly Data Upload Screen" />
      <AlertMessage type={"warning"} message={msgError} />
      <AlertMessage type={"success"} message={msgAlert} />
      <Grid container spacing={0} sx={{ alignItems: "center", marginTop: "10px" }}>
        <Grid item xs={12}>
          <HeaderSection
            setOnOpen={setOnOpen}
            mode={mode}
            setMode={setMode}
            setRows={setRows}
            searchForm={searchForm}
            setSearchForm={setSearchForm}
            setOnSearch={setOnSearch}
            setRowModesModel={setRowModesModel}
            setRowSelectionModel={setRowSelectionModel}
            setPagination={setPagination}
            setPageNumber={setPageNumber}
            forecastTypeList={forecastTypeList}
            setMsg={{ setMsgError, setMsgAlert }}
          />
        </Grid>
        <Grid item xs={8}></Grid>
        {searchForm?.forecastTypeCd &&
          (searchForm?.forecastTypeCd === "M" ? (
            <>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <SubHeaderSection
                  dataList={{
                    uploadStatusList: uploadStatusList,
                    modeList: modeList,
                    dataTypeList: dataTypeList,
                  }}
                  mode={mode}
                  searchForm={searchForm}
                  setSearchForm={setSearchForm}
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonBar
                  setOnOpen={setOnOpen}
                  dataList={{
                    uploadStatusList: uploadStatusList,
                    modeList: modeList,
                    dataTypeList: dataTypeList,
                  }}
                  form={searchForm}
                  setSearchForm={setSearchForm}
                  getSearch={getSearch}
                  mode={mode}
                  setMode={setMode}
                  rows={rows}
                  setRows={setRows}
                  columns={columns}
                  setMsg={{ setMsgError, setMsgAlert }}
                  setOnSearch={setOnSearch}
                  rowModesModel={rowModesModel}
                  setRowModesModel={setRowModesModel}
                  rowSelectionModel={rowSelectionModel}
                  setRowSelectionModel={setRowSelectionModel}
                  setPagination={setPagination}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  functionId={"WDN92010"}
                  setOnSearchAdd={setOnSearchAdd}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <BodySection
                  dataList={{
                    modeList: modeList,
                  }}
                  form={searchForm}
                  mode={mode}
                  rows={rows}
                  onSearch={onSearch}
                  setOnSearch={setOnSearch}
                  rowSelectionModel={rowSelectionModel}
                  setRowSelectionModel={setRowSelectionModel}
                  setMode={setMode}
                  setRows={setRows}
                  rowModesModel={rowModesModel}
                  columns={columns}
                  setRowModesModel={setRowModesModel}
                  pagination={pagination}
                  setPagination={setPagination}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  setMsg={{ setMsgError, setMsgAlert }}
                  getSearch={getSearch}
                  onSearchAdd={onSearchAdd}
                />
              </Grid>
            </>
          ) : (
            // For Yearly Forecast Screen
            ""
          ))}
      </Grid>

      {/* Popup APMC */}
      <PopupDialog
        onOpen={onOpen}
        onClick={() => setOnOpen(!onOpen)}
        content={
          <ApmcSourceDataScreen
            formApmc={formApmc}
            dataApmc={dataApmc}
            onOpen={onOpen}
            setOnOpen={setOnOpen}
            mode={mode}
            setMode={setMode}
            onSearch={onSearch}
            handleLinkUploadStatus={handleLinkUploadStatus}
          />
        }
      />
    </Box>
  );
}
