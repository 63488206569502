import { COMMON_ENUM } from "shared/constants";
import { useMutateData, useMutateDownload, useCustomQuery } from "shared/services/base.service";
import { isBlankOrNull } from "utils";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useGetCountry = ({ dataOwner, countryCd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/path/country/${dataOwner}/${countryCd}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetCountryMaster = ({ category, subCategory }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/country/company/${category}/${subCategory}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRoutePointInTable = countryCd => {
  return useCustomQuery({
    url: `${BaseServicePath}/path/route-point/${COMMON_ENUM.C_SM_CTG_TMAP_EM}/${countryCd}`,
    method: "GET",
    enabled: !isBlankOrNull(countryCd),
    select: data => data.result,
  });
};

export const useGetRoutePointForm = countryCd => {
  return useCustomQuery({
    key: ["route-point-from", { countryCd }],
    url: `${BaseServicePath}/path/route-point/${COMMON_ENUM.C_SM_CTG_TMAP_EM}/${countryCd}/FROM_COUNTRY_CD`,
    method: "GET",
    enabled: !isBlankOrNull(countryCd),
    select: data => data.result,
  });
};

export const useGetRoutePointTo = countryCd => {
  return useCustomQuery({
    key: ["route-point-to", { countryCd }],
    url: `${BaseServicePath}/path/route-point/${COMMON_ENUM.C_SM_CTG_TMAP_EM}/${countryCd}/TO_COUNTRY_CD`,
    method: "GET",
    enabled: !isBlankOrNull(countryCd),
    select: data => data.result,
  });
};

export const useSearchPathMaster = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/path/search`,
      method: "POST",
      data: body,
    });
  };
};
export const useCreatePathMaster = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [["route-point-from"], ["route-point-to"]],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/path/create`,
      method: "POST",
      data: body,
    });
  };
};
export const useUpdatePathMaster = (countryFrom, countryTo) => {
  const { mutate } = useMutateData({
    invalidateKeys: [
      ["route-point-from", { countryCd: countryFrom }],
      ["route-point-to", { countryCd: countryTo }],
    ],
  });
  return async (body, pathId) => {
    return await mutate(
      {
        url: `${BaseServicePath}/path/edit/${pathId}`,
        method: "PATCH",
        data: body,
      },
      {
        onSuccess: () => {},
      }
    );
  };
};
export const useDeletePathMaster = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [["route-point-from"], ["route-point-to"]],
  });
  return async (body, pathId) => {
    return await mutate({
      url: `${BaseServicePath}/path/delete/${pathId}`,
      method: "DELETE",
      data: body,
    });
  };
};

export const usePathMasterDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate(
      {
        url: `${BaseServicePath}/path/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },
      {
        onSuccess: response => {
          saveAs(response);
        },
      }
    );
  };
};
