import { useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useSearchSafetyStockandOverflow = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/safety-overflow-stock/search`,
      method: "POST",
      data: body,
    });
  };
};

export const useCreateSafetyStockandOverflow = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, type) => {
    return await mutate({
      url: `${BaseServicePath}/safety-overflow-stock/${type}/create`,
      method: "POST",
      data: body,
    });
  };
};

export const useUpdateSafetyStockandOverflow = () => {
  // return useMutation({
  //   url: `/safety-overflow-stock/${type}/edit/${id}`,
  //   method: "PATCH",
  // });
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, type, id) => {
    return await mutate({
      url: `${BaseServicePath}/safety-overflow-stock/${type}/edit/${id}`,
      method: "PATCH",
      data: body,
    });
  };
};

export const useDeleteSafetyStockandOverflow = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, type, id) => {
    return await mutate({
      url: `${BaseServicePath}/safety-overflow-stock/${type}/delete/${id}`,
      method: "DELETE",
      data: body,
    });
  };
};

export const useDownloadExcel = () => {
  // return useMutation({
  //   url: `/safety-overflow-stock/${type}/download/excel`,
  //   method: "POST",
  //   data: { ...params },
  // });
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, type) => {
    return await mutate({
      url: `${BaseServicePath}/safety-overflow-stock/${type}/download/excel`,
      method: "POST",
      data: body,
    });
  };
};
export const useSafetyStockDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async (body, masterTypeCd) => {
    return await mutate(
      {
        url: `${BaseServicePath}/safety-overflow-stock/${masterTypeCd === "S" ? "safety" : "overflow"}/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },

      {
        onSuccess: response => {
          saveAs(response);
          // const blob = new Blob([data], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // });
          // (blob, `${fileName}`);
        },
      }
    );
  };
};
