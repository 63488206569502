import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useGetType = () => {
  const data = useCustomQuery({
    url: `${BaseServicePath}/category/gentan-i/type/COMMON/GENTAN_I_TYPE`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: false,
  });
  return data;
};
export const useGetImpExpCdDropDown = ({ type }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/gentan-i/imp-exp/${type}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetImpExpCdTableDropDown = ({ type }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/gentan-i/imp-exp/plant/${type}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRpkgTypeTableDropDown = ({ dataOwner, rPkgOwnerCompAbbr }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/gentan-i/r-package/company/${dataOwner}/${rPkgOwnerCompAbbr}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetCarFamilyCodeTableDropDown = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/car/CAR_FAMILY_CD`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetSubSeriesTableDropDown = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/car/SUB_SERIES`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};
