import React, { useEffect } from "react";
import { Box, Button, IconButton, Popover, TextField, styled } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon, DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputMask from "react-input-mask";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

import { getLocalDate } from "utils/init-config-date";
import dayjs from "dayjs";
const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    backgroundColor: theme.palette.primary.light,
    textAlign: "left",
    padding: "5px 10px",
  },
  "& .MuiInput-root:focus": {
    border: `2px solid black !important`,
    borderRadius: ".3rem",
    color: "#000",
  },
}));
const StyledDateCalendar = styled(DateCalendar)(({ theme, error }) => ({
  "& .MuiButtonBase-root": {
    "&.MuiPickersDay-root:hover": {
      backgroundColor: `${theme.palette.secondary.medium}`,
    },
  },
  "& .Mui-selected": { backgroundColor: `${theme.palette.tertiary.chip}`, color: "#000000" },
}));
const TanStackDatePickerField = ({
  // renderValue,
  // getValue,
  row,
  column,
  table,
  id,
  onChange,
  value,
  required,
  fullWidth = false,
  disabled = false,
  autoFocus = false,
  // defaultValue = "",
  format = "DD/MM/YYYY",
  mask = "DD/MM/YYYY",
  disablePast = false,
  disableKey = false,
  views = ['day', 'month', 'year'],
  // setValueNull = false,
  holidayList,
  // ...props
}) => {
  const priority = {
    'day': 1, 'month': 2, 'year': 3
  };
  const state = views.sort((a, b) => priority[a] - priority[b]);
  
  const tableMeta = table.options.meta;
  const rowData = row.original;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [select, setSelect] = React.useState(getLocalDate());
   const [currentView, setCurrentView] = React.useState();
   const [date, setDate] = React.useState(value);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    if (disabled) {
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
    setCurrentView(state[0])
  };

  const handleClose = React.useCallback((e, reason) => {
    if (reason === "backdropClick") {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(null);
  },
  []
);

  const handleSelect = date => {
    try {
      setSelect(date);
      setDate(dayjs(date).format(format));
      onChange(dayjs(date).format(format));
      tableMeta?.updateData({
        rowIndex: rowData?.rowNumber,
        columnId: column.id,
        value: dayjs(date).format(format),
      });

      if (currentView === state[0]) {
        handleClose()
      }

      return;
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = event => {
    const newValue = event.target.value;
    if (!newValue) {
      setDate("");
      setSelect(getLocalDate());
      tableMeta?.updateData({ rowIndex: rowData?.rowNumber, columnId: column.id, value: newValue });
      return;
    }
    setDate(newValue);
    tableMeta?.updateData({ rowIndex: rowData?.rowNumber, columnId: column.id, value: newValue });

    if (!dayjs(newValue, format, true).isValid()) {
      setSelect(getLocalDate());
      return;
    }
    setSelect(dayjs(newValue, format));
  };

  const formattedValue = date => {
    try {
      // date equal null
      if (!date) {
        return "";
      }
      if (typeof date === "string") {
        return dayjs(date, format);
      }

      return date;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const shouldDisableDate = day => {
    const formattedDay = day.format("YYYY-MM-DD");
    return holidayList.includes(formattedDay);
  };

  useEffect(() => {
    if (disabled) {
      setDate("");
      setSelect(null);
      onChange(null);
      tableMeta?.updateData({
        rowIndex: rowData?.rowNumber,
        columnId: column.id,
        value: null,
      });
    } else {
      setDate(value);
      setSelect(getLocalDate(value, format));
    }
  }, [value, disabled, onChange, tableMeta, rowData?.rowNumber, column.id, format]);

  const handlerArrow = (props, state) => {
    const date = dayjs(select).add(state === 'left' ? 1 : -1, 'month');
    props.onMonthChange(date, state);
    setSelect(date);
  }

  const onMonthChange = () => {
    if (views.includes('day')) {
      setCurrentView('day')  
    }
  }

  return (
    <Box width={"100%"} sx={{ display: "flex", justifyContent: "center", flexWrap: "row wrap", alignItems: "center" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputMask
          id={id}
          data-testid={id}
          mask={mask}
          formatChars={{
            Y: "[0-9]",
            M: "[0-9]",
            D: "[0-9]",
          }}
          value={date}
          required={required}
          disabled={disabled || disableKey}
          fullWidth={fullWidth}
          onChange={handleChange}
          placeholder={format}
          maskChar={null}
          alwaysShowMask={false}
          autoFocus={autoFocus}>
          {inputProps => <StyledTextField disabled={disabled || disableKey} inputProps={{...inputProps}} />}
        </InputMask>
        <IconButton onClick={handleClick}>
          <InsertInvitationIcon sx={{ color: "#58595B", ml: 1, mb: "2px" }} />
        </IconButton>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}>
          <StyledDateCalendar
            views={views}
            value={formattedValue(select)}
            onChange={handleSelect}
            disablePast={disablePast}
            onViewChange={(v) => setCurrentView(v)}
            onYearChange={() => setCurrentView('month')}
            onMonthChange={onMonthChange}
            openTo={currentView}
            shouldDisableDate={shouldDisableDate}
            slots={{ 
              calendarHeader: (props) => {
                return (
                  <Box sx={{ pl: '24px', pr: '24px', mt: '16px', mb: '8px', display: 'flex', alignItems: 'center' }}>
                    <Button 
                      sx={{
                        'font-size': '14px',
                        color: '#000000'
                      }} 
                      onClick={() => setCurrentView('year')}
                    >
                      {select.year()}
                    </Button>
                    <Button 
                      sx={{
                        'font-size': '14px',
                        color: '#000000',
                        'text-transform': 'capitalize'
                      }} 
                      onClick={() => setCurrentView('month')}
                    >
                      {dayjs().month(select.month()).format('MMMM')}
                    </Button>

                    <Box sx={{ ml: 'auto' }}>
                      <ArrowLeftIcon
                        sx={{
                          cursor: 'pointer'
                        }}
                        onClick={() => handlerArrow(props, 'right')}
                      />
                      <ArrowRightIcon 
                        sx={{
                          cursor: 'pointer',
                          ml: '2rem'
                        }}
                        onClick={() => handlerArrow(props, 'left')}
                      />
                    </Box>
                  </Box>
                )
              },
            }}
          />
        </Popover>
      </LocalizationProvider>
    </Box>
  );
};

export default TanStackDatePickerField;
