import React, { Fragment } from "react";
import { Typography } from "components/UI";

import { Checkbox, FormControlLabel } from "@mui/material";
export default function CheckBox({
  id,
  placement = "start",
  label,
  onChange,
  checked = false,
  tabIndex = -1,
  ...props
}) {
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      onChange({
        target: {
          value: id,
          checked: !checked,
        },
      });
    }
  };

  return (
    <Fragment>
      <FormControlLabel
        {...props}
        value={placement}
        labelPlacement={placement}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        label={
          <Typography
            content={label}
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
            }}
            noWrap={false}
          />
        }
        onChange={onChange}
        control={<Checkbox data-testid={id} id={id} size="medium" checked={checked} {...props} />}
      />
    </Fragment>
  );
}
