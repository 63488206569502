import React from "react";
import { Box } from "@mui/material";
import { DataGridTable, SubmitBar } from "components/UI";
//Utils
import { responseErrors } from "utils";
import { transformData } from "../hooks/tranformData";
//Service
import { useExportInvoiceSearchMutation } from "shared/services/invoice";
//Type
import { PAGINATION } from "shared/constants";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";
export default function TableSection(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    form,
    setOnSearch,
    setMsg: { setMsgError },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    className,
  } = props;
  const userInfo = useSelector(state => state.auth.user);
  const searchExportInfo = useExportInvoiceSearchMutation();
  // TODO: API create
  const { startLoading, stopLoading } = useLoadingContext();
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        importerId: form?.importerId,
        vesselName: form?.vesselName,
        invoiceNo: form?.invoiceNo,
        etdFrom: form?.etdFrom,
        etdTo: form?.etdTo,
        loginUserCompanyAbbr: userInfo.companyAbbr, // TODO: mock constants here
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: pagination.rowsPerPage,
      };
      startLoading();
      const searchData = await searchExportInfo(body);
      stopLoading();
      const data = transformData(searchData?.result?.data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  return (
    <Box sx={{ mt: 3 }} className={className}>
      <DataGridTable
        id="table-container"
        mode={mode}
        setMode={setMode}
        rows={rows}
        onCellClick={false}
        checkboxSelection={true}
        processRowUpdate={null}
        slotProps={{
          baseCheckbox: {
            tabIndex: -1,
          },
        }}
        column={{
          columns,
        }}
        rowSelection={{
          rowModesModel,
          setRowModesModel,
          rowSelectionModel,
          setRowSelectionModel,
        }}
        pagination={{
          pagination: pagination,
          pageNumber: pageNumber,
          handleChangePagination: handleChangePagination,
        }}
        isMultipleSelection={false}
      />
      <SubmitBar
        setOnSearch={setOnSearch}
        mode={mode}
        rows={rows}
        setMode={setMode}
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowModesModel={rowModesModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        noRoleRequired={true}
        tabIndex={-1}
      />
    </Box>
  );
}
