import React, { useState, useMemo } from "react";
// Component
import { Grid } from "@mui/material";
import MappingFilterBar from "pages/Forecast/ShipmentManagementScreen/components/Mapping/components/MappingFilterBar";
import MappingTable from "pages/Forecast/ShipmentManagementScreen/components/Mapping/components/MappingTable";
import MappingButtonBar from "pages/Forecast/ShipmentManagementScreen/components/Mapping/components/MappingButtonBar";
// Utils
import { isUndefined } from "lodash";
import { findObject, messageDisplay, responseDownloadFileErrors, responseErrors } from "utils";
import { validationSearchForm, validationUploadFile } from "utils/validation";
import { formatRowsMapping } from "pages/Forecast/ShipmentManagementScreen/utils/formatData";
// Hook
import useColMapping from "pages/Forecast/ShipmentManagementScreen/hooks/useColMapping";
// Service
import {
  useMappingExportPdfMutation,
  useMappingUploadMutation,
  useShipmentSearchMutation,
} from "shared/services/inventory-forecast";

// Type
import { MessageType, MSG_TYPE } from "state/enum";
import { API_SHIPMENT, ROW_PER_PAGE, WEB_FUNCTION_ID } from "shared/constants";
export default function Mapping(props) {
  const {
    setForm,
    form,
    onSearch,
    setOnSearch,
    mode,
    setMode,
    setAppId,
    setMsg: { setMsgAlert, setMsgError },
    dataList: { exporterList, importerList, rPkgList },
    functionId,
  } = props;
  //API
  const searchShipment = useShipmentSearchMutation();
  const uploadPdf = useMappingUploadMutation();
  const exportPdf = useMappingExportPdfMutation();
  // Table

  const [rows, setRows] = useState([]);
  const [rPkg, setRPkg] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  // edit
  const handleUploadFilePdf = async ({ field, file }) => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      const fieldPath =
        field === API_SHIPMENT.ORG_NCV_FILE_NAME ? API_SHIPMENT.NCV_FILE_UPLOAD : API_SHIPMENT.BL_FILE_UPLOAD;
      const fileName =
        field === API_SHIPMENT.ORG_NCV_FILE_NAME ? API_SHIPMENT.NCV_FILE_NAME : API_SHIPMENT.BL_FILE_NAME;
      const fileOrgName =
        field === API_SHIPMENT.ORG_NCV_FILE_NAME ? API_SHIPMENT.ORG_NCV_FILE_NAME : API_SHIPMENT.ORG_BL_FILE_NAME;
      const fileType = field === API_SHIPMENT.ORG_NCV_FILE_NAME ? "nvc" : "bl";

      // Clear file when don't have file
      if (isUndefined(file)) {
        console.log("handleUploadFilePdf");

        setForm(prev => ({
          ...prev,
          [fileOrgName]: {},
          [fileName]: "",
          [fieldPath]: "",
        }));
        return true;
      }
      const uploadData = new FormData();

      if (field === API_SHIPMENT.ORG_NCV_FILE_NAME) {
        uploadData.append([API_SHIPMENT.NCV_FILE_NAME], file.name);
        uploadData.append([API_SHIPMENT.UPDATE_BY], form.userId);
        uploadData.append([API_SHIPMENT.FUNCTION_ID], WEB_FUNCTION_ID.FORECAST_SHIPMENT);
        uploadData.append([API_SHIPMENT.FILE], file);
      } else {
        uploadData.append([API_SHIPMENT.BL_FILE_NAME], file.name);
        uploadData.append([API_SHIPMENT.UPDATE_BY], form.userId);
        uploadData.append([API_SHIPMENT.FUNCTION_ID], WEB_FUNCTION_ID.FORECAST_SHIPMENT);
        uploadData.append([API_SHIPMENT.FILE], file);
      }

      const response = await uploadPdf(fileType, uploadData);
      setForm(prev => ({
        ...prev,
        [fileOrgName]: file,
        [fileName]: response.result.filename,
        [fieldPath]: response.result.path,
      }));
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return false;
    }
  };
  const handleUploadFile = async ({ field, file, maxLength }) => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      // Clear file when don't have file
      if (isUndefined(file)) {
        setForm(prev => ({
          ...prev,
          [API_SHIPMENT.FILE]: { [field]: {} },
          [API_SHIPMENT.FILE_NAME]: { [field]: "" },
        }));
        return true;
      }
      // validate
      const { isSuccess, errors } = validationUploadFile({
        file: file,
        rule: [
          {
            type: MessageType.TYPE_EXCEL,
            key: [file.name, "Excel"],
          },
          {
            type: MessageType.FILE_NAME,
            key: [`Uploaded File Name`, maxLength],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return false;
      }
      setForm(prev => ({
        ...prev,
        [API_SHIPMENT.FILE]: { [field]: file },
        [API_SHIPMENT.FILE_NAME]: { [field]: file.name },
      }));

      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleLink = async fileName => {
    try {
      await exportPdf(fileName);
    } catch (error) {
      //MSTD0013AERR: /home/TMAP/DND/shared/TMAP-EM/data/upload/DN95050_20240802215111707.PDF file does not exist
      const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(error.response.data)));
      const objRes = JSON.parse(text);
      if ("statusCode" in objRes) {
        if (objRes.statusCode === 404) {
          const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0013AERR", msg: [fileName] });
          setMsgError([msg]);
          return;
        }
      }
      const errors = responseDownloadFileErrors(error);
      // const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColMapping({
      rPkg,
      mode,
      rows,
      selectedRows,
      selectedRowKeys,
      handleUploadFile,
      handleUploadFilePdf,
      handleLink,
      setSelectedRows,
      tabIndex: 1
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rPkg, mode, rows]);

  const getSearch = async body => {
    try {
      const searchData = await searchShipment(body);
      if (!searchData?.result?.data?.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] });
        setMsgError([msg]);
        return false;
      }
      const { rows, rPkg } = formatRowsMapping(searchData?.result?.data);
      return { searchData, rows, rPkg };
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  const refresh = async () => {
    try {
      const bodySearch = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.OPERATION]: form?.operationId,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? findObject({ data: importerList, value: form?.importerId, property: "companyId", field: "companyAbbr" })
          : "",
        [API_SHIPMENT.R_RKG_OWNER]: form.rPkgOwnerId
          ? findObject({
              data: rPkgList,
              value: form?.rPkgOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            })
          : "",
        [API_SHIPMENT.FORECAST_MONTH]: form.forecastMonth,
        [API_SHIPMENT.SHIPMENT_STATUS]: form.shipmentStsId,
        [API_SHIPMENT.ETD_FORM]: form.etdFrom,
        [API_SHIPMENT.ETD_TO]: form.etdTo,
        [API_SHIPMENT.ETA_FORM]: form.etaFrom,
        [API_SHIPMENT.ETA_TO]: form.etaTo,
        pageNumber: pageNumber,
        rowsPerPage: ROW_PER_PAGE,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: bodySearch,
        rule: [
          {
            field: API_SHIPMENT.EXPORTER,
            type: MessageType.EMPTY,
            key: ["Exporter"],
          },
          {
            field: API_SHIPMENT.IMPORTER,
            type: MessageType.EMPTY,
            key: ["Importer"],
          },
          {
            field: API_SHIPMENT.FORECAST_MONTH,
            type: MessageType.MONTH_INVALID_50,
            key: ["Forecast Month", "MM/YYYY"],
          },
          {
            field: API_SHIPMENT.R_RKG_OWNER,
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const searchData = await searchShipment(bodySearch);
      if (!searchData?.result?.data?.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] });
        setMsgError([msg]);
        return false;
      }
      const { data, rPkg } = formatRowsMapping(searchData?.result?.data);
      setRows(data);
      setRPkg(rPkg);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      setForm(old => ({
        ...old,
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.FILE_NAME]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
          [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
        },
        [API_SHIPMENT.FILE]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
          [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
        },
      }));
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  return (
    <Grid container pacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <MappingFilterBar
          mode={mode}
          form={form}
          setForm={setForm}
          dataList={{ exporterList, importerList, rPkgList }}
        />
      </Grid>
      <MappingButtonBar
        mode={mode}
        setMode={setMode}
        form={form}
        setForm={setForm}
        rows={rows}
        setRows={setRows}
        setAppId={setAppId}
        setRPkg={setRPkg}
        setOnSearch={setOnSearch}
        refresh={refresh}
        columns={columns}
        dataList={{ exporterList, importerList, rPkgList }}
        pagination={{ pageNumber, setPageNumber, pagination, setPagination }}
        rowSelection={{ selectedRowKeys, setSelectedRowKeys, selectedRows, setSelectedRows }}
        setMsg={{ setMsgError, setMsgAlert }}
        functionId={functionId}
      />
      <Grid item xs={12}>
        {onSearch && (
          <MappingTable
            form={form}
            columns={columns}
            rows={rows}
            setRows={setRows}
            onSearch={onSearch}
            setOnSearch={setOnSearch}
            setRPkg={setRPkg}
            mode={mode}
            setAppId={setAppId}
            setMode={setMode}
            refresh={refresh}
            getSearch={getSearch}
            dataList={{ exporterList, importerList, rPkgList }}
            pagination={{ pageNumber, setPageNumber, pagination, setPagination }}
            rowSelection={{ selectedRowKeys, setSelectedRowKeys, selectedRows, setSelectedRows }}
            setMsg={{ setMsgError, setMsgAlert }}
          />
        )}
      </Grid>
    </Grid>
  );
}
