import { saveAs } from "utils/download-file";
import { useMutateData, useMutateDownload } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useSearchVolumeDimension = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/volumn-dimension/serach`,
      method: "POST",
      data: body,
    });
  };
};

export const useCreateVolumeDimension = () => {
  // return useMutation({
  //   url: `/volumn-dimension/${type}/create`,
  //   method: "POST",
  // });
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, type) => {
    return await mutate({
      url: `${BaseServicePath}/volumn-dimension/${type}/create`,
      method: "POST",
      data: body,
    });
  };
};

export const useUpdateVolumeDimension = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, type, VolumeDimensionId) => {
    return await mutate({
      url: `${BaseServicePath}/volumn-dimension/${type}/edit/${VolumeDimensionId}`,
      method: "PATCH",
      data: body,
    });
  };
  // return useMutation({
  //   url: `/volumn-dimension/${type}/edit/${VolumeDimensionId}`,
  //   method: "PATCH",
  // });
};

export const useDeleteVolumeDimension = () => {
  // return useMutation({
  //   url: `/volumn-dimension/${type}/delete/${VolumeDimensionId}`,
  //   method: "DELETE",
  //   key: [VolumeDimensionId],
  //   enabled: !_.isEmpty(VolumeDimensionId),
  // });
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, type, VolumeDimensionId) => {
    return await mutate({
      url: `${BaseServicePath}/volumn-dimension/${type}/delete/${VolumeDimensionId}`,
      method: "DELETE",
      data: body,
    });
  };
};

export const useVolumeRPkgDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate(
      {
        url: `${BaseServicePath}/volumn-dimension/download-excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },
      {
        onSuccess: response => {
          saveAs(response);
        },
      }
    );
  };
};
export const useVolumeContainerDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate(
      {
        url: `${BaseServicePath}/volumn-dimension/download-excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },
      {
        onSuccess: response => {
          saveAs(response);
        },
      }
    );
  };
};
