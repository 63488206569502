import fileSaver from "file-saver";

//response must include header and data
export const saveAs = response => {
  if (!response?.data) {
    return;
  }
  const { headers, data } = response;
  if (!headers["content-disposition"]) {
    console.error("NOT INITIAL FILENAME PLEASE CONTACT DEV TEAM");
  }

  // Improved filename extraction
  let fileName = headers["content-disposition"]
    .split(";")
    .find(n => n.includes("filename="))
    ?.split("=")[1]
    ?.trim()
    .replace(/^["']|["']$/g, ""); // Remove surrounding quotes if present

  // Decode the filename (handles special characters and spaces)
  fileName = decodeURIComponent(fileName);

  // Clean up the filename
  fileName = fileName.replace(/^[\s_*]+|[\s_*]+$/g, "");

  const blob = new Blob([data], { type: `${headers["content-type"]}` });
  fileSaver.saveAs(blob, fileName);
};
