import React, { Fragment } from "react";
import { Box } from "@mui/material";
import { InputButton } from "components/UI";
//Util
import { isBlankOrNull, responseErrors } from "utils";
//Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";
//Type
import { ModeAction } from "state/enum";
import { COMMON_ENUM } from "shared/constants";
export default function ConfirmMatching({
  form,
  setForm,
  rows,
  setRows,
  modeOfWDN95030,
  mode,
  getSumQty,
  rPkgQty,
  setRPkgQty,
  setOnOpen,
  updateTempContainer,
  copyRPackageFunction,
  pageNumber,
  loading: { startLoading, stopLoading },
  setMsg: { setMsgAlert, setMsgError },
  rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
  functionId,
}) {
  const confirmDialogCtx = useConfirmDialogContext();

  const handleConfirmMatch = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const confirm = await confirmDialogCtx.success({ type: "WDN95031_CONFIRM_MATCHING" });
      if (!confirm) {
        return;
      }

      //NOTE - In case no selected record will close container sub screen
      if (rowSelectionModel?.length <= 0) {
        startLoading();
        for (const row of rows) {
          // if (row === COMMON_ENUM.VANNING_STATUS_INCOMPLETE) {
          let notEqual = false;
          if (row.planContainerNo !== row.actualContainerNo) notEqual = true;
          if (parseInt(row.planRModuleQty) !== parseInt(row.actualRModuleQty)) notEqual = true;
          if (parseInt(row.planRBoxDunQty) !== parseInt(row.actualRBoxDunQty)) notEqual = true;

          row["status"] = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;
          // }
          // continue;
        }
        console.log("form container sub: ", form);
        console.log(rows);
        setForm(prev => ({ ...prev, rows: [...rows] }));
        await updateTempContainer(rows, form?.shippingSessionId);
        stopLoading();
        setOnOpen(false);
        return;
      }
      // in case has selected record
      else {
        let tempUpdateRow = [...rows];
        let tempRowSelectionModel = [...rowSelectionModel];
        startLoading();
        for (const selected of rowSelectionModel) {
          const rowSelected = tempUpdateRow.find(row => row.rowNumber === selected);
          if (
            isBlankOrNull(rowSelected?.planContainerNo) &&
            isBlankOrNull(rowSelected.planRModuleQty) &&
            isBlankOrNull(rowSelected.planRBoxDunQty)
          ) {
            await copyRPackageFunction(rowSelected);
          }

          // In case that is add new will don't have actual ContainerNo
          // change status to Complete only
          if (rowSelected?.status === COMMON_ENUM.VANNING_STATUS_INCOMPLETE) {
            rowSelected.status = COMMON_ENUM.VANNING_STATUS_COMPLETED;
            tempRowSelectionModel = tempRowSelectionModel.filter(idx => idx !== rowSelected?.rowNumber);
            continue;
          }
          // remove checked for rowSelected status is complete
          if (rowSelected?.status === COMMON_ENUM.VANNING_STATUS_COMPLETED) {
            tempRowSelectionModel = tempRowSelectionModel.filter(idx => idx === rowSelected?.rowNumber);
            continue;
          }
        }
        setRowSelectionModel(tempRowSelectionModel);
        setRows(tempUpdateRow);
        setForm(prev => ({ ...prev, rows: [...tempUpdateRow] }));
        await updateTempContainer(tempUpdateRow, form?.shippingSessionId);
        stopLoading();
        return true;
      }
    } catch (error) {
      stopLoading();
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  return (
    <Fragment>
      {[ModeAction.VIEW, ModeAction.SELECTED].includes(modeOfWDN95030) ? (
        <div></div>
      ) : (
        ![ModeAction.ADD, ModeAction.EDIT, ModeAction.COPY].includes(mode) && (
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <InputButton
              id="button-confirm-match"
              value="Confirm Matching"
              onClick={handleConfirmMatch}
              name={`${functionId}Confirm`}
              tabIndex={0}
            />
          </Box>
        )
      )}
    </Fragment>
  );
}
