import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import { InputButton } from "components/UI";
import { ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";
const ROW_ADD_BEFORE_SEARCH = 2;
const SubmitBar = ({
  mode,
  rows,
  setMode,
  setRows,
  setRowModesModel,
  setMsgError,
  rowModesModel,
  rowSelectionModel,
  setRowSelectionModel,
  setOnSearch,
  labelBtnSave = "Save",
  labelBtnCancel = "Cancel",
  functionId,
  noRoleRequired,
  onSubmit = () => {},
  onCancel = () => {},
  tabIndex = -1
}) => {
  const confirmDialogCtx = useConfirmDialogContext();

  useMemo(() => mode, [mode]);

  const handleSaveClick = async () => {
    try {
      const saveConfirm = await confirmDialogCtx.success({
        type: "confirmSave",
      });
      if (!saveConfirm) {
        return;
      }

      onSubmit(rows.find(item => rowSelectionModel.includes(item.rowNumber)));

      if (mode === ModeAction.ADD) {
        //To find maximum rowNumber
        const latestKey = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

        setRowModesModel({
          ...rowModesModel,
          [latestKey]: { mode: GridRowModes.View },
        });
        return;
      } else {
        let tempRowModesModel = rowModesModel;

        const rowSelect = rowSelectionModel.map(val => {
          tempRowModesModel = {
            ...tempRowModesModel,
            [val]: { mode: GridRowModes.View },
          };
        });

        rowSelect && setRowModesModel(tempRowModesModel);
        return;
      }
    } catch (error) {
      setMsgError([error]);
    }
  };

  const handleCancelClick = async () => {
    const confirm = await confirmDialogCtx.success({ type: "confirmCancel" });
    if (!confirm) {
      return;
    }

    setMsgError([]);

    onCancel(rows.find(item => rowSelectionModel.includes(item?.rowNumber)));

    if (mode === ModeAction.ADD) {
      //To find maximum rowNumber
      const latestKey = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

      setRowModesModel({
        ...rowModesModel,
        [latestKey]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      setRows(rows.filter(row => !row.isNew)); //Keep only not new
    } else {
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map(val => {
        tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.View, ignoreModifications: true },
        };
      });

      rowSelect && setRowModesModel(tempRowModesModel);
    }
    if (mode !== ModeAction.EDIT) {
      if (rows.length < ROW_ADD_BEFORE_SEARCH) {
        setOnSearch(false);
      }
    }
    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
  };

  return (
    <>
      {[ModeAction.ADD, ModeAction.EDIT].includes(mode) && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton
            id="btn_save"
            value={labelBtnSave}
            onClick={handleSaveClick}
            name={`${functionId}Save`}
            noRoleRequired={noRoleRequired}
            tabIndex={tabIndex}
          />
          <InputButton
            id="btn_cancel"
            value={labelBtnCancel}
            onClick={handleCancelClick}
            name={`${functionId}Cancel`}
            noRoleRequired={noRoleRequired}
            tabIndex={tabIndex}
          />
        </Grid>
      )}
    </>
  );
};

export default SubmitBar;
