import React, { useEffect } from "react";
// Components
import { Grid, Divider } from "@mui/material";
import { InputButton } from "components/UI";
// Utils
import { findObject, messageDisplay, responseErrors } from "utils";
import { transformSubScreenData } from "../../../hooks/tranformData";
//Service
import {
  useExportInvoiceSearchSubScreenMutation,
  useExportInvoiceShipmentCarrierMutation,
  useExportInvoiceShipmentEtdMutation,
} from "shared/services/invoice";
//Type
import { BUTTON_VALUE, COMMON_ENUM, FIRST_PAGE, PAGINATION } from "shared/constants";
import { MSG_TYPE, ModeAction } from "state/enum";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { DEFAULT_STATE_WDN95011 } from "pages/Invoice/ExportInvoiceCreationMaintenanceScreen/constants/constants";
import { useSelector } from "react-redux";

export default function ButtonCriterial(props) {
  const {
    setMode,
    form,
    setForm,
    setRows,
    setOnSearch,
    setMsg: { setMsgAlert, setMsgError },
    stateButton: { searchBtn, clearBtn },
    rowSelection: { setRowModesModel, rowSelectionModel, setRowSelectionModel },
    pagination: { setPagination, setPageNumber },
    dataList: { expData, importerData, brokerData },
    setShipCarrierData,
    setShipmentEtdData,
    setExporterList,
    onOpen,
    functionId,
    isAPMCUser,
    onSearch,
    stateBtn: { setSearchBtn, setClearBtn },
  } = props;
  const userInfo = useSelector(state => state.auth.user);
  const searchExportInfo = useExportInvoiceSearchSubScreenMutation();
  const shipmentEtd = useExportInvoiceShipmentEtdMutation();
  const shipmentCarrier = useExportInvoiceShipmentCarrierMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleSearch = async event => {
    try {
      event?.preventDefault();
      setMsgAlert([]);
      setMsgError([]);
      // Reset Data of Table
      setPageNumber(FIRST_PAGE);
      setPagination({});

      setMode(ModeAction.VIEW);
      setOnSearch(false);

      setForm(prev => ({ ...prev, nonComInvFlag: true }));
      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});

      const body = prepareParamSearch();
      startLoading();
      const searchData = await searchExportInfo(body);
      stopLoading();
      if (searchData?.result?.errors?.length > 0) {
        const message = searchData?.result?.errors.map(val => {
          const code = val?.code;
          const message = val?.message;
          return code ? `${code}: ${message}\n` : "";
        });
        setMsgError(message);
      }

      if (!searchData?.result?.data?.length) {
        setMsgError([
          messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0059AERR",
            msg: "",
          }),
        ]);
        return;
      }
      const data = transformSubScreenData(searchData?.result?.data);

      if (data.length > 0) {
        setForm(prev => ({
          ...prev,
          expBrokerCompanyAbbr: data[0].brokerCompanyAbbr ?? "",
          expBrokerCompanyId: data[0].brokerCompanyId ?? "",
        }));
      }
      //Search dropdown
      if (isAPMCUser) {
        await prePareData();
        setForm(prev => ({
          ...prev,
          expBrokerCompanyAbbr: form?.expBrokerCompanyId
            ? findObject({
                data: brokerData,
                value: form?.expBrokerCompanyId,
                property: "companyId",
                field: "companyAbbr",
              })
            : "",
        }));
        setExporterList(expData);
      } else {
        if ([COMMON_ENUM.SC_MODE_EDIT, COMMON_ENUM.SC_MODE_VIEW].includes(onOpen.actionType)) {
          setExporterList([{ plantId: data[0].expPlantId, exporterCd: data[0].expCd }]);
        }
      }
      if ([COMMON_ENUM.SC_MODE_EDIT, COMMON_ENUM.SC_MODE_VIEW].includes(onOpen.actionType)) {
        for (const row of data) {
          setRowSelectionModel(prev => [...prev, row?.rowNumber]);
        }
      }
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);

      setOnSearch(true);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const prepareParamSearch = () => {
    if ([COMMON_ENUM.SC_MODE_EDIT, COMMON_ENUM.SC_MODE_VIEW].includes(onOpen.actionType)) {
      return {
        dataOwner: form.dataOwner,
        invoiceNo: form?.invNo,
        etdDt: form?.etdDt,
        vesselName: form?.vesselName,
        importerCompanyAbbr: form?.impCompanyAbbr,
        importerId: form?.impCompanyId,
        exporterCd: form.exporterCd,
        exporterCompanyAbbr: form.expCompanyAbbr,
        nonComInvHId: form?.nonComInvHId,
        currencyCd: form?.currencyCd,
        expEntryNo: form?.expEntryNo,
        updateDt: form?.updateDt,
        updateBy: form?.updateBy,
        brokerCompanyId: form?.expBrokerCompanyId,
        carrier: form?.carrierCompanyAbbr,
        loginUserCompanyAbbr: userInfo.companyAbbr, // TODO: mock constants here
        actionType: COMMON_ENUM.SC_MODE_EDIT,
        isApmcRole: isAPMCUser,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
      };
    } else {
      return {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        invoiceNo: form?.invNo,
        etdDt: form?.etdDt,
        vesselName: form?.vesselName,
        importerCompanyAbbr: !form?.impCompanyId
          ? ""
          : findObject({
              data: importerData,
              value: form?.impCompanyId,
              property: "companyId",
              field: "companyAbbr",
            }),
        importerId: form?.impCompanyId,
        exporterCd: !form?.exporterPlantId
          ? ""
          : findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCd",
            }),
        invoiceOrderType: form?.invType,
        exporterCompanyAbbr: !form?.exporterPlantId
          ? ""
          : findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCompanyAbbr",
            }),
        nonComInvHId: form?.nonCommInvHId,
        currencyCd: form?.currencyCd ?? "",
        parkingMonth: form?.parkingMonth ?? "",
        vanningPlant: form?.vanningPlant ?? "",
        brokerCompanyId: form?.expBrokerCompanyId ?? "",
        loginUserCompanyAbbr: userInfo.companyAbbr, // TODO: mock constants here
        isApmcRole: isAPMCUser,
        actionType: COMMON_ENUM.SC_MODE_SEARCH,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
      };
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setForm(DEFAULT_STATE_WDN95011);

    setPageNumber(FIRST_PAGE);
    setRows([]);
    setPagination({});
    setRowSelectionModel([]);
    setRowModesModel({});
    //Reset state
    setOnSearch(false);
    setMode(ModeAction.VIEW);
  };

  const prePareData = async () => {
    try {
      setShipmentEtdData([]);
      setShipCarrierData([]);
      if (onOpen.actionType === COMMON_ENUM.SC_MODE_CREATE_INVOICE) {
        const { result: shipmentEtdResultData } = await shipmentEtd({
          impCompanyId: form?.impCompanyId ?? "",
          exporterCd: form?.exporterPlantId
            ? findObject({
                data: expData,
                value: form?.exporterPlantId,
                property: "plantId",
                field: "exporterCd",
              })
            : "",
          loginUserCompanyAbbr: userInfo.companyAbbr ?? "",
        });
        setShipmentEtdData(shipmentEtdResultData);
      } else if (onOpen.actionType === COMMON_ENUM.SC_MODE_EDIT) {
        setShipmentEtdData([{ shipmentLabel: form?.shipmentEtd }]);
      }
      const body = {
        dataOwner: userProfile.dataOwner,
        shipmentEtd: form?.shipmentEtd || "",
        etdDt: form?.etdDt,
        impCompanyId: form?.impCompanyId,
        exporterCd: form?.exporterPlantId
          ? findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCd",
            })
          : "",
        expCompanyAbbr: form?.exporterPlantId
          ? findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCompanyAbbr",
            })
          : "",
      };
      const { result: shipCarrierResultData } = await shipmentCarrier(body);
      setShipCarrierData([...shipCarrierResultData, { carrierCompanyAbbr: form?.carrierCompanyAbbr }]);
      return;
    } catch (error) {
      setShipmentEtdData([]);
      setShipCarrierData([]);
      const msg = responseErrors(error);
      setMsgError(msg);
    }
  };
  useEffect(() => {
    setExporterList(expData);
    if (onOpen.isEdit) {
      setSearchBtn(true);
      setClearBtn(true);
      handleSearch();
    }
  }, [onOpen]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <InputButton
          id="button-search"
          value={BUTTON_VALUE.SEARCH}
          disabled={searchBtn}
          onClick={handleSearch}
          name={`${functionId}Search`}
          tabIndex={1}
          marginRight="8px"
        />
        <InputButton
          id="button-clear"
          value={BUTTON_VALUE.CLEAR}
          disabled={clearBtn}
          onClick={handleClear}
          name={`${functionId}Clear`}
          tabIndex={1}
          marginRight="0px"
          onKeyDown={e => {
            if (!onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select-invoice-order-type").focus();
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
