import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_REPORT;

const ReportAverageLeadTimeApiService = () => ({
  useReportCommonGetReportBasisQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-report-basis`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetLocationTypeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-location-type`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetLocationGroupQuery: locationType => {
    const data = useCustomQuery({
      key: ["location-group", locationType],
      url: `${BaseServicePath}/report-common/get-location-group-by-location-type/${locationType}`,
      method: "GET",
      enabled: !!locationType,
      select: data => data.result,
      keepPreviousData: false,
    });
    return data;
  },
  useReportCommonGetOperationQuery: locationGroup => {
    const data = useCustomQuery({
      key: ["operationDesc", locationGroup],
      url: `${BaseServicePath}/report-common/get-operation-by-location-group`,
      method: "POST",
      enabled: !!locationGroup, // location group from GetLocationByLocationType
      select: data => data.result,
      body: {
        locationGroup,
      },
      keepPreviousData: false,
    });

    return data;
  },
  useReportCommonGetRoutePointQuery: ({ locationGroup, operationDesc }) => {
    const data = useCustomQuery({
      key: ["route-point", locationGroup, operationDesc],
      url: `${BaseServicePath}/report-common/get-route-point-by-location-group`,
      method: "POST",
      body: { locationGroup, operationDesc },
      enabled: !!locationGroup && !!operationDesc, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: false,
    });
    return data;
  },
  useReportCommonGetRPackageStatusFromQuery: ({ locationGroup, operationDesc }) => {
    const data = useCustomQuery({
      key: ["r-package-from", locationGroup, operationDesc],
      url: `${BaseServicePath}/report-common/get-r-package-status-from-by-location-group`,
      method: "POST",
      body: { locationGroup, operationDesc },
      enabled: !!locationGroup && !!operationDesc, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: false,
    });
    return data;
  },
  useReportCommonGetRPackageStatusToQuery: ({ locationGroup, operationDesc }) => {
    const data = useCustomQuery({
      key: ["r-package-to", locationGroup, operationDesc],
      url: `${BaseServicePath}/report-common/get-r-package-status-to-by-location-group`,
      method: "POST",
      body: { locationGroup, operationDesc },
      enabled: !!locationGroup && !!operationDesc, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: false,
    });
    return data;
  },
  useReportCommonGetPackStatusFromQuery: ({ locationGroup, operationDesc }) => {
    const data = useCustomQuery({
      key: ["pack-status-from", locationGroup, operationDesc],
      url: `${BaseServicePath}/report-common/get-pack-status-from-by-location-group`,
      method: "POST",
      body: { locationGroup, operationDesc },
      enabled: !!locationGroup && !!operationDesc, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: false,
    });
    return data;
  },
  useReportCommonGetPackStatusToQuery: ({ locationGroup, operationDesc }) => {
    const data = useCustomQuery({
      key: ["pack-status-to", locationGroup, operationDesc],
      url: `${BaseServicePath}/report-common/get-pack-status-to-by-location-group`,
      method: "POST",
      body: { locationGroup, operationDesc },
      enabled: !!locationGroup && !!operationDesc, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: false,
    });
    return data;
  },
  useReportCommonGetRPackageOwnerQueryDN96I0: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-r-package-owner/LDN960I0`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetRPackageTypeQueryDN96I0: rPackageOwner => {
    const data = useCustomQuery({
      key: ["r=-package-type", rPackageOwner],
      url: `${BaseServicePath}/report-common/get-r-package-type/LDN960I0/${rPackageOwner}`,
      method: "GET",
      enabled: !!rPackageOwner,
      select: data => data.result,
      keepPreviousData: false,
    });
    return data;
  },
});

export const {
  useReportCommonGetReportBasisQuery,
  useReportCommonGetLocationTypeQuery,
  useReportCommonGetLocationGroupQuery,
  useReportCommonGetOperationQuery,
  useReportCommonGetRoutePointQuery,
  useReportCommonGetRPackageStatusFromQuery,
  useReportCommonGetRPackageStatusToQuery,
  useReportCommonGetPackStatusFromQuery,
  useReportCommonGetPackStatusToQuery,
  useReportCommonGetRPackageOwnerQueryDN96I0,
  useReportCommonGetRPackageTypeQueryDN96I0,
} = ReportAverageLeadTimeApiService();
