import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { EditableTextField, InputEditTableText } from "components/UI";
import React from "react";

export default function useColumnModal({
  rowsMain,
  request,
  plantCategoryData,
  companyCategoryData,
  handleChangePlantCode,
  handleChangePlantName,
  handleChangeImpExpCd,
  handleCategoryCheckboxChange,
}) {
  const handleEnterToggleCheckbox = (e, currentChecked, callback, ...args) => {
    if (e.key === "Enter") {
      e.preventDefault();
      callback({ target: { value: args[0], checked: !currentChecked } }, ...args);
    }
  };

  return [
    {
      field: "rowNumber",
    },
    {
      field: "plantCd",
      sortable: false,
      headerName: "Plant Code",
      minWidth: 150,
      flex: 0.1,
      editable: true,
      headerAlign: "center",
      align: "right",
      renderCell: params => {
        return params.value;
      },
      renderEditCell: params => {
        return (
          <EditableTextField
            required
            {...params}
            setValue={handleChangePlantCode}
            onUpperCase={true}
            regularExp={/^[a-zA-Z0-9]*$/}
            maxLength={5}
            style={{ border: "1.7px solid" }}
          />
        );
        // return <InputEditTableText required {...params} setValue={handleChangePlantCode} />;
      },
    },
    {
      field: "plantName",
      sortable: false,
      headerName: "Plant Name",
      minWidth: 250,
      flex: 0.1,
      editable: true,
      headerAlign: "center",
      align: "right",
      renderCell: params => {
        return params.value;
      },
      renderEditCell: params => {
        return (
          <EditableTextField
            required
            {...params}
            setValue={handleChangePlantName}
            onUpperCase={true}
            maxLength={80}
            style={{ border: "1.7px solid" }}
          />
        );
        // return <InputEditTableText required {...params} setValue={handleChangePlantName} regularExp="" />;
      },
    },
    {
      field: "impExpCd",
      sortable: false,
      headerName: "Importer/Exporter Code",
      minWidth: 200,
      flex: 0.1,
      editable: true,
      headerAlign: "center",
      align: "right",
      renderCell: params => {
        return params.value;
      },
      renderEditCell: params => {
        return (
          <EditableTextField
            required
            {...params}
            setValue={handleChangeImpExpCd}
            onUpperCase={true}
            regularExp={/^[a-zA-Z0-9]*$/}
            maxLength={10}
            style={{ border: "1.7px solid" }}
          />
        );
        // return <InputEditTableText required {...params} setValue={handleChangeImpExpCd} />;
      },
    },
    {
      field: "category",
      sortable: false,
      headerName: "Plant Category",
      minWidth: 450,
      flex: 0.1,
      editable: true,
      headerAlign: "center",
      align: "right",
      renderEditCell: params => {
        return (
          <Grid container padding="12px">
            {plantCategoryData?.map((val, i) => {
              const isChecked = request?.plant?.[params.id - 1]?.category?.some(v => v.categoryCd === val.cd);

              return (
                <Grid item xs={6} key={i}>
                  <FormControlLabel
                    onKeyDown={e =>
                      handleEnterToggleCheckbox(
                        e,
                        isChecked,
                        handleCategoryCheckboxChange,
                        val.cd,
                        val.value,
                        "",
                        params.id
                      )
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#000000",
                      },
                    }}
                    control={
                      <Checkbox
                        value={val.cd}
                        onChange={e => handleCategoryCheckboxChange(e, val.cd, val.value, "", params.id)}
                        defaultChecked={request?.plant?.[params.id - 1]?.category?.some(v => v.categoryCd === val.cd)}
                        sx={{
                          color: "#000000",
                        }}
                      />
                    }
                    label={val.value}
                  />
                </Grid>
              );
            })}
          </Grid>
        );
        // return params.value;
      },
      // renderEditCell: (params) => {
      //   return <InputEditTableText required {...params} />;
      // },
    },
  ];
}
