import React from "react";
import { Tooltip } from "@mui/material";
import { ModeAction } from "state/enum";

export default function useColumn({ rows, mode }) {

  const MAX_LENGTH_STRING = 50

  const truncateByMaxLength = (str) => {
    return str && str.length > MAX_LENGTH_STRING ? `${str.slice(0, MAX_LENGTH_STRING)}...` : str;
  }

  return [
    {
      headerName: "No",
      field: "rowNumber",
      sortable: false,
      align: "right",
      width: 50,
      editable: false,
      renderCell: params => {
        if (params.row.rowNumber === rows.slice(-1)?.[0].rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    {
      headerName: "Company Abbreviation",
      field: "companyAbbr",
      headerAlign: "center",
      align: "left",
      minWidth: 120,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return params.value;
      },
    },
    {
      headerName: "Company Name",
      field: "companyName",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 250,
      flex: 0.1,
      editable: true,
      renderCell: params => {
        return truncateByMaxLength(params.value)
      },
    },
    {
      headerName: "Company Category",
      field: "category",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 300,
      flex: 0.1,
      editable: true,
      renderCell: params => {
        if (params.value.length) {
          params?.value?.sort((a, b) => a.categoryDesc.toLowerCase().localeCompare(b.categoryDesc.toLowerCase()));
          const value = params.value
            ?.map(val => {
              return val.categoryDesc;
            })
            .join(" | ");
          return (
            <Tooltip title={value} style={{ textWrap: "wrap" }}>
              <span>{truncateByMaxLength(value)}</span>
            </Tooltip>
          );
        }
      },
    },
    {
      headerName: "Company&Plant Code",
      field: "companyPlantCd",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      editable: true,

      renderCell: params => {
        return truncateByMaxLength(params.value)
      },
    },
    {
      headerName: "Plant Name",
      field: "plantName",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      editable: true,
      renderCell: params => {
        if (params?.row?.plant.length) {
          params?.row?.plant.sort((a, b) => a.plantName.toLowerCase().localeCompare(b.plantName.toLowerCase()));
          const value = params.row.plant
            ?.map(val => {
              return val.plantName;
            })
            .join(" | ");

          return (
            <Tooltip title={value}>
              <span>{truncateByMaxLength(value)}</span>
            </Tooltip>
          );
        }
      },
    },
    {
      headerName: "Importer/Exporter Code",
      field: "impExpCdAll",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      editable: true,
      renderCell: params => {
        return truncateByMaxLength(params.value)
      },
    },
    {
      headerName: "Address Line 1",
      field: "address1",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 280,
      flex: 0.1,
      editable: true,
      renderCell: params => {
        return truncateByMaxLength(params.value)
      },
    },
    {
      headerName: "Address Line 2",
      field: "address2",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 280,
      flex: 0.1,
      editable: true,
      renderCell: params => {
        return truncateByMaxLength(params.value)
      },
    },
    {
      headerName: "Address Line 3",
      field: "address3",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 280,
      flex: 0.1,
      editable: true,
      renderCell: params => {
        return truncateByMaxLength(params.value)
      },
    },
    {
      headerName: "Postal Code",
      field: "poatcode",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      editable: true,
    },
    {
      headerName: "Country",
      field: "countryName",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      editable: true,
    },
    {
      headerName: "Telephone Number",
      field: "tel",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      editable: true,
    },
    {
      headerName: "Fax Number",
      field: "fax",
      sortable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      editable: true,
    },
  ];
}
