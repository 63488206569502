import { Grid } from "@mui/material";

const Logo = () => {
  return (
    <Grid container spacing={1} width="100%" height="100%" style={{ margin: "auto" }}>
      <Grid item xs={12} sx={{ justifyContent: "center", alignContent: "center" }}>
        <center>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/polygon.svg`}
            alt="Polygon Logo"
            width={600}
            height={650}
          />
        </center>
      </Grid>
    </Grid>
  );
};

export default Logo;
