import { Box, Link, Stack } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import React, { useMemo, useState } from "react";
import { FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import CriterialSection from "./components/CriterialSection";
import {
  useExportInvoiceCategoryQuery,
  useExportInvoiceSearchCancelVanningResultSubScreenMutation,
} from "shared/services/invoice";
import { userProfile } from "constant";
import ButtonCriterial from "./components/ButtonCriterial";
import TableSection from "./components/TableSection";
import { useColumnCancalVanningResultSubScreen } from "../../hooks/useColumnCancalVanningResultSubScreen";
import { ModeAction } from "state/enum";
import { transformCancelSubScreenData } from "../../hooks/tranformData";
import { responseErrors } from "utils";
import { DEFAULT_STATE_WDN95012 } from "../../constants/constants";
import { useSelector } from "react-redux";

export default function CancelVanningResultSubScreen(props) {
  const { setOnOpen, alertMain, form, setForm } = props;
  const confirmDialogCtx = useConfirmDialogContext();
  const userInfo = useSelector(state => state.auth.user);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [mode, setMode] = useState(ModeAction.VIEW);
  const [onSearch, setOnSearch] = useState(false);

  // Table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);

  //Button State
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);

  // API
  const { data: ImporterCompanyData } = useExportInvoiceCategoryQuery();
  const searchExportSubScreenInfo = useExportInvoiceSearchCancelVanningResultSubScreenMutation();

  // columns
  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumnCancalVanningResultSubScreen({
      rows,
      mode: mode,
      dataList: {},
    });
  }, [rows, mode]);

  const handleClose = async () => {
    const confirm = await confirmDialogCtx.success({ type: "closeScreen" });
    if (!confirm) {
      return;
    }
    setOnOpen(false);
    setForm(DEFAULT_STATE_WDN95012);
  };
  const refreshRowsTable = async () => {
    try {
      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        etdDt: form?.etdDt,
        companyId: form?.companyId,
        containerNo: form?.containerNo,
        userId: userInfo.userName,
        loginUserCompanyAbbr: userInfo.companyAbbr, // TODO: mock constants here
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { result } = await searchExportSubScreenInfo(body);

      if (!result?.data?.length) {
        setOnSearch(false);
        setPageNumber(FIRST_PAGE);
        setMode(ModeAction.VIEW);
        setRows([]);
        setRowSelectionModel([]);
        setRowModesModel({});
        setPagination({});
        return;
      }
      setRowSelectionModel([]);
      setRowModesModel({});
      setMode(ModeAction.VIEW);
      const data = transformCancelSubScreenData(result?.data);
      setRows(data);
      setPagination(result?.pagination ?? {});
      setPageNumber(result?.pagination?.pageNumber);
      setOnSearch(true);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  return (
    <Stack>
      <Stack>
        <Link href="#" color="#000" onClick={handleClose} size="small">
          Close
        </Link>
      </Stack>
      <Stack mb={2}>
        <HeaderContentBar title="WDN95012 : Cancel Vanning Result Sub Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        {/* <AlertMessage type={"success"} message={msgAlert} /> */}
      </Stack>
      <Stack sx={{ pl: "1rem", pr: "1rem" }}>
        <CriterialSection mode={mode} form={form} setForm={setForm} dataList={{ ImporterCompanyData }} />
        <ButtonCriterial
          form={form}
          setForm={setForm}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          mode={mode}
          setMode={setMode}
          rows={rows}
          setRows={setRows}
          stateButton={{ searchBtn, clearBtn }}
          setMsg={{ setMsgAlert, setMsgError }}
          rowSelection={{
            rowModesModel,
            setRowModesModel,
            rowSelectionModel,
            setRowSelectionModel,
          }}
          pagination={{
            pagination,
            setPagination,
            pageNumber,
            setPageNumber,
          }}
          functionId={"WDN95010"}
        />
        {onSearch && (
          <Box sx={{ pr: "1rem", pl: "1rem" }}>
            <TableSection
              mode={mode}
              setMode={setMode}
              setOnOpen={setOnOpen}
              onSearch={onSearch}
              rows={rows}
              setRows={setRows}
              columns={columns}
              form={form}
              setForm={setForm}
              refreshRowsTable={refreshRowsTable}
              setOnSearch={setOnSearch}
              setMsg={{ setMsgAlert, setMsgError }}
              alertMain={alertMain}
              rowSelection={{
                rowModesModel,
                setRowModesModel,
                rowSelectionModel,
                setRowSelectionModel,
              }}
              pagination={{
                pagination,
                setPagination,
                pageNumber,
                setPageNumber,
              }}
              dataList={{}}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
