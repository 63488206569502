import { Box } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

const Footer = ({ width = "none" }) => {
  return (
    <Box className="area-footer" width={width} sx={{ borderTop: "1px solid #C4C4C4" }}>
      {/* &copy; 2024 -TOYOTA MOTOR ASIA (THAILAND) CO., LTD. All Rights Reserved */}
      Polygon &copy; 2024 version 1.0
    </Box>
  );
};

Footer.propTypes = { width: PropTypes.string };

export default Footer;
