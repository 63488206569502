import _ from "lodash";
import { COMMON_ENUM } from "shared/constants";
import { useMutateData, useMutateDownload, useCustomQuery } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useGetCountry = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/route-point/country-code`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};
export const useGetCountryInTable = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/COMMON/COUNTRY_CD/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};
export const useRoutePointCtgListSearch = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/route-point/category`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};
export const useRoutePointCategory = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/${COMMON_ENUM.C_SM_CTG_COMMON}/${COMMON_ENUM.C_SM_SUB_CTG_ROUTE_POINT_CATEGORY}/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
}; //EXT
export const useGetSubPlant = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/sub-plant`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};
export const useGetSubPlantInTable = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/${COMMON_ENUM.C_SM_CTG_TMAP_EM}/${COMMON_ENUM.C_SM_SUB_CTG_COMPANY_PLANT_EXT}/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useInOutStatus = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/COMMON/IN_OUT_STATUS/ASC`,
    mrthod: "GET",
    enabled: true,
    select: data => data.result,
  });
};
export const useSearchRoutePoint = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/route-point/search`,
      method: "POST",
      data: body,
    });
  };
};

export const usePossibleStatus = ({ category = "COMMON", subCategory = "RPKG_STATUS" }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/${category}/${subCategory}/ASC`,
    method: "GET",
    select: data => data.result,
    enabled: true,
  });
};

export const useCreateRoutePoint = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate({
      url: `${BaseServicePath}/route-point/create`,
      method: "POST",
      data: body,
    });
  };
};

export const useUpdateRoutePoint = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, routePointId) => {
    return await mutate({
      url: `${BaseServicePath}/route-point/edit/${routePointId}`,
      method: "PATCH",
      data: body,
    });
  };
};

export const useDeleteRoutePoint = () => {
  const { mutate } = useMutateData({
    invalidateKeys: [],
  });
  return async (body, routePointId) => {
    return await mutate({
      url: `${BaseServicePath}/route-point/delete/${routePointId}`,
      method: "DELETE",
      data: body,
    });
  };
};

export const useCompanyPlantInTable = ({ dataOwner, cd }) => {
  return useCustomQuery({
    key: ["company-plant-table", dataOwner, cd],
    url: `${BaseServicePath}/route-point/country/${dataOwner}/${cd}`,
    mrthod: "GET",
    enabled: !_.isEmpty(cd),
    select: data => data.result,
  });
};

export const useRoutePathDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async body => {
    return await mutate(
      {
        url: `${BaseServicePath}/route-point/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },

      {
        onSuccess: response => {
          saveAs(response);
        },
      }
    );
  };
};
