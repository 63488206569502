import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;

const ContainerGroupApiService = () => ({
  useContainerGroupGetApmcQuery: () => {
    const data = useCustomQuery({
      key: "apmc-criterial",
      url: `${BaseServicePath}/category/container-group/apmc`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      // keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupGetApmcPlantQuery: () => {
    const data = useCustomQuery({
      key: ["apmc-select"],
      url: `${BaseServicePath}/category/container-group/apmc/plant`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      // keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupGetContainerTypeQuery: () => {
    const data = useCustomQuery({
      key: "container-type",
      url: `${BaseServicePath}/category/container-group/container-type`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      // keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupGetContainerTypeModalQuery: () => {
    const data = useCustomQuery({
      key: ["container-type-model"],
      url: `${BaseServicePath}/category/container-group/container-type/all`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },

  useContainerGroupGetRPackageTypeQuery: () => {
    const data = useCustomQuery({
      key: "r-package-type",
      url: `${BaseServicePath}/category/container-group/r-package/all`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      // keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupGetWarehouseQuery: () => {
    const data = useCustomQuery({
      key: "ware-house",
      url: `${BaseServicePath}/category/container-group/warehouse`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      // keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/container-group/search`,
        method: "POST",
        data: body,
      });
    };
  },
  useContainerGroupCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [
        ["ware-house"],
        ["r-package-type"],
        ["container-type-model"],
        ["container-type"],
        ["apmc-criterial"],
        ["apmc-select"],
      ],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/container-group/create`,
        method: "POST",
        data: body,
      });
    };
  },
  useContainerGroupUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [
        ["ware-house"],
        ["r-package-type"],
        ["container-type-model"],
        ["container-type"],
        ["apmc-criterial"],
        ["apmc-select"],
      ],
    });
    return async (body, containerGroupId) => {
      return await mutate({
        url: `${BaseServicePath}/container-group/edit/${containerGroupId}`,
        method: "PATCH",
        data: body,
      });
    };
  },
  useContainerGroupDeleteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [
        ["ware-house"],
        ["r-package-type"],
        ["container-type-model"],
        ["container-type"],
        ["apmc-criterial"],
        ["apmc-select"],
      ],
    });
    return async (containerGroupId, body) => {
      return await mutate({
        url: `${BaseServicePath}/container-group/delete/${containerGroupId}`,
        method: "DELETE",
        data: body,
      });
    };
  },
  useContainerGroupDownloadExcelMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/container-group/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
          },
        }
      );
    };
  },
});
export const {
  useContainerGroupGetApmcQuery,
  useContainerGroupGetApmcPlantQuery,
  useContainerGroupGetContainerTypeQuery,
  useContainerGroupGetContainerTypeModalQuery,
  useContainerGroupGetRPackageTypeQuery,
  useContainerGroupGetWarehouseQuery,
  useContainerGroupCreateMutation,
  useContainerGroupSearchMutation,
  useContainerGroupUpdateMutation,
  useContainerGroupDeleteMutation,
  useContainerGroupDownloadExcelMutation,
} = ContainerGroupApiService();
