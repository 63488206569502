import { useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
const RPkgMasterRModuleApiService = () => ({
  useRPkgMasterCreateRModuleMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/r-module/create`,
        method: "POST",
        data: body,
      });
    };
  },
  useRPkgMasterSearchRModuleMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/r-module/search`,
        method: "POST",
        data: body,
      });
    };
  },
  useRPkgMasterEditRModuleMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, rModuleId) => {
      return await mutate({
        url: `${BaseServicePath}/r-module/edit/${rModuleId}`,
        method: "PATCH",
        data: body,
      });
    };
  },
  useRPkgMasterRModuleDownloadTemplateMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/r-module/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
          },
        }
      );
    };
  },
  useRPkgMasterDeleteRModuleMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, rPkgTypeId) => {
      return await mutate({
        url: `${BaseServicePath}/r-module/delete/${rPkgTypeId}`,
        method: "DELETE",
        data: body,
      });
    };
  },
});

export const {
  useRPkgMasterCreateRModuleMutation,
  useRPkgMasterEditRModuleMutation,
  useRPkgMasterRModuleDownloadTemplateMutation,
  useRPkgMasterDeleteRModuleMutation,
  useRPkgMasterSearchRModuleMutation,
} = RPkgMasterRModuleApiService();
