import React, { useRef, useState } from "react";
import { useGridApiContext } from "@mui/x-data-grid";
import { InputButton } from ".";

const InputEditTableFile = ({
  field,
  id,
  disabled = false,
  setValue = undefined,
  className,
  value,
  tabIndex = -1,
  uploadProcess = async () => {},
}) => {
  const style = {
    border: "1px solid #ccc",
    padding: "8px 15px",
    cursor: "pointer",
    background: "#dbdbdb",
    borderRadius: "4px",
    maring: "auto",
  };
  const apiRef = useGridApiContext();
  const [filename, setFilename] = useState(null);
  const [isLabelFileVisible, setIsLabelFileVisible] = React.useState(true);

  const handleValueChange = async event => {
    const file = event.target.files[0];
    if (!file) return;

    if (setValue) setValue(file.name);

    const fileid = await uploadProcess(field, file);
    fileid && setFilename(file.name);
    fileid && apiRef.current.setEditCellValue({ id, field, value: fileid });
  };

  const handleDeleteClick = () => {
    setIsLabelFileVisible(false);
    setFilename(false);
    apiRef.current.setEditCellValue({ id, field, value: "" });
  };

  const input = useRef(null);
  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      input.current.click();
    }
  };

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {!filename && !value ? (
        <label tabIndex={tabIndex} onKeyDown={onKeyDown} style={style}>
          Upload
          <input
            ref={input}
            type="file"
            accept="image/*"
            onChange={handleValueChange}
            disabled={disabled}
            className={className}
            style={{ display: "none" }}
          />
        </label>
      ) : (
        <></>
      )}
      {(filename && isLabelFileVisible) || value ? (
        <div
          className="label-file"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <p
            style={{
              margin: "unset",
              width: "65%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              height: "1.2em",
              whiteSpace: "nowrap",
            }}>
            {filename ?? value}
          </p>
          <InputButton
            value={"X"}
            color="secondary"
            size="small"
            onClick={handleDeleteClick}
            style={{
              minWidth: "26px",
              height: "24px",
              color: "#ffff",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InputEditTableFile;
