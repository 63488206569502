import { COMMON_ENUM } from "shared/constants";
import { useCustomQuery, useMutateData, useMutateDownload } from "shared/services/base.service";

const BaseServicePath = process.env.REACT_APP_API_INVENTORY_FORECAST;

const ShipmentApiService = () => ({
  //GET
  useShipmentExporterQuery: userCompany => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/shipment-management/exporter/${userCompany}`,
      method: "GET",
      enabled: !!userCompany,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET
  useShipmentImporterQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/shipment-management/importer`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET
  useShipmentRPackageQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/shipment-management/r-package/owner`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //GET
  useShipmentStatusQuery: companyAbbr => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/shipment-management/status/${companyAbbr}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  //POST SEARCH
  useShipmentSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/search`,
        method: "POST",
        data: body,
      });
    };
  },
  //POST DOWNLOAD EXCEL TEMPLATE
  useShipmentDownloadExcelMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${process.env.REACT_APP_API_INVENTORY}/generate-report`,
        method: "POST",
        data: body,
      });
    };
  },
});

export const {
  useShipmentExporterQuery,
  useShipmentImporterQuery,
  useShipmentRPackageQuery,
  useShipmentStatusQuery,
  useShipmentSearchMutation,
  useShipmentDownloadExcelMutation,
} = ShipmentApiService();
