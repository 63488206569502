import React from "react";
import { Box } from "@mui/material";
import { InputButton } from "components/UI";
// util
import { responseErrors, responseSuccess } from "utils";
// service
import { useUploadRPkgContUploadRPgMutation } from "shared/services/main-server";
import { BUTTON_VALUE, WEB_FUNCTION_ID } from "shared/constants";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";

export default function ButtonUploadRPkg({
  form,
  setForm,
  setMsg: { setMsgError, setMsgAlert },
  setAppId,
  functionId,
}) {
  const userInfo = useSelector(state => state.auth.user);
  const { startLoading, stopLoading } = useLoadingContext();
  const uploadRPkg = useUploadRPkgContUploadRPgMutation();
  const handleUpload = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      setAppId(null);

      const uploadData = new FormData();
      uploadData.append("userCompanyCd", userInfo.companyAbbr);
      uploadData.append("functionId", WEB_FUNCTION_ID.STD_MOVEMENT);
      uploadData.append("userId", userInfo.userName);
      uploadData.append("file", form.file);

      startLoading();
      const response = await uploadRPkg(uploadData);
      stopLoading();
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      setAppId(response?.appId);
      setForm({ file: "", fileName: "" });
      return true;
    } catch (error) {
      stopLoading();
      const msgError = responseErrors(error);
      setForm({ file: "", fileName: "" });
      setMsgError(msgError);
      window.scrollTo(0, 0);
      return false;
    }
  };
  return (
    <Box>
      <InputButton
        id="button-upload-rPkg"
        value={BUTTON_VALUE.UPLOAD}
        onClick={handleUpload}
        name={`${functionId}Upload`}
        tabIndex={1}
      />
    </Box>
  );
}
