import React, { Fragment, useEffect, useMemo, useState } from "react";
import { InputDropDown } from "components/UI/index";
import { Box, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { AlertMessage } from "components/UI";
import { HeaderContentBar } from "components/Layout";
import ActionBar from "../../Partials/ActionBar";
import DataTable from "pages/DataTable";
// import SubmitBar from "pages/Partials/SubmitBar";
//Utils
import { message, responseErrors, messageTypeDisplay, responseSuccess, responseDownloadFileErrors } from "utils";
//Service
import {
  useCompanyPlantGetCountryQuery,
  useCompanyPlantGetCompanyAbbreviation,
  useCompanyPlantGetCompanyCategory,
  useCompanyPlantSearchMutation,
  useCompanyPlantDeleteMutation,
  useCompanyPlantDownloadTemplateMutation,
} from "shared/services/master";
//Hooks
import { useConfirmDialogContext } from "context/confirmDialogContext";
// Type
import { MessageType, ModeAction } from "state/enum";
import { userProfile } from "constant";
import useColumn from "./hooks/useColumn";
import { FIRST_PAGE, ROW_PER_PAGE } from "shared/constants";
import { useSelector } from "react-redux";
import { ModalCompanyAndPlant } from "./components/ModalCompanyAndPlant";

const CompanyAndPlantMasterScreen = () => {
  const firstField = "select_country";

  const userInfo = useSelector(state => state.auth.user);
  const confirmDialogCtx = useConfirmDialogContext();

  const [mode, setMode] = useState("view");
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [onSearch, setOnSearch] = useState(false);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [searchForm, setSearchForm] = useState({
    countryId: "",
    companyAbbr: "",
    companyCategory: [],
  });

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);

    const body = {
      dataOwner: userProfile.dataOwner,
      countryCd: searchForm.countryId,
      companyId: searchForm.companyAbbr,
      companyCategoryCd: searchForm.companyCategory,
      pageNumber: value,
      rowsPerPage: 10,
    };
    const searchData = await searchDataAsync(body);

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);

    setRows(data);
  };

  // 2. api
  const { data: countryData } = useCompanyPlantGetCountryQuery({
    dataOwner: userProfile.dataOwner,
  });
  const { data: companyAbbrData, refetch } = useCompanyPlantGetCompanyAbbreviation();
  const { data: companyCategoryData } = useCompanyPlantGetCompanyCategory({
    dataOwner: userProfile.dataOwner,
  });
  const searchDataAsync = useCompanyPlantSearchMutation();
  const deleteCompanyPlant = useCompanyPlantDeleteMutation();
  const downloadExcel = useCompanyPlantDownloadTemplateMutation();
  useEffect(() => {
    document.getElementById("select_country").focus();
    return;
  }, []);

  // 3. initail value search from
  useEffect(() => {
    document.getElementById("select_country").focus();
    return;
  }, []);

  // 4. columns
  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumn({ rows, mode });
  }, [mode, rows]);

  // 5. columnGroupingModel
  const columnGroupingModel = [];

  const getSearch = async () => {
    const body = {
      dataOwner: userProfile.dataOwner,
      countryCd: searchForm.countryId,
      companyId: searchForm.companyAbbr,
      companyCategoryCd: searchForm.companyCategory,
      pageNumber: pageNumber,
      rowsPerPage: ROW_PER_PAGE,
    };
    const searchData = await searchDataAsync(body);

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    return { searchData, data };
  };

  // 6. handleSearch
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setRows([]);
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRowModesModel({});
      setOnSearch(false);

      const body = {
        dataOwner: userProfile.dataOwner,
        countryCd: searchForm.countryId,
        companyId: searchForm.companyAbbr,
        companyCategoryCd: searchForm.companyCategory,
        pageNumber: FIRST_PAGE,
        rowsPerPage: ROW_PER_PAGE,
      };
      const searchData = await searchDataAsync(body);

      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: index + 1,
          ...item,
        })) ?? [];

      // data not found
      if (!data.length) {
        setMsgError([message({ type: "notFound" })]);
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setOnSearch(true);

      document.getElementById(firstField).focus();
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // 7. handleDeleteClick
  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }

    try {
      let msg;
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);
      const body = { updateBy: userInfo.userName, updateDt: row.updateDt };

      const response = await deleteCompanyPlant({ companyId: row.companyId, body });
      msg = responseSuccess(response);
      setMsgAlert([msg]);
      const { data } = await getSearch();
      refetch();
      setSearchForm(prev => ({ ...prev, companyAbbr: "" }));
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows(data);

      msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRows([]);
    setRowModesModel([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm({
      countryId: "",
      companyAbbr: "",
      companyCategory: [],
    });
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    try {
      const { data } = await getSearch(1);
      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError(old => [...old, msg]);
        return;
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
    try {
      // const filename = `Company_Plant_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      const option = {
        dataOwner: userProfile.dataOwner,
        countryCd: searchForm.countryId,
        companyId: searchForm.companyAbbr,
        companyCategoryCd: searchForm.companyCategory,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };
      await downloadExcel(option);
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // search checkbox
  const handleChangeCheckboxCompanyCategory = event => {
    const { value } = event.target;

    setSearchForm(prevSearchForm => {
      const updatedCompanyCategory = [...prevSearchForm.companyCategory];

      if (updatedCompanyCategory.includes(value)) {
        updatedCompanyCategory.splice(updatedCompanyCategory.indexOf(value), 1);
      } else {
        updatedCompanyCategory.push(value);
      }

      return {
        ...prevSearchForm,
        companyCategory: updatedCompanyCategory,
      };
    });
  };

  // modal add
  const [isOpenModal, setIsOpenModal] = useState(false);
  // const [rowNumberModal, setRowNumberModal] = useState(null);
  const handleAdd = () => {
    setMode(ModeAction.ADD);
    setIsOpenModal(true);
  };

  // handle edit
  const [rowNumber, setRowNumber] = useState();
  const handleEdit = () => {
    setMsgError([]);
    setMsgAlert([]);

    setMode(ModeAction.EDIT);
    setRowNumber(rowSelectionModel[0]);
    setIsOpenModal(true);
    setRowSelectionModel([]);
  };

  // handle copy
  const handleCopy = () => {
    setMsgError([]);
    setMsgAlert([]);

    setMode(ModeAction.COPY);
    setRowNumber(rowSelectionModel[0]);
    setIsOpenModal(true);
    setRowSelectionModel([]);
  };
  const refetchSearch = async () => {
    try {
      setOnSearch(true);
      const searchData = await searchDataAsync({
        dataOwner: userProfile.dataOwner,
        countryCd: searchForm.countryId,
        companyId: searchForm.companyAbbr,
        companyCategoryCd: searchForm.companyCategory,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      });

      const data =
        searchData?.result?.data?.map((item, index) => ({
          rowNumber: index + 1,
          ...item,
        })) ?? [];

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleEnterToggleCheckbox = (e, isChecked, onChange, value) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onChange({
        target: {
          value,
          checked: !isChecked,
        },
      });
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN910A0 : Company and Plant Master Maintenance Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "325px" }}>Country : </strong>
            <InputDropDown
              id="select_country"
              value={searchForm.countryId}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  countryId: e.target.value,
                }))
              }
              memu={countryData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
              tabIndex={1}
              focus={true}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "325px" }}>Company Abbreviation : </strong>
            <InputDropDown
              id="select_companyAbbr"
              value={searchForm.companyAbbr}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  companyAbbr: e.target.value?.toUpperCase(),
                }))
              }
              memu={companyAbbrData?.map(val => ({
                key: val.companyId,
                value: val.companyAbbr,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
              tabIndex={1}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2} mt={2}>
            <strong>Company Category : </strong>
          </Grid>
          <Grid item xs={10} mt={1}>
            <Grid container spacing={0} alignItems="center">
              {companyCategoryData?.map((val, i) => {
                const isChecked = searchForm.companyCategory.some(item => item === val.cd);

                return (
                  <Grid item xs={3} key={i}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "#000000",
                        },
                      }}
                      tabIndex={1}
                      onKeyDown={e =>
                        handleEnterToggleCheckbox(e, isChecked, handleChangeCheckboxCompanyCategory, val.cd)
                      }
                      control={
                        <Checkbox
                          id={`checkbox_companyCategory_${i + 1}`}
                          value={val.cd}
                          onChange={e => handleChangeCheckboxCompanyCategory(e)}
                          checked={isChecked}
                          sx={{
                            color: "#000000",
                          }}
                        />
                      }
                      label={val.value}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ActionBar
        mode={mode}
        rows={rows}
        columns={columns}
        setRows={setRows}
        setMode={setMode}
        setMsgError={setMsgError}
        setMsgAlert={setMsgAlert}
        setOnSearch={setOnSearch}
        setRowModesModel={setRowModesModel}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        rowModesModel={rowModesModel}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleDownloadExcel={handleDownloadExcel}
        handleDeleteClick={handleDeleteClick}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        handleCopy={handleCopy}
        firstField={firstField}
        functionId={"WDN910A0"}
        tabIndex={1}
        tabIndexNext={2}
      />
      <Box sx={{ padding: "1rem" }}>
        <Grid>
          {onSearch && rows.length !== 0 && (
            <DataTable
              mode={mode}
              onSearch={onSearch}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              setMode={setMode}
              rows={rows}
              rowModesModel={rowModesModel}
              columns={columns}
              setRowModesModel={setRowModesModel}
              processRowUpdate={null}
              pagination={pagination}
              pageNumber={pageNumber}
              handleChangePagination={handleChangePagination}
              columnGroupingModel={columnGroupingModel}
              columnVisibilityModel={{
                currencys: false,
                rPkgTypeId: false,
              }}
              rowHeight={200}
              slotProps={{
                baseCheckbox: {
                  tabIndex: 1,
                },
              }}
            />
          )}
          {/* <SubmitBar
            mode={mode}
            rows={rows}
            setMode={setMode}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            setMsgError={setMsgError}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            functionId={"WDN910A0"}
          /> */}
        </Grid>
      </Box>
      <ModalCompanyAndPlant
        open={isOpenModal}
        setOpen={setIsOpenModal}
        rowsMain={rows}
        rowNumber={rowNumber}
        setPagination={setPagination}
        setPageNumber={setPageNumber}
        setRows={setRows}
        mode={mode}
        setMode={setMode}
        countryData={countryData}
        setMsgErrorMainScreen={setMsgError}
        setMsgAlertMainScreen={setMsgAlert}
        getSearch={getSearch}
        refetchSearch={refetchSearch}
        refetchCompanyAbbrData={refetch}
        setSearchForm={setSearchForm}
        onMainSearch={onSearch}
      />
    </Fragment>
  );
};

export default CompanyAndPlantMasterScreen;
