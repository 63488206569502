import { useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
const RPkgMasterOtherApiService = () => ({
  useRPkgMasterCreateOtherMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/other-materials/create`,
        method: "POST",
        data: body,
      });
    };
  },
  useRPkgMasterSearchOtherMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/other-materials/search`,
        method: "POST",
        data: body,
      });
    };
  },
  useRPkgMasterEditOtherMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, rOthMatId) => {
      return await mutate({
        url: `${BaseServicePath}/other-materials/edit/${rOthMatId}`,
        method: "PATCH",
        data: body,
      });
    };
  },
  useRPkgMasterOtherDownloadTemplateMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/other-materials/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
          },
        }
      );
    };
  },
  useRPkgMasterDeleteOtherMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, rPkgTypeId) => {
      return await mutate({
        url: `${BaseServicePath}/other-materials/delete/${rPkgTypeId}`,
        method: "DELETE",
        data: body,
      });
    };
  },
});

export const {
  useRPkgMasterCreateOtherMutation,
  useRPkgMasterEditOtherMutation,
  useRPkgMasterOtherDownloadTemplateMutation,
  useRPkgMasterDeleteOtherMutation,
  useRPkgMasterSearchOtherMutation,
} = RPkgMasterOtherApiService();
