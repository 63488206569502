import React, { Fragment, useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputDropDown } from "components/UI";
import { ModeAction } from "state/enum";
import ContainerTable from "./partials/ContainerTable";
import RpackageTable from "./partials/RpackageTable";
import {
  useGetContainerTypeCriterial,
  useGetImpCdDropDown,
  useGetRpkgTypeDropDown,
  useGetType,
} from "shared/services/master/volumeDimension";
import { userProfile } from "constant";

const VolumeDimensionScreen = () => {
  // const confirmDialogCtx = useConfirmDialogContext();

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [mode, setMode] = useState("view");
  const [onSearch, setOnSearch] = useState(false);

  const [searchForm, setSearchForm] = useState({
    dataOwner: userProfile.dataOwner,
    dimensionType: "",
    refCompanyAbbr: "",
    refImpExpCd: "",
    rPkgType: "",
    containerType: "",
  });

  useEffect(() => {
    document.getElementById("select_dimensionType").focus();
  }, []);

  const { data: typeData } = useGetType({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: containerTypeData, refetch: refetchContainerTypeData } = useGetContainerTypeCriterial({
    type: searchForm.dimensionType === "C" ? "CONTAINER" : "R_PACKAGE",
    dataOwner: userProfile.dataOwner,
  });

  const { data: importerData, refetch: refetchImporterData } = useGetImpCdDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: rpkgTypeData, refetch: refetchRpkgTypeData } = useGetRpkgTypeDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  useEffect(() => {
    const handleTabKey = event => {
      if (event.key === "Tab") {
        if (searchForm.dimensionType === "") {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("keydown", handleTabKey);

    return () => {
      window.removeEventListener("keydown", handleTabKey);
    };
  }, [searchForm.dimensionType]);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN91060 : Volume Dimension Master Maintenance Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={5} style={{ display: "flex" }}>
            <strong style={{ margin: "auto 0px", width: "325px" }}>* Type of dimension : </strong>
            <InputDropDown
              id="select_dimensionType"
              required
              tabIndex={1}
              value={searchForm.dimensionType}
              onChange={e => {
                setOnSearch(false);
                setMsgAlert([]);
                setMsgError([]);

                setSearchForm(old => ({
                  dataOwner: userProfile.dataOwner,
                  refCompanyAbbr: "",
                  refImpExpCd: "",
                  rPkgType: "",
                  containerType: "",
                  dimensionType: e.target.value,
                }));
                refetchContainerTypeData();
              }}
              memu={typeData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<Select>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={7}></Grid>

          {searchForm.dimensionType === "C" && (
            <>
              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>Container Type : </strong>
                <InputDropDown
                  id="select_containerTypeId"
                  tabIndex={1}
                  value={searchForm.containerType}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      containerType: e.target.value,
                    }))
                  }
                  memu={containerTypeData?.map(val => ({
                    key: val.containerType,
                    value: val.containerType,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={7}></Grid>
            </>
          )}

          {searchForm.dimensionType === "R" && (
            <>
              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>Importer : </strong>
                <InputDropDown
                  id="select_refImpExpCd"
                  tabIndex={1}
                  value={searchForm.refImpExpCd}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      refImpExpCd: e.target.value,
                    }))
                  }
                  memu={importerData?.map(val => ({
                    key: val.refImpExpCd,
                    value: val.importer,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>Container Type : </strong>
                <InputDropDown
                  id="select_containerTypeId"
                  value={searchForm.containerType}
                  tabIndex={1}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      containerType: e.target.value,
                    }))
                  }
                  memu={containerTypeData?.map(val => ({
                    key: val.containerType,
                    value: val.containerType,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5} style={{ display: "flex" }}>
                <strong style={{ margin: "auto 0px", width: "325px" }}>R-Package Type : </strong>
                <InputDropDown
                  id="select_rPkgType"
                  value={searchForm.rPkgType}
                  tabIndex={1}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      rPkgType: e.target.value,
                    }))
                  }
                  memu={rpkgTypeData?.map(val => ({
                    key: val.rPkgType,
                    value: val.rPkgType,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </>
          )}
        </Grid>
      </Box>
      {searchForm.dimensionType === "C" && (
        <ContainerTable
          setMsgAlert={setMsgAlert}
          setMsgError={setMsgError}
          setOnSearch={setOnSearch}
          searchForm={searchForm}
          onSearch={onSearch}
          setSearchForm={setSearchForm}
          setMode={setMode}
          mode={mode}
          slotProps={{
            baseCheckbox: {
              tabIndex: 1,
            },
          }}
          refetchContainerTypeData={refetchContainerTypeData}
        />
      )}
      {searchForm.dimensionType === "R" && (
        <RpackageTable
          setMsgAlert={setMsgAlert}
          setMsgError={setMsgError}
          setOnSearch={setOnSearch}
          searchForm={searchForm}
          onSearch={onSearch}
          setSearchForm={setSearchForm}
          setMode={setMode}
          mode={mode}
          slotProps={{
            baseCheckbox: {
              tabIndex: 1,
            },
          }}
          containerTypeData={containerTypeData}
          refetchImporterData={refetchImporterData}
          refetchContainerTypeData={refetchContainerTypeData}
          refetchRpkgTypeData={refetchRpkgTypeData}
        />
      )}
    </Fragment>
  );
};

export default VolumeDimensionScreen;
