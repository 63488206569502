import React, { Fragment } from "react";
// import "dayjs/locale/en";
// Components
import { Grid } from "@mui/material";
import { DropDown, InputTextField, DatePickerField } from "components/UI";
import { API_LOG_MONITORING } from "shared/constants/api-name/common/logMonitoring";

export default function CriteriaBar(props) {
  const {
    searchForm,
    setSearchForm,
    dataList: { moduleData, functionData, logStatusDetailData, levelData },
  } = props;
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ display: "flex", alignItems: "center", mt: "1px" }}>
        <Grid item tem xs={3.5} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "85px" }}>Module : </strong>
          <DropDown
            id="select_module"
            value={searchForm?.moduleId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.MODULE_ID]: e.target.value,
              }))
            }
            tabIndex={0}
            menu={moduleData?.map(val => ({
              key: val.moduleId,
              value: val.moduleName,
            }))}
            placeholder="<All>"
            defaultValue=""
            autoFocus={true}
          />
        </Grid>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "90px" }}>Function : </strong>
          <DropDown
            id="select_function"
            value={searchForm?.functionId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.FUNCTION_ID]: e.target.value,
              }))
            }
            tabIndex={0}
            menu={functionData?.map(val => ({
              key: val.funtionId,
              value: val.funtionName,
            }))}
            placeholder="<All>"
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "90px" }}>Status : </strong>
          <DropDown
            id="select_status"
            value={searchForm?.statusId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.STATUS_ID]: e.target.value,
              }))
            }
            tabIndex={0}
            menu={logStatusDetailData?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            placeholder="<All>"
            defaultValue=""
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 1, alignItems: "center" }}>
        {/* ======================================================== */}
        {/* ======================================================== */}
        <Grid item xs={3} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "90px" }}>User ID : </strong>
          <InputTextField
            id="input_userId"
            fullWidth={true}
            value={searchForm?.userId}
            tabIndex={0}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.USER_ID]: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={2} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "120px" }}>App ID : </strong>
          <InputTextField
            id="input_appId"
            fullWidth={true}
            value={searchForm?.appId}
            tabIndex={0}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.APP_ID]: e.target.value.trim(),
              }))
            }
          />
        </Grid>
        <Grid item xs={2} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "90px" }}>From : </strong>
          <DatePickerField
            id="date-from"
            value={searchForm?.dateFrom}
            fullWidth={true}
            onChange={date => {
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.DATE_FORM]: date,
              }));
            }}
          />
        </Grid>
        <Grid item xs={2} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "90px" }}>To : </strong>
          <DatePickerField
            id="date-to"
            fullWidth={true}
            tabIndex={0}
            onChange={date =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.DATE_TO]: date,
              }))
            }
            value={searchForm?.dateTo}
          />
        </Grid>
        <Grid item xs={2.5} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "90px" }}>Level : </strong>
          <DropDown
            id="select_level"
            value={searchForm?.levelId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.LEVEL_ID]: e.target.value,
              }))
            }
            tabIndex={0}
            menu={levelData?.map(val => ({
              key: val.statusId,
              value: val.status,
            }))}
            placeholder="<All>"
            defaultValue=""
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
