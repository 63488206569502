import { createTheme } from "@mui/material";
import { deDE as dataGridDeDE } from "@mui/x-data-grid";
import { deDE as coreDeDE } from "@mui/material/locale";
import { deDE } from "@mui/x-date-pickers/locales";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#EB0A1E",
      light: "#FFFFFF",
      dark: "#000000",
      gray: "#58595B",
      hover: "#ba0616",
      focus: "#424242",
    },
    secondary: { main: "#9B9C9D", medium: "#C4C4C4", silver: "#E6E6E6" },
    tertiary: { main: "#1059CD", light: "#EDF0F5", gray: "#F7F7F7", chip: "#fed3d7c7" },
  },
  components: {
    MuiTypography: { styleOverrides: { root: { color: "#9B9C9D" } } },
    MuiListItemIcon: { styleOverrides: { root: { color: "#9B9C9D" } } },
    MuiDrawer: { styleOverrides: { paper: { backgroundColor: "#000" } } },
    MuiStack: { defaultProps: { useFlexGap: true } },
    MuiDialog: { styleOverrides: { root: { zoom: "80%" } } },
  },
  breakpoints: { values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 } },
  typography: { fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(",") },
  deDE, // x-date-pickers translations
  dataGridDeDE, // x-data-grid translations
  coreDeDE, // core translations
});
