import React from "react";
import { Box, Grid } from "@mui/material";
import { DataGridTable, InputButton } from "components/UI";
//Utils
import { findObject, responseErrors, responseSuccess } from "utils";
import { transformSubScreenData } from "../../../hooks/tranformData";
//Service
import {
  useExportInvoiceCreateDetailVanningResultSubScreenMutation,
  useExportInvoiceEditDetailVanningResultSubScreenMutation,
  useExportInvoiceSearchSubScreenMutation,
} from "shared/services/invoice";
//Type
import { ModeAction } from "state/enum";
import { COMMON_ENUM, PAGINATION } from "shared/constants";
import { userProfile } from "constant";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { DEFAULT_STATE_WDN95011 } from "pages/Invoice/ExportInvoiceCreationMaintenanceScreen/constants/constants";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";
export default function TableSection(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    form,
    setForm,
    dataList: { importerData, expData },
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    setOnOpen,
    alertMain,
    refreshRowsTableMain,
    isEdit,
    isAPMCUser,
    onSearch,
  } = props;
  const userInfo = useSelector(state => state.auth.user);
  const confirmDialogCtx = useConfirmDialogContext();
  const { startLoading, stopLoading } = useLoadingContext();

  const searchExportInfo = useExportInvoiceSearchSubScreenMutation();
  // TODO: API create
  const createSubInvoice = useExportInvoiceCreateDetailVanningResultSubScreenMutation();
  const updateSubInvoice = useExportInvoiceEditDetailVanningResultSubScreenMutation();

  const handleChangePagination = async value => {
    try {
      setPageNumber(value);
      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        invoiceNo: form?.invNo,
        etdDt: form?.etdDt,
        vesselName: form?.vesselName,
        importerCompanyAbbr: !form?.impCompanyId
          ? ""
          : findObject({
              data: importerData,
              value: form?.impCompanyId,
              property: "companyId",
              field: "companyAbbr",
            }),
        importerId: form?.impCompanyId,
        exporterCd: !form?.exporterPlantId
          ? ""
          : findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCd",
            }),
        invoiceOrderType: form?.invType,
        exporterCompanyAbbr: !form?.exporterPlantId
          ? ""
          : findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCompanyAbbr",
            }),
        nonComInvHId: form?.nonCommInvHId,
        currencyCd: form?.currencyCd ?? "",
        parkingMonth: form?.parkingMonth ?? "",
        vanningPlant: form?.vanningPlant ?? "",
        brokerCompanyId: form?.expBrokerCompanyId ?? "",
        isApmcRole: isAPMCUser,
        loginUserCompanyAbbr: userInfo.companyAbbr, // TODO: mock constants here
        actionType: COMMON_ENUM.SC_MODE_SEARCH,
        [PAGINATION.PAGE_NUMBER]: value,
      };
      startLoading();
      const searchData = await searchExportInfo(body);
      stopLoading();
      const data = transformSubScreenData(searchData?.result?.data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // TODO: Action Create
  const handleCreate = async newRow => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      let body = {
        dataOwner: userProfile?.dataOwner,
        currencyCd: form?.currencyCd,
        expCustomsStkDt: form?.expCustomsStkDt ? form?.expCustomsStkDt : "",
        expEntryNo: form?.expEntryNo,
        etdDt: form?.etdDt ? form?.etdDt : "",
        vesselName: form?.vesselName,
        impCompanyId: form?.impCompanyId,
        exporterCompanyAbbr: form?.exporterPlantId
          ? findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCompanyAbbr",
            })
          : "",
        expCd: form?.exporterPlantId
          ? findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCd",
            })
          : "",
        packingMonth: form?.parkingMonth,
        vanningPlant: form?.vanningPlant,
        brokerCompanyId: form?.expBrokerCompanyId,
        loginUserCompanyAbbr: userInfo.companyAbbr,
        loginUser: userInfo.userName,
        carrier: form?.carrierCompanyAbbr,
        shipmentEtd: form?.shipmentEtd,
        actionType: COMMON_ENUM.SC_MODE_CREATE_INVOICE,
        isNonCommercialInvoice: form?.nonComInvFlag,
        isAPMCUser: isAPMCUser,
        shipmentList: newRow,
      };
      const response = await createSubInvoice(body);
      const msg = responseSuccess(response);

      setMsgAlert([msg]);
      alertMain.setMsgAlert([msg]);
      window.scrollTo(0, 0);
      stopLoading();
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  // TODO: Action Update
  const handleUpdate = async row => {
    try {
      const body = {
        loginUser: userInfo.userName,
        loginUserCompanyAbbr: userInfo.companyAbbr, // TODO: mock constants here
        dataOwner: form.dataOwner,
        invoiceNo: form?.invNo,
        etdDt: form?.etdDt,
        vesselName: form?.vesselName,
        importerCompanyAbbr: form?.importerCompanyAbbr,
        impCompanyId: form?.impCompanyId,
        expCd: form.exporterCd,
        exporterCompanyAbbr: form.exporterCompanyAbbr,
        nonComInvHId: form?.nonComInvHId,
        currencyCd: form?.currencyCd,
        expEntryNo: form?.expEntryNo,
        expCustomsStkDt: form?.expCustomsStkDt,
        updateDt: form?.updateDt,
        updateBy: form?.updateBy,
        brokerCompanyId: form?.expBrokerCompanyId,
        carrier: form?.carrierCompanyAbbr,
        shipmentCd: form?.shipmentCd,
        isNonCommercialInvoice: form?.nonComInvFlag,
        actionType: COMMON_ENUM.SC_MODE_SAVE_EDIT,
        isAPMCUser: isAPMCUser,
        shipmentList: row,
      };

      const response = await updateSubInvoice(body, form.nonComInvHId);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      alertMain.setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleCancelClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    alertMain.setMsgError([]);
    alertMain.setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({
      type: "confirmCancel",
    });
    if (!confirm) {
      return;
    }
    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
    setForm(DEFAULT_STATE_WDN95011);
    setOnOpen({ open: false });
  };

  const handleSaveClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    let response;
    try {
      const saveConfirm = await confirmDialogCtx.success({
        type: "confirmSave",
      });
      if (!saveConfirm) {
        return;
      }
      if (!isEdit) {
        let rowSelect = rows.filter(item => rowSelectionModel.includes(item.rowNumber));
        if (!rowSelect.length) {
          setTimeout(() => {
            alert("MDN96003AERR: A record must be selected to execute Add operation.");
          }, 100);
          return;
        }

        startLoading();
        response = await handleCreate(rowSelect);
      } else {
        let rowSelect = rows.filter(item => rowSelectionModel.includes(item.rowNumber));
        response = await handleUpdate(rowSelect);
      }
      if (!response) {
        stopLoading();
        return false;
      }
      stopLoading();
      await refreshRowsTableMain(form?.impCompanyId);
      setForm(DEFAULT_STATE_WDN95011);
      setOnOpen({ open: false });
    } catch (error) {
      console.log(error);
      stopLoading();
      return false;
    }
  };

  const handleRowDisableSelect = params => {
    if ([COMMON_ENUM.SC_MODE_EDIT, COMMON_ENUM.SC_MODE_VIEW].includes(mode)) {
      return false;
    }
    return true;
  };

  return (
    <Box>
      <DataGridTable
        id="table-container"
        mode={mode}
        setMode={setMode}
        rows={rows}
        disableCheckBoxAll={false}
        isMultipleSelection={true}
        processRowUpdate={null}
        handleRowDisableSelect={handleRowDisableSelect}
        tableHeight="400px"
        column={{
          columns,
          columnVisibilityModel: {},
        }}
        rowSelection={{
          rowModesModel,
          setRowModesModel,
          rowSelectionModel,
          setRowSelectionModel,
        }}
        pagination={{
          pagination: pagination,
          pageNumber: pageNumber,
          handleChangePagination: handleChangePagination,
        }}
        onCellClick={false}
        onCellDoubleClick={false}
        checkboxSelection={true}
        slotProps={{
          baseCheckbox: {
            tabIndex: 1,
          },
        }}
        paginateTabIndex={1}
      />
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <InputButton
          id="btn_save"
          value={"Save"}
          onClick={handleSaveClick}
          disabled={[COMMON_ENUM.SC_MODE_VIEW].includes(mode)}
          tabIndex={1}
        />
        <InputButton
          id="btn_cancel"
          value={"Cancel"}
          onClick={handleCancelClick}
          disabled={[COMMON_ENUM.SC_MODE_VIEW].includes(mode)}
          onKeyDown={e => {
            if (onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select-invoice-order-type").focus();
            }
          }}
          tabIndex={1}
        />
      </Grid>
    </Box>
  );
}
