import { COMMON_ENUM } from "shared/constants";
import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useGetDownloadAndUploadMaster = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/${COMMON_ENUM.C_SM_CTG_TMAP_EM}/DOWNLOAD_UPLOAD_MASTER/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadYear = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/working-calendar/year`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadCompanyCode = ({ year }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/working-calendar/company/${year}`,
    method: "GET",
    enabled: year !== "",
    select: data => data.result,
  });
};

export const useGetDownloadTransportCategory = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/transport-leadtime/category`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadTransportMode = ({ transportCd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/transport-leadtime/mode/${transportCd}`,
    method: "GET",
    enabled: transportCd !== "",
    select: data => data.result,
  });
};

export const useGetDownloadSender = ({ transportCd, transportMd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/transport-leadtime/sender/${transportCd}/${transportMd}`,
    method: "GET",
    enabled: transportCd !== "" && transportMd !== "",
    select: data => data.result,
  });
};

export const useGetDownloadReceiver = ({ transportCd, transportMd, senderCompId }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/transport-leadtime/receiver/${transportCd}/${transportMd}/${senderCompId}`,
    method: "GET",
    enabled: transportCd !== "" && transportMd !== "" && senderCompId !== "",
    select: data => data.result,
  });
};

export const useGetDownloadExporter = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/custom-stock-leadtime/exporter`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadImporter = ({ expCompId }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/custom-stock-leadtime/importer/${expCompId}`,
    method: "GET",
    enabled: expCompId !== "",
    select: data => data.result,
  });
};

export const useGetDownloadSopExporter = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/sop/exporter`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadSopImporter = ({ expCompId }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/sop/importer/${expCompId}`,
    method: "GET",
    enabled: expCompId !== "",
    select: data => data.result,
  });
};

export const useGetDownloadSopRpackage = ({ expCompId, impCompId }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/sop/r-package/${expCompId}/${impCompId}`,
    method: "GET",
    enabled: expCompId !== "" && impCompId !== "",
    select: data => data.result,
  });
};

export const useGetDownloadCarFamilyType = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/katashi-subseries/car-family/type`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadCarFamilyCode = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/katashi-subseries/car-family/code`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadSubseries = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/katashi-subseries/sub-series`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadLocationType = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/COMMON/LOCATION_TYPE/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadAverageRpackageOwner = () => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/average-leadtime-group/r-package/owner`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadAverageRpackageType = ({ rPkgOwnerCompAbbr }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/average-leadtime-group/r-package/type/${rPkgOwnerCompAbbr}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadAverageLocationGroup = ({ locationTypeCd }) => {
  return useCustomQuery({
    url: `${BaseServicePath}/category/download-upload/average-leadtime-group/location/${locationTypeCd}`,
    method: "GET",
    enabled: locationTypeCd !== "",
    select: data => data.result,
  });
};
