import { COMMON_ENUM } from "shared/constants";
import { useCustomQuery, useMutateData, useMutateDownload } from "shared/services/base.service";
import { isBlankOrNull } from "utils";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
const RPkgTypeMasterApiService = () => ({
  useRPkgTypeMasterCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/r-package/type/create`,
        method: "POST",
        data: body,
      });
    };
  },
  useRPkgTypeMasterSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/r-package/type/search`,
        method: "POST",
        data: body,
      });
    };
  },
  useRPkgTypeMasterEditMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, rPkgTypeId) => {
      return await mutate({
        url: `${BaseServicePath}/r-package/type/edit/${rPkgTypeId}`,
        method: "PATCH",
        data: body,
      });
    };
  },
  useRPkgTypeMasterDownloadTemplateMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate(
        {
          url: `${BaseServicePath}/r-package/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
          },
        }
      );
    };
  },
  useRPkgTypeMasterUploadImageMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, type) => {
      return await mutate({
        url: `${BaseServicePath}/r-package/upload/${type}`,
        method: "POST",
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    };
  },
  useRPkgTypeMasterDownloadImageMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async type => {
      return await mutate({
        url: `${BaseServicePath}/images/${type}`,
        method: "GET",
        responseType: "blob",
      });
    };
  },
  useRPkgTypeMasterDeleteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (body, rPkgTypeId) => {
      return await mutate({
        url: `${BaseServicePath}/r-package/type/delete/${rPkgTypeId}`,
        method: "DELETE",
        data: body,
      });
    };
  },
  useRPkgTypeMasterGetCurrencyQuery: dataOwner => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/${dataOwner}/CURRENCY_CD/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useRPkgTypeMasterRPkgOwnerQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/r-package/owner`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useRPkgTypeMasterRPkgOwnerEditQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/r-package/owner/${COMMON_ENUM.C_SM_CTG_TMAP_EM}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useRPkgTypeMasterCategoryQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/TMATH/R_PKG_CATEGORY/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useRPkgTypeMasterGetRPkgTypeQuery: ({ dataOwner, rPkgOwnerCompId }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/r-package/type/owner/${dataOwner}/${rPkgOwnerCompId}`,
      method: "GET",
      enabled: !isBlankOrNull(rPkgOwnerCompId),
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
});

export const {
  useRPkgTypeMasterCreateMutation,
  useRPkgTypeMasterEditMutation,
  useRPkgTypeMasterDownloadTemplateMutation,
  useRPkgTypeMasterUploadImageMutation,
  useRPkgTypeMasterDownloadImageMutation,
  useRPkgTypeMasterDeleteMutation,
  useRPkgTypeMasterGetCurrencyQuery,
  useRPkgTypeMasterRPkgOwnerQuery,
  useRPkgTypeMasterRPkgOwnerEditQuery,
  useRPkgTypeMasterSearchMutation,
  useRPkgTypeMasterCategoryQuery,
  useRPkgTypeMasterGetRPkgTypeQuery,
} = RPkgTypeMasterApiService();
