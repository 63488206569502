import { useMutateData, useMutateDownload } from "shared/services/base.service";
import fileSaver from "file-saver";

const BaseServicePath = process.env.REACT_APP_API_INVENTORY_FORECAST;

const GroupingApiService = () => ({
  //POST CREATE
  useMappingCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async createData => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/mapping/create`,
        data: createData,
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    };
  },
  //POST EDIT
  useMappingUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (shipmentId, shipmentCd, updateData, hasFile) => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/mapping/edit/${shipmentId}/${shipmentCd}`,
        data: updateData,
        method: "POST",
        headers: {
          "content-type": hasFile ? "multipart/form-data" : "application/json",
        },
      });
    };
  },
  //POST ALLOW EDIT
  useMappingCheckEditMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async updateData => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/search/allow-edit`,
        data: updateData,
        method: "POST",
      });
    };
  },
  useMappingCheckAddMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async updateData => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/search/allow-add`,
        data: updateData,
        method: "POST",
      });
    };
  },
  //POST CANCEL
  useMappingCancelMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (shipmentId, cancelData) => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/mapping/cancel/${shipmentId}`,
        data: cancelData,
        method: "DELETE",
      });
    };
  },
  //POST UPLOAD FILE
  useMappingUploadMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async (fileType, uploadData) => {
      return await mutate({
        url: `${BaseServicePath}/shipment-management/mapping/upload/${fileType}`,
        data: uploadData,
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    };
  },
  //POST DOWNLOAD PDF
  useMappingExportPdfMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async ncvFileName => {
      return await mutate(
        {
          url: `${BaseServicePath}/pdf-files/${ncvFileName}`,
          method: "GET",
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            const { headers, data } = response;
            console.log(headers["content-type"]);

            const blob = new Blob([data], { type: `${headers["content-type"]}` });
            fileSaver.saveAs(blob, ncvFileName);
          },
        }
      );
    };
  },
});

export const {
  useMappingCreateMutation,
  useMappingUpdateMutation,
  useMappingCheckEditMutation,
  useMappingCheckAddMutation,
  useMappingCancelMutation,
  useMappingUploadMutation,
  useMappingExportPdfMutation,
} = GroupingApiService();

const saveAsPdsOrExcel = (response, filename) => {
  console.log(response);
  if (!response?.data) {
    console.error("No Download file (response must include header and data)");
    return;
  }
  const { headers, data } = response;
  console.log(headers["content-type"]);
  console.log(headers["content-disposition"]);
  if (!headers["content-disposition"]) {
    console.error("NOT INITIAL FILENAME PLEASE CONTACT DEV TEAM");

    const blob = new Blob([data], { type: `${headers["content-type"]}` });
    fileSaver.saveAs(blob, filename);
    return;
  }

  // Improved filename extraction
  let fileName = headers["content-disposition"]
    .split(";")
    .find(n => n.includes("filename="))
    ?.split("=")[1]
    ?.trim()
    .replace(/^["']|["']$/g, ""); // Remove surrounding quotes if present

  // Decode the filename (handles special characters and spaces)
  fileName = decodeURIComponent(fileName);

  // Clean up the filename
  fileName = fileName.replace(/^[\s_*]+|[\s_*]+$/g, "");

  console.log("Extracted filename:", fileName);

  const blob = new Blob([data], { type: `${headers["content-type"]}` });
  fileSaver.saveAs(blob, fileName);
};
