import React, { useEffect, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel, styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";

const StyledSelectField = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    padding: "5px 0px 5px 12px",
    color: `${theme.palette.primary.dark}`,
  },
  "& :focus-within": {
    border: `1.5px solid ${theme.palette.primary.dark} !important`,
    color: `${theme.palette.primary.dark}`,
  },
}));

export default function DropDown({
  id,
  value,
  menu,
  onChange,
  disabled = false,
  bgColor = "white",
  className = "",
  required,
  placeholder = "<Select>",
  defaultValue = "",
  autoFocus = false,
  onBlur,
  filterKey = null, // New prop to filter menu items by key
  tabIndex = -1,
  ...rest
}) {
  const selectRef = useRef(null);

  const handleKeyDown = e => {
    if (!menu || !Array.isArray(menu)) return;

    if (!selectRef.current?.contains(document.activeElement)) return;

    const fullList = [{ key: "", value: placeholder }, ...menu];

    const currentIndex = fullList.findIndex(item => item.key === value);

    if (e.key === "ArrowRight") {
      const nextIndex = currentIndex + 1;
      if (nextIndex < fullList.length) {
        onChange({ target: { value: fullList[nextIndex].key } });
      }
    }

    if (e.key === "ArrowLeft") {
      const prevIndex = currentIndex - 1;
      if (prevIndex >= 0) {
        onChange({ target: { value: fullList[prevIndex].key } });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [value, menu]);

  return (
    <FormControl style={{ width: "100%", margin: "4px 0px" }} ref={selectRef}>
      <InputLabel
        id="test-select-label"
        shrink={false}
        size="small"
        variant="outlined"
        style={{
          top: "50%",
          left: "5%",
          transform: "translateY(-50%)",
        }}>
        {value === "" && placeholder}
      </InputLabel>
      <Select
        {...rest}
        data-testid={id}
        id={id}
        sx={{ background: bgColor }}
        // size="small"
        value={value}
        label=""
        onChange={onChange}
        disabled={disabled}
        className={className}
        required={required}
        placeholder={placeholder}
        style={{ fontSize: "12px", border: required ? "1.7px solid" : "1px solid", borderRadius: "4px" }}
        autoFocus={autoFocus}
        input={<StyledSelectField inputProps={{ tabIndex, disabled }} />}>
        <MenuItem value={defaultValue}>
          <span>{placeholder}</span>
        </MenuItem>
        {menu &&
          menu.map((val, index) => (
            <MenuItem key={index} value={val.key}>
              {val.value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
